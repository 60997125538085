import { useState, useEffect } from "react";
import { DialogTitle, Typography, FormControl, IconButton, Dialog, Paper, DialogContent, DialogActions, Button, PaperProps } from "@mui/material"
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps } from './types'
import { Form, Formik } from "formik";
import tw from "twin.macro";
import InputField from "components/form-fields/input-field";
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    metadata: Yup.object().shape({
        name: Yup.string()
            .required('Category name is required'),
    })
})

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-metadata-add-new-category"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} sx={{
                //top: '-25%'
            }} />
        </Draggable>
    );
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{ cursor: 'move' }} >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


const MetaDataAddNewCategory = (props: { 
    open: boolean, 
    title?: string,
    label?:string,
    textHelper?:string,
    onClose: () => void, 
    onCreateCategory: (name: string) => void }) => {

    const {
        title = "Create category",
        label = "Metadata name*",
        textHelper = "This will be the name"
    } = props

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        props.onClose()
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    function _handleSubmit(values: any) {
        console.log("test")
        handleClose()
        props.onCreateCategory(values.metadata.name)
    }
    return (
        <Dialog
            open={open}
            fullWidth={false}
            maxWidth='sm'
            onClose={() => { }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-metadata-add-new-category"

        >
            <Formik
                initialValues={{
                    metadata: {
                        name: '',
                    }
                }}
                validationSchema={validationSchema}
                onSubmit={_handleSubmit}
            >
                {
                    () => {
                        return (
                        <Form css={tw`w-128`}>
                            <BootstrapDialogTitle id="draggable-dialog-metadata-add-new-category" onClose={handleClose}>
                                {title}
                            </BootstrapDialogTitle>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Typography component="h1" variant="h6">
                                        {label}
                                    </Typography>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {textHelper}
                                    </Typography>
                                    <InputField
                                        name="metadata.name"
                                        id="metadata.name"
                                        size="small"
                                    />
                                </FormControl>

                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button type="submit" onClick={() => { }}>Save</Button>
                            </DialogActions>
                        </Form>
                    )
                }
                }
            </Formik>
        </Dialog >
    )
}

export default MetaDataAddNewCategory