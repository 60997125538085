import {
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";

export interface AvailableCountriesState {
    countries: string[];
}

const initialState: AvailableCountriesState = {
    countries: [
        'Australia',
        'France',
        'Lebanon',
        'Pakistan',
        'Spain',
        'USA',
        'United Arab Emirates'
    ]
}

export const AvailableCountriesSlice = createSlice({
    name: 'availableCountriesSlice',
    initialState,
    reducers: {
        loadAvailableCountriesRequest: (_state, _action: PayloadAction) => {
            return
        },
        loadAvailableCountriesSuccess: (state, action: PayloadAction<string[]>) => {
            state.countries = action.payload
        },
    }
})

export const AvailableCountriesActions = AvailableCountriesSlice.actions
export default AvailableCountriesSlice