import { FormControl, Box, Typography, Button, TextField, FormHelperText } from "@mui/material"
import CityDescriptionPicker from "components/modale/city-description-picker"
import { useState } from "react"
import tw from "twin.macro"
import _, { at } from 'lodash';
import { useField } from 'formik';


const AboutTheCity = (props: any) => {
    const [openCityPicker, setOpenCityPicker] = useState(false);
    const [field, meta, helper] = useField(props);

    const { setValue } = helper;


    const { label = '', helperText = "", ...rest } = props

    function onSelectCity(city: string) {
        setValue(city, false)
    }

    function _renderHelperText(): any {
        const [error] = at(meta, 'error');

        if (error) {
            return <FormHelperText error sx={{ paddingLeft: 1 }}>{error}</FormHelperText>;
        } else
            return null

    }

    return (
        <>
            <FormControl {...rest}>
                <Box css={tw`flex flex-row justify-between`}>
                    <Typography component="h1" variant="h6" css={tw`inline`}>
                        {label}
                    </Typography>

                    <Button size="small" color='secondary' variant="contained" onClick={() => { setOpenCityPicker(true) }}>add</Button>
                </Box>

                <Typography variant="caption" display="block" gutterBottom>
                    {helperText}
                </Typography>

                {
                    _renderHelperText()
                }

                <TextField
                    size="small"
                    multiline
                    rows={10}
                    {...field}
                    {...rest}
                    InputProps={{
                        readOnly: true,
                    }}
                    sx={{
                        display: _.isEmpty(field.value) ? "none" : "flex",
                    }}
                    css={tw`bg-gray-100`}
                />
            </FormControl>

            <CityDescriptionPicker open={openCityPicker} onSelectCity={onSelectCity} onClose={() => { setOpenCityPicker(false) }} />
        </>
    )
}

export default AboutTheCity