import {
    createSlice,
    PayloadAction,
    current,
} from "@reduxjs/toolkit";
import _ from 'lodash'
import { CurrencyCodes, LanguagesCode, MeasurementsCode, RedirectionModes, AdsDraft, AdsCreationSteps, TransactionType, QueryAdsLoadDraftArgs, AdsDraftPublishMutationVariables, AdsPublishResult, CountryIso2Codes } from '../../../graphql/__generated__/graphql-types';
import produce from 'immer'
//import iniTialAdsDraft from './init-traft-ads.json'

interface CreateAdsProcessStep {
    step: AdsCreationSteps;
    title: string;
};

const createAdsProcessBaseSteps: CreateAdsProcessStep[] = [
    {
        step: AdsCreationSteps.Initial,
        title: "Start",
    },
    {
        step: AdsCreationSteps.AdsSetup,
        title: "Ads Setup",
    },
    {
        step: AdsCreationSteps.Asset,
        title: "Asset",
    },
    {
        step: AdsCreationSteps.Spv,
        title: "SPV",
    },
    {
        step: AdsCreationSteps.Distribution,
        title: "Briicks distribution",
    },
    {
        step: AdsCreationSteps.Restrictions,
        title: "Briicks restrictions",
    },
    {
        step: AdsCreationSteps.Financial,
        title: "Financial",
    },
]


const createAdsProcessEndSteps: CreateAdsProcessStep[] = [
    {
        step: AdsCreationSteps.OfferingCalendar,
        title: "Offering (Calendar)",
    },
    {
        step: AdsCreationSteps.OfferingInformations,
        title: "Offering (informations)",
    },
]
const createAdsProcessTransactionRentalSteps: CreateAdsProcessStep[] = [
    {
        step: AdsCreationSteps.TransactionRental,
        title: "Transaction (rental)",
    },
    {
        step: AdsCreationSteps.TransactionRentalProfitability,
        title: "Transaction (rental profitability)",
    },
]

const createAdsProcessTransactionFlippingSteps: CreateAdsProcessStep[] = [
    {
        step: AdsCreationSteps.TransactionFlipping,
        title: "Transaction (flipping)",
    },
    {
        step: AdsCreationSteps.TransactionFlippingProfitability,
        title: "Transaction (flipping profitability)",
    },
]

export interface DraftAdsState {
    adsDraft: AdsDraft;
    adsCreationSteps: CreateAdsProcessStep[];
    action: {
        loading: boolean;
        error: string;
    }
}

/*
const initialState: DraftAdsState = {
    adsDraft: {...iniTialAdsDraft, restrictions: {
        hasRestriction: false,
    }} as unknown as  AdsDraft,
    adsCreationSteps: [...createAdsProcessBaseSteps, ...createAdsProcessEndSteps],
    action: {
        loading: false,
        error: ''
    }
}
*/

const initialState: DraftAdsState = {
    adsDraft: {
        _id: '',
        restrictions: {
            hasRestriction: false,
        },
        distribution: {
            hasReservedShares: false,
            sharesOnSale: 0,
            totalShares: 0,
            sharesOffSale: 0,
            stock: 0,
        },
        financial: {
            assetValue: {
                totalAssetValue: 0,
            },
            aquisitionCost: {
                totalCost: 0,
            },
            holdingCost: {
                totalCost: 0,
            },
        },
        spv: null,
        asset: {
            location: {
                inside: null,
                coordinates: {
                    longitude: NaN,
                    latitude: NaN,
                },
                address: {
                    city: {
                        name: 'Sofiane',
                        descriptrion: '',
                    },
                    country: '' as CountryIso2Codes,
                    number: '',
                    stateRegion: '',
                    street: '',
                    zipCode: '',
                }
            },
            description: '',
            assetFeatures: [
                {
                    name: 'Interior Rooms',
                    metadata: []
                },
                {
                    name: 'Exterior elements',
                    metadata: []
                },
                {
                    name: 'Equipment',
                    metadata: []
                },
                {
                    name: 'Construction',
                    metadata: []
                },
            ],
            assetCondition: null,
            assetType: null,
            pictures: null,
            plans: null,
            video: null
        },
        adsSetup: {
            currency: CurrencyCodes.Usd,
            language: LanguagesCode.Eng,
            measurement: MeasurementsCode.SquareMetre,
            redirection: {
                mode: RedirectionModes.Default,
                params: '',
            },
            title: '',
            tokenizedFractionalized: false,
            transactionType: [],
        },
        step: AdsCreationSteps.Initial
        //step: AdsCreationSteps.TransactionRental
    },
    adsCreationSteps: [...createAdsProcessBaseSteps, ...createAdsProcessEndSteps],
    action: {
        loading: false,
        error: ''
    }
}


export const DraftAdsSlice = createSlice({
    name: 'draftAdsSlice',
    initialState,
    reducers: {
        adsCreationGoToNextStep: (state, action: PayloadAction<AdsDraft>) => {
            const { step, ...rest } = action.payload;
            const nextStep = _.reduce(state.adsCreationSteps, (acc: AdsCreationSteps, creationStep: CreateAdsProcessStep, index: number, adsCreationSteps: CreateAdsProcessStep[]) => {
                if (creationStep.step === step)
                    return adsCreationSteps[index + 1].step
                else return acc
            }, AdsCreationSteps.Initial)

            state.adsDraft = {
                ...rest,
                step: nextStep
            }

            if (nextStep === AdsCreationSteps.Asset) {
                let transactionSteps: CreateAdsProcessStep[] = []
                if (action.payload.adsSetup.transactionType.includes(TransactionType.FlippingInvestment)) {
                    transactionSteps = [...transactionSteps, ...createAdsProcessTransactionFlippingSteps]
                    if (!current(state).adsDraft.flipping) {
                        state.adsDraft.flipping = {
                            income: { totalAmount: 0, metadata: [] },
                            investment: { totalAmount: 0, holdingDuration: 0 },
                            operationDuration: { totalDuration: 0, metadata: [] },
                            profitability: { profit: 0, roiRation: 0, holdingTime: 0 },
                        }
                    }
                }

                if (action.payload.adsSetup.transactionType.includes(TransactionType.RentalInvestment))
                    transactionSteps = [...transactionSteps, ...createAdsProcessTransactionRentalSteps]

                state.adsCreationSteps = [...createAdsProcessBaseSteps, ...transactionSteps, ...createAdsProcessEndSteps]
            }

        },
        adsCreationGoToPreviousStep: (state, action: PayloadAction<AdsDraft>) => {
            const { step, ...rest } = action.payload;
            const nextStep = _.reduce(state.adsCreationSteps, (acc: AdsCreationSteps, creationStep: CreateAdsProcessStep, index: number, adsCreationSteps: CreateAdsProcessStep[]) => {
                if (creationStep.step === step)
                    return adsCreationSteps[index - 1].step
                else return acc
            }, AdsCreationSteps.Initial)

            state.adsDraft = {
                ...rest,
                step: nextStep
            }

        },
        adsCreationSaveAsDraftRequest: (state, _action: PayloadAction<AdsDraft>) => {
            state.action.loading = true
        },
        adsCreationSaveAsDraftRequestSuccess: (state, action: PayloadAction<AdsDraft>) => {
            state.action.loading = false
            state.adsDraft = action.payload;
        },
        adsCreationSaveAsDraftRequestFailed: (state, action: PayloadAction<string>) => {
            state.action.loading = false
            state.action.error = action.payload
        },
        adsCreationCanceled: (_state) => {
            return initialState
        },

        adsLoadDraftRequest: (_state, _action: PayloadAction<QueryAdsLoadDraftArgs & { callback: () => void }>) => {
            return produce(initialState, (draftState) => {
                draftState.action.loading = true;
            });
        },

        adsLoadDraftRequestSuccess: (state, action: PayloadAction<AdsDraft>) => {
            state.action.loading = false;
            state.adsDraft = action.payload;

            let transactionSteps: CreateAdsProcessStep[] = []
            if (action.payload.adsSetup.transactionType.includes(TransactionType.FlippingInvestment))
                transactionSteps = [...transactionSteps, ...createAdsProcessTransactionFlippingSteps]

            if (action.payload.adsSetup.transactionType.includes(TransactionType.RentalInvestment))
                transactionSteps = [...transactionSteps, ...createAdsProcessTransactionRentalSteps]

            state.adsCreationSteps = [...createAdsProcessBaseSteps, ...transactionSteps, ...createAdsProcessEndSteps]

        },

        adsLoadDraftRequestFailed: (state, action: PayloadAction<string>) => {
            state.action.loading = false;
            state.action.error = action.payload;
        },

        adsPublishRequest: (state, _action: PayloadAction<AdsDraftPublishMutationVariables & { callback?: () => void }>) => {
            state.action.loading = true;
            state.action.error = "";
        },

        adsPublishRequestSuccess: (_state, _action: PayloadAction<AdsPublishResult>) => {
            return initialState;
        },
        adsPublishRequestFailed: (state, action: PayloadAction<string>) => {
            state.action.loading = false;
            state.action.error = action.payload;
        },
    }
})

export const DraftAdsActions = DraftAdsSlice.actions
export default DraftAdsSlice