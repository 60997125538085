import { BriicksPaginateResult, BriicksListingsQueryVariables } from '../../graphql/__generated__/graphql-types';
import {
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";

export interface ListingsState {
    listingsPage: BriicksPaginateResult;
    isLoading: boolean;
    error: string;
}

const initialState: ListingsState = {
    listingsPage: {
        page: 0,
        limit: 27,
        docs: [],
        totalDocs: 0,
        hasNextPage: false,
        hasPrevPage: false,
        nextPage: 0,
        offset: 0,
        pagingCounter: 0,
        prevPage: 0,
        totalPages: 0
    },
    isLoading: false,
    error: "",
}


export const ListingsSlice = createSlice({
    name: 'listings',
    initialState,
    reducers: {
        loadListingsRequest: (state, _action: PayloadAction<BriicksListingsQueryVariables>) => {
            state.isLoading = true
            state.error = ""
        },
        loadListingsSuccess: (state, action: PayloadAction<BriicksPaginateResult>) => {
            state.isLoading = false

            state.listingsPage = action.payload
        },
        loadListingsFaillure: (state, action: PayloadAction<string>)=>{
            //state.listingsPage = initialState.listingsPage
            state.isLoading = false
            state.error = action.payload
        }
    },
})

export const ListingsActions = ListingsSlice.actions
export default ListingsSlice