import tw from 'twin.macro';
import AssetInfos, { elements as assetArticles } from './asset';
import Financial, { elements as financialArticles } from './financial';
import Legal, { elements as legalArticles } from './legal';
import ListedBy from './listed-by';
import Navigation from './navigation';
import Offering, { elements as offeringArticles } from './offering';
import Operation, { elements as operationsArticles } from './operation';
import Resources, { elements as ressourcesArticles } from './resources';
import AdTitle from './title';
import { useState } from 'react';
import { IFullAdsIndexMenu } from '../../common/types';

const Container = tw.div`md:flex flex-col md:flex-row md:min-h-screen w-full px-2`

const CardLetfWraper = tw.div`flex flex-col rounded text-gray-700 w-56 flex-shrink-0`
const ContentContainer = tw.div`flex flex-col flex-grow pl-4`

const FullCard = () => {
    const sections: IFullAdsIndexMenu[] = [
        {
            section: 'Asset',
            elements: assetArticles
        }, {
            section: 'Offering',
            elements: offeringArticles
        },
        {
            section: 'Operation',
            elements: operationsArticles
        },
        {
            section: 'Financial',
            elements: financialArticles
        },
        {
            section: 'Legal',
            elements: legalArticles
        },
        {
            section: 'Ressources',
            elements: ressourcesArticles
        }
    ]
    const [visibleArticle, setVisibleArticle] = useState('');
    console.log(visibleArticle)
    return (
        <Container>
            <CardLetfWraper>
                <Navigation visibleArticle={visibleArticle} sections={sections} />
            </CardLetfWraper>
            <ContentContainer>
                <ListedBy />
                <AdTitle />
                <AssetInfos setVisibleArticle={setVisibleArticle} />
                <Offering setVisibleArticle={setVisibleArticle} />
                <Operation setVisibleArticle={setVisibleArticle} />
                <Financial setVisibleArticle={setVisibleArticle} />
                <Legal setVisibleArticle={setVisibleArticle} />
                <Resources setVisibleArticle={setVisibleArticle} />
            </ContentContainer>
        </Container>
    )
}

export default FullCard;