import { Box, Button, FormControl, Step, StepLabel, Stepper, TextField } from "@mui/material";
import { Form, Formik, FormikProps, useField } from "formik";
import { BeatLoader } from "react-spinners";
import { useSelector } from "redux/store";
import { useDispatch } from "react-redux";
import tw from "twin.macro";
import AdsSetupForm from "./setup";
import { AdsCreationSteps, AdsDraft, AdsDraftPublishMutationVariables, Rental, Distribution, Financial, LeaseDetails, RentalInvestment, RentalProfitability } from '../../../../graphql/__generated__/graphql-types';
import AdsCreationStart from "./start";
import _ from "lodash";
import { DraftAdsActions } from "redux/reducers/maker/draft-ads";
import ValidationSchema from './validation-schema';
import { ValidationSchemaKey } from './validation-schema';
import AdsAsset from "./asset";
import AdsSPV from "./spv";
import BriicksDistribution from "./briicks-distribution";
import BriicksRestrictions from "./briicks-restriction";
import BriicksFinancial from "./financial";
import RentalTransaction from "./reantal-transaction";
import RentalProfitabilityView from './reantal-profitability';
import OfferingCalendar from "./offering-calendar";
import OfferingInformations from "./offering-informations";
import FlippingTransaction from "./flipping-transaction";
import FlippingProfitability from "./flipping-profitability";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';

const Container = tw.div`
relative min-h-2/4 relative
` //min-h-screen

const ToolbarContainer = tw.div`
sticky bottom-0 left-0 right-0  z-40
p-4 bg-gray-100 border border-l-0 border-r-0 border-b-0
`

const Toolbar = tw.div`
flex w-full justify-between 
`

const TopbarContainer = tw.div`
absolute top-0 left-0 right-0 bg-gray-200 sticky z-40
`

const Topbar = tw.div`
flex w-full justify-center p-2
`

const Content = tw.div`
min-h-screen
`

const CreateListing = () => {
    const ref = useRef();
    const draftAds = useSelector((state) => state.createAds.adsDraft)
    const action = useSelector((state) => state.createAds.action)
    const adsCreationSteps = useSelector((state) => state.createAds.adsCreationSteps)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formikRef = useRef<FormikProps<AdsDraft>>(null);
    const { assetId = null } = useParams();
    useEffect(() => {
        if (assetId) {
            dispatch(DraftAdsActions.adsLoadDraftRequest({
                _id: assetId, callback: () => {
                    formikRef?.current?.resetForm()
                }
            }))
        }
    }, [])

    const HiddenStepField = () => {
        const stepField = useField('step')
        useEffect(() => {
            stepField[2].setValue(draftAds.step, false)
        }, [draftAds.step])
        return null
    }

    const AdsId = (props: { id?: string } & any) => {
        const { id } = props
        const idField = useField('_id');
        useEffect(() => {
            if (id && !_.isEmpty(id)) idField[2].setValue(id, false)
        }, [id])
        return null
    }



    const activeStepIndex = _.findIndex(adsCreationSteps, (e) => e.step === draftAds.step);


    function _handleBack(values: AdsDraft) {
        dispatch(DraftAdsActions.adsCreationGoToPreviousStep(values))
    }

    function CurrentStep(props: FormikProps<AdsDraft>) {
        const rentalField = useField('rental')

        const resetRentalField = () => {
            const investment: RentalInvestment = {
                totalAmount: 0,
                metadata: [],
            }
            const leaseDetails: LeaseDetails = {
                annualGrossRent: 0,
                statut: '',
                type: '',
            }
            const profitability: RentalProfitability = {
                assetAppreciation: 0,
                cashFlow: 0,
                cashOnCash: 0,
                internalRateOfReturn: 0,
            }

            const rental: Rental = {
                investment,
                leaseDetails,
                profitability,
            }
            rentalField[2].setValue(rental, false)
        }
        switch (draftAds.step) {
            case AdsCreationSteps.TransactionFlippingProfitability:
                return <FlippingProfitability
                    flipping={props.values.flipping}
                    financial={props.values.financial as Financial}
                />
            case AdsCreationSteps.TransactionFlipping:
                return <FlippingTransaction flipping={props.values.flipping} financial={props.values.financial as Financial} />
            case AdsCreationSteps.OfferingInformations:
                return <OfferingInformations
                    financial={props.values.financial as Financial}
                    distribution={props.values.distribution as Distribution}
                    briicksMinMax={props.values.briicksMinMax} />
            case AdsCreationSteps.OfferingCalendar:
                return <OfferingCalendar />
            case AdsCreationSteps.TransactionRentalProfitability:
                return <RentalProfitabilityView rental={props.values.rental} financial={props.values.financial as Financial} />;
            case AdsCreationSteps.TransactionRental:
                if (!props.values.rental) resetRentalField();
                return <RentalTransaction rental={props.values.rental} financial={props.values.financial as Financial} />
            case AdsCreationSteps.Financial:
                return <BriicksFinancial financial={props.values.financial as Financial} />
            case AdsCreationSteps.Restrictions:
                return <BriicksRestrictions
                    ref={ref}
                    restrictions={props.values.restrictions!}
                    distribution={props.values.distribution!}
                    errors={props.errors?.restrictions} />
            case AdsCreationSteps.Distribution:
                return <BriicksDistribution distribution={props.values.distribution!} />
            case AdsCreationSteps.AdsSetup:
                return <AdsSetupForm />
            case AdsCreationSteps.Asset:
                return <AdsAsset asset={props.values.asset} adsSetup={props.values.adsSetup} />
            case AdsCreationSteps.Spv:
                return <AdsSPV />
            case AdsCreationSteps.Initial:
            default:
                return <AdsCreationStart /> //return null
        }
    }


    const getSubmitHandler = (params: { save: Boolean }) => (values: AdsDraft, actions: any) => {
        if (params.save) {
            actions.validateForm().then((errors: any) => {
                if (_.isEmpty(errors))
                    dispatch(DraftAdsActions.adsCreationSaveAsDraftRequest(values));
            }
            );
        } else _handleSubmit(values)
    }
    function _handleSubmit(values: AdsDraft) {
        if (draftAds.step === AdsCreationSteps.OfferingInformations) {
            dispatch(DraftAdsActions.adsPublishRequest({
                callback: () => {
                    navigate('/maker/ads');
                }, ...(values as AdsDraftPublishMutationVariables)
            }))
        } else {
            dispatch(DraftAdsActions.adsCreationGoToNextStep(values))
        }
    }

    function handleFormValidation(_props: FormikProps<AdsDraft>) {
        //@ts-ignore
        if (ref && _.isFunction(ref?.current?.submited))ref?.current?.submited()
    }

    return (
        <Container>
            <Formik
                innerRef={formikRef}
                initialValues={draftAds}
                //onSubmit={_handleSubmit}
                onSubmit={getSubmitHandler({ save: false })}
                validationSchema={ValidationSchema[draftAds.step as ValidationSchemaKey]}
                enableReinitialize
            >
                {
                    //
                    (props: FormikProps<AdsDraft>) => {
                        //console.log(props.values)
                        //console.log(props.errors)
                        return (
                            <Form>
                                <HiddenStepField />
                                <AdsId id={draftAds._id} />
                                <TopbarContainer>
                                    <Topbar>

                                        <Stepper css={tw`w-full`} alternativeLabel activeStep={activeStepIndex}>
                                            {
                                                adsCreationSteps.map((e, index) => (
                                                    <Step key={index}>
                                                        <StepLabel>{e.title}</StepLabel>
                                                    </Step>
                                                ))
                                            }

                                        </Stepper>
                                    </Topbar>
                                </TopbarContainer>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <BeatLoader css={tw`py-4`} size={20} color='#4299e1' loading={action.loading} />
                                </Box>
                                <Content>
                                    <CurrentStep {...props} />
                                </Content>
                                <ToolbarContainer>
                                    <Toolbar>
                                        <Button variant="outlined" onClick={() => {
                                            navigate('/maker/ads')
                                            dispatch(DraftAdsActions.adsCreationCanceled())

                                        }}>
                                            Cancel
                                        </Button>
                                        <div>
                                            {
                                                draftAds.step !== AdsCreationSteps.Initial &&
                                                <Button variant="outlined" onClick={() => _handleBack(props.values)}>
                                                    Back
                                                </Button>
                                            }
                                            {
                                                draftAds.step !== AdsCreationSteps.Initial && draftAds.step !== AdsCreationSteps.OfferingInformations &&
                                                <Button css={tw`ml-2`} variant="outlined" onClick={() => getSubmitHandler({ save: true })(props.values, props)}>
                                                    Save
                                                </Button>
                                            }

                                            <Button
                                                css={tw`ml-2`}
                                                color="primary"
                                                onClick={() => { handleFormValidation(props) }}
                                                variant="contained" type="submit">
                                                {draftAds.step === AdsCreationSteps.Initial ? "Start" :
                                                    draftAds.step === AdsCreationSteps.OfferingInformations ? "Publish" : "Next"}
                                            </Button>
                                        </div>
                                    </Toolbar>
                                </ToolbarContainer>
                            </Form>
                        )
                    }
                }
            </Formik>
            <FormControl fullWidth>
                {
                    true && <TextField
                        value={JSON.stringify(draftAds)}
                        multiline
                        rows={5}
                    />
                }

            </FormControl>

        </Container>
    )
}

export default CreateListing;