import { BriicksListing,  BriicksHighlightedListingsQueryVariables } from '../../graphql/__generated__/graphql-types';
import {
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";

export interface HighlightedListingsState {
    listings: BriicksListing[];
    isLoading: boolean;
    error: string;
}

const initialState: HighlightedListingsState = {
    listings: [],
    isLoading: false,
    error: ''
}

export const HighlightedListingsSlice = createSlice({
    name: 'highlightedListings',
    initialState,
    reducers: {
        loadHighlightedListingsRequest: (state, _action: PayloadAction<BriicksHighlightedListingsQueryVariables>) => {
            state.isLoading = true
            state.error = ""
        },
        loadHighlightedListingsSuccess: (state, action: PayloadAction<BriicksListing[]>) => {
            state.isLoading = false
            state.listings = action.payload
        },
        loadHighlightedListingsFaillure: (state, action: PayloadAction<string>)=>{
            //state.listings = initialState.listings
            state.isLoading = false
            state.error = action.payload
        }
    }
})

export const HighlightedListingsActions = HighlightedListingsSlice.actions
export default HighlightedListingsSlice