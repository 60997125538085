import { motion } from 'framer-motion';
import styled from 'styled-components';
import { FC, useEffect, useState, ReactNode } from 'react';
import { ReactComponent as LoaderIcon } from 'feather-icons/dist/icons/loader.svg'
import tw from 'twin.macro';

const LoadingContainer = styled(motion.div)`
${tw`flex min-w-full min-h-56 lg:min-h-64 justify-center items-center`}
svg{
    ${tw`h-6 w-6`}
}
`

const Loading : FC = () => (
    <LoadingContainer
        animate={{ rotate: 360 }}
        transition={{
            loop: Infinity,
            ease: "linear",
            duration: 1.5
        }}
    >
        <LoaderIcon />
    </LoadingContainer>
)

export const LazyBackground :FC<{src: string, children: ReactNode}> = (props) => {
    const {src, children} = props
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        setLoaded(false)
        const img = new Image()
        img.src = src
        img.onload = () => setLoaded(true)
    }, [src])


    return !loaded ? <Loading/> : <>{children}</>
    
}