import { Box, FormControl, Typography, Button } from "@mui/material";
import MetaDataField from "components/form-fields/metadata-field__";
import tw from "twin.macro";
import { IMetaDataInput, IMetaDataOutput } from '../../../../../components/form-fields/metadata-field__/index';
import { FinancialMetadata, AquisitionCost, OtherFinancialMetadata, DocumentInput } from '../../../../../graphql/__generated__/graphql-types';
import { useEffect, useState } from "react";
import _ from "lodash"
import { useField } from "formik";
import MetaDataAddNewCategory from "components/modale/metadata-add-new-category";
import MetaDataFieldNew from 'components/form-fields/metadata-field-new';

const AcquisitionCost = (props: { aquisitionCost?: AquisitionCost } & any) => {

    const {
        aquisitionCost,
        ...rest
    } = props

    const totalCostField = useField("financial.aquisitionCost.totalCost")
    const otherCostsField = useField("financial.aquisitionCost.otherCosts")
    const [openModalAddNewCategory, setOpenModalAddNewCategory] = useState(false)

    useEffect(() => {

        let totalCost = 0.0

        if (aquisitionCost) {
            if (aquisitionCost?.closingCost) totalCost += _.reduce(aquisitionCost.closingCost, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
            if (aquisitionCost?.reserve) totalCost += _.reduce(aquisitionCost.reserve, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
            if (aquisitionCost?.setupCost) totalCost += _.reduce(aquisitionCost.setupCost, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
            if (aquisitionCost?.otherCosts)
                totalCost += _.reduce(aquisitionCost.otherCosts, (accTot: number, cc: OtherFinancialMetadata) => (
                    accTot + _.reduce(cc.metadata, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
                ), 0)
        }

        totalCostField[2].setValue(totalCost, false)

    }, [aquisitionCost])

    function _handleOnCreateCategory(name: string) {
        const newCost: OtherFinancialMetadata[] = [{
            name,
            metadata: []
        }]

        if (otherCostsField[0].value) otherCostsField[2].setValue([...otherCostsField[0].value, ...newCost], false)
        else otherCostsField[2].setValue(newCost, false)
    }

    return (
        <>
            <Box {...rest}>
                <FormControl fullWidth>
                    <Box css={tw`flex flex-row justify-between`}>
                        <Typography component="h1" variant="h6">
                            Acquisition Cost (One Time Fees )
                        </Typography>
                        <Button size="small" color='secondary' variant="contained" onClick={() => setOpenModalAddNewCategory(true)}>
                            Add category
                        </Button>
                    </Box>

                    <Typography variant="caption" display="block" gutterBottom>
                        Insert all costs to purchase and commission this asset
                    </Typography>
                </FormControl>
                <MetaDataFieldNew
                    name="financial.aquisitionCost.closingCost"
                    id="financial.aquisitionCost.closingCost"
                    sx={{ marginTop: 3 }}
                    label="Closing Cost"
                    buttonLabel="Add line"
                    withDocument
                    type="number"
                    dataMapperInversed={(metadata: FinancialMetadata): IMetaDataOutput => {
                        const { title, value, document } = metadata
                        return { key: title, value: `${value}`, documents: !!document ? [document] : [] }
                    }}
                    dataMapper={(metadata: IMetaDataInput): FinancialMetadata => {
                        let document: DocumentInput | null = null

                        if (metadata.documents && metadata.documents.length > 0) {
                            const source = metadata.documents[0].remoteFile.filePath
                            const mimeType = metadata.documents[0].localeFile.type
                            document = {
                                source,
                                mimeType
                            }
                        }
                        return {
                            title: metadata.key,
                            value: parseFloat(metadata.value || '0'),
                            document
                        }

                    }}
                    suggestedFields={[
                        { key: "Title search", value: "1 000" },
                        { key: "Title insurance", value: "1 000" },
                        { key: "Recording fees", value: "1 000" },
                        { key: "Survey", value: "1 000" },
                        { key: "Realtor’s commissions", value: "1 000" },
                        { key: "Appraisal fees", value: "1 000" },
                        { key: "Transfer taxes", value: "1 000" },
                        { key: "Settlement costs", value: "1 000" },
                    ]}
                />

                <MetaDataFieldNew
                    name="financial.aquisitionCost.reserve"
                    id="financial.aquisitionCost.reserve"
                    sx={{ marginTop: 3 }}
                    label="Reserve"
                    buttonLabel="Add line"
                    withDocument
                    type="number"
                    dataMapperInversed={(metadata: FinancialMetadata): IMetaDataOutput => {
                        const { title, value, document } = metadata
                        return { key: title, value: `${value}`, documents: !!document ? [document] : [] }
                    }}
                    dataMapper={(metadata: IMetaDataInput): FinancialMetadata => {
                        let document: DocumentInput | null = null

                        if (metadata.documents && metadata.documents.length > 0) {
                            const source = metadata.documents[0].remoteFile.filePath
                            const mimeType = metadata.documents[0].localeFile.type
                            document = {
                                source,
                                mimeType
                            }
                        }
                        return {
                            title: metadata.key,
                            value: parseFloat(metadata.value || '0'),
                            document
                        }

                    }}
                    suggestedFields={[
                        { key: "Maintenance", value: "10 000" },
                        { key: "Vacancy", value: "5 000" },
                        { key: "Initial Renovation", value: "25 000" },
                    ]}
                />

                <MetaDataFieldNew
                    name="financial.aquisitionCost.setupCost"
                    id="financial.aquisitionCost.setupCost"
                    sx={{ marginTop: 3 }}
                    label="Set-up cost"
                    buttonLabel="Add line"
                    withDocument
                    type="number"
                    dataMapperInversed={(metadata: FinancialMetadata): IMetaDataOutput => {
                        const { title, value, document } = metadata
                        return { key: title, value: `${value}`, documents: !!document ? [document] : [] }
                    }}
                    dataMapper={(metadata: IMetaDataInput): FinancialMetadata => {
                        let document: DocumentInput | null = null

                        if (metadata.documents && metadata.documents.length > 0) {
                            const source = metadata.documents[0].remoteFile.filePath
                            const mimeType = metadata.documents[0].localeFile.type
                            document = {
                                source,
                                mimeType
                            }
                        }
                        return {
                            title: metadata.key,
                            value: parseFloat(metadata.value || '0'),
                            document
                        }

                    }}
                    suggestedFields={[
                        { key: "Briick Maker fees", value: "8 500" },
                        { key: "SPV Creation", value: "550" },
                    ]}
                    fixedKeys={["Briick Maker fees", "SPV Creation"]}
                />

                {
                    otherCostsField[0].value &&
                    otherCostsField[0].value.map((otherCost: OtherFinancialMetadata, index: number) => (
                        <MetaDataFieldNew
                            sx={{ marginTop: 3 }}
                            key={index}
                            name={`financial.aquisitionCost.otherCosts[${index}].metadata`}
                            id={`financial.aquisitionCost.otherCosts[${index}].metadata`}
                            label={otherCost.name}
                            buttonLabel="Add line"
                            withDocument
                            type="number"
                            dataMapperInversed={(metadata: FinancialMetadata): IMetaDataOutput => {
                                const { title, value, document } = metadata
                                return { key: title, value: `${value}`, documents: !!document ? [document] : [] }
                            }}
                            dataMapper={(metadata: IMetaDataInput): FinancialMetadata => {
                                let document: DocumentInput | null = null

                                if (metadata.documents && metadata.documents.length > 0) {
                                    const source = metadata.documents[0].remoteFile.filePath
                                    const mimeType = metadata.documents[0].localeFile.type
                                    document = {
                                        source,
                                        mimeType
                                    }
                                }
                                return {
                                    title: metadata.key,
                                    value: parseFloat(metadata.value || '0'),
                                    document
                                }

                            }}
                        />
                    ))
                }
            </Box>
            <MetaDataAddNewCategory
                open={openModalAddNewCategory}
                onClose={() => setOpenModalAddNewCategory(false)}
                onCreateCategory={_handleOnCreateCategory}
            />
        </>

    )
}

export default AcquisitionCost;