import { FormControl as FormControlBase, FormHelperText, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useField } from 'formik';
import _, { at } from 'lodash';

const AdsTransactions = [
    { title: 'FLIPPING INVESTMENT', value: 'FLIPPING_INVESTMENT', enabled: true },
    {
        title: 'RENTAL INVESTMENT',
        value: 'RENTAL_INVESTMENT', enabled: true
    },
    {
        title: 'HOTEL ROOMS',
        value: 'HOTEL_ROOMS', enabled: false
    },
    {
        title: 'NEW CONSTRUCTION',
        value: 'NEW_CONSTRUCTION', enabled: false
    },
    {
        title: 'OWNER TAKE BACK',
        value: 'OWNER_TAKE_BACK', enabled: false
    },
]

const FormControl = styled(FormControlBase)<{ component?: React.ElementType }>({
    '& .MuiFormControlLabel-labelPlacementStart': {
        marginLeft: 0,
        justifyContent: "space-between",
    }
})

export default function AdsTransactionsTypeField(props: any) {
    const [field, meta, helper] = useField(props);
    const { setValue } = helper;

    function _renderHelperText(): any {
        const [touched, error] = at(meta, 'touched', 'error');

        if (touched && error) {
            return <FormHelperText error sx={{ paddingLeft: 2 }}>{error}</FormHelperText>;
        } else
            return null
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            setValue([...field.value, event.target.name], false)
        } else {
            setValue(_.pull([...field.value], event.target.name), false)
        }
    }


    return (
        <FormControl variant="standard" sx={{ display: 'flex' }}>
            {_renderHelperText()}
            <FormGroup>
                {
                    AdsTransactions.map((e, index) => (
                        <FormControlLabel
                            disabled={!e.enabled}
                            labelPlacement={'start'}
                            key={index}
                            control={
                                <Switch
                                    name={e.value}
                                    checked={_.includes(field.value, e.value)}
                                    onChange={handleChange}
                                />
                            }
                            label={e.title}
                        />
                    ))
                }

            </FormGroup>
        </FormControl>
    )
}
