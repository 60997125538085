import tw from "twin.macro";
import NumberFormat from 'react-number-format';

const Container = tw.div`
flex grid grid-cols-3 pt-1
`
const ColDiv = tw.div`
col-span-2 pr-4 mr-2 mt-1 mb-1 border-r
`

const Global = tw.div`
col-span-1 flex items-center
`


const Heading2 = tw.h2`
font-medium text-base text-secondary-900 mr-1
`

const Val = tw.div`
flex-grow pl-2 flex justify-end text-base text-secondary-500
`

const RowDiv = tw.div`
flex flex-row justify-between
`

const ReturnOnInvestment = () => {

    return (
        <Container>
            <ColDiv>
                <RowDiv>
                    <Heading2>Rental income</Heading2>
                    <Val>
                        <NumberFormat
                            value={8.34}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            suffix={' %'}                            
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Val>
                </RowDiv>

                <RowDiv>
                    <Heading2>Capital appreciation</Heading2>
                    <Val>
                        <NumberFormat
                            value={11.32}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            suffix={' %'}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Val>
                </RowDiv>

            </ColDiv>
            <Global>
                <Heading2>Global</Heading2>
                <Val>
                    <NumberFormat
                        value={20.85}
                        decimalScale={2}
                        fixedDecimalScale
                        displayType={'text'}
                        thousandSeparator={' '}
                        suffix={' %'}
                        renderText={(value: string) => <span>{value}</span>}
                    />
                </Val>
            </Global>
        </Container>
    )
}

export default ReturnOnInvestment;