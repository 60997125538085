import { IMainSection } from './main-section';
import { ISubscribeToSocialsSection } from './subscribe-to-socials-section';
import { ReactComponent as TelegramIcon } from 'images/telegram-icon.svg'
import { ReactComponent as WhatsappIcon } from 'images/whatsapp-icon.svg'
import { IHighlightedListingSection } from './highlighted-listing-section';
import { IStatsSection } from './stats-section';

interface IHomeContent {
    MainSection: IMainSection;
    SubscribeToSocialsSection: ISubscribeToSocialsSection;
    HighlightedListingSection: IHighlightedListingSection;
    StatsSection: IStatsSection;
}

export const HomeContent: IHomeContent = {
    MainSection: {
        HeadingText: {
            normal: "Invest easily in worldwide real estate,",
            highlighted: "buy briicks from a dollar."
        },
        description: "Discover here the all world opportunities.",
        CTAButton: {
            text: "Learn about real-estate tokenization",
            link: "/fag"
        }
    },
    SubscribeToSocialsSection: {
        heading: "Be alerted to upcoming sales and don't miss any opportunities",
        description: "Join our channel on Telegram & WhattsApp.",
        socials: [
            {
                link: "#",
                icon: <TelegramIcon />
            },
            {
                link: "#",
                icon: <WhatsappIcon />
            }
        ]
    },
    HighlightedListingSection: {
        Title: "Hilighted Offers",
        MoreContent: {
            text: "View All Offers",
            link: "/market",
        },
        CTAButton: {
            text: "Jump to the market",
            link: "/market"
        }
    },
    StatsSection: {
        Title: "Over 9000 Projects Completed",
        SubTitle : "",
        Description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        Stats : [
            {
                label: "Clients",
                value: "2500+",
            },
            {
                label: "Revenue",
                value: "$100M+",
            },
            {
                label: "Employees",
                value: "150+",
            },
        ]
    }
}