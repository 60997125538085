import { FormControl, Box, Button, Typography, TextField, Chip, IconButton } from "@mui/material"
import tw from "twin.macro"
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from "react";
import { useField } from 'formik';
import _ from 'lodash';
import JoinFile, { IDocument } from "./join-field";
import { Document } from "graphql/__generated__/graphql-types";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface ISuggestedField {
    key: string;
    value: string
}

export interface IMetaData {
    key: string;
    value: string;
    documents?: IDocument[] | Document[];
}

interface IState {
    data: IMetaData;
    placeHolder: ISuggestedField,
}


const MetaDataFieldNew = (props: {
    label: string,
    buttonLabel: string,
    suggestedFields?: ISuggestedField[],
    helperText?: string,
    withDocument?: boolean,
    labelStyle?: any,
    valueHidden?: boolean,
    dataMapper: (metadata: IMetaData) => any,
    dataMapperInversed: (data: any) => IMetaData,
    prefixComponent?: React.ReactNode,
    sufixComponent?: React.ReactNode,
    valueType?: "Str" | "Float" | "Int",
    valueProps: any,
    fixedKeys?: string[]
} & any) => {

    const {
        label,
        fixedKeys = [],
        valueHidden = false,
        labelStyle = {},
        withDocument = false,
        type,
        helperText,
        buttonLabel,
        dataMapper = (o: any) => (o),
        dataMapperInversed = (o: any) => (o),
        suggestedFields = [],
        prefixComponent,
        sufixComponent,
        name,
        id,
        ...rest } = props
    const [metaData, setMetaData] = useState<IMetaData[]>([])
    const [formValue, setFormValue] = useState<IMetaData[]>([])


    // eslint-disable-next-line
    //@ts-ignore 
    const [field, meta, helper] = useField(name);
    const { setValue } = helper;

    if (field.name === 'asset.location.inside'){
        console.log('fields °°°°°°°°°')
        console.log(field.value)
        console.log(metaData)
    } 
    useEffect(() => {
        /* if (field.name === 'asset.location.inside'){
            console.log('fields ======>')
            console.log(field.value)
        } */
        if (!field.value) {
            const fixed = _.map(fixedKeys, (e: string) => ({ key: e, value: "" }))
            const suggested = _.map(suggestedFields, (_o: ISuggestedField) => ({ key: "", value: "" }))
            const data = [...fixed, ...suggested];
            setMetaData(data);
            setFormValue(data)
        }else {
            setMetaData(_.map(field.value, dataMapperInversed))
        }
    }, [])

    useEffect(() => {
        setValue(_.map(formValue, dataMapper), false);
    }, [formValue])

    function _handleOnBlure(){
        //setValue(_.map(metaData, dataMapper), false);
        setFormValue(metaData)
    }

    //const metaData = _.map(field.value, (e: any) => dataMapperInversed(e))

    const _handleAddLine = () => {
        const line = { key: "", value: "" }
        //const mappedLine = dataMapper(line)
        //setValue([...field.value, mappedLine], false)
        setMetaData([...metaData, line]);
        setFormValue([...metaData, line]);
    }

    function _handleRemoveLine(index: number) {
        let data = _.clone(field.value);
        _.pullAt(data, index);
        setMetaData(data);
        setFormValue(data)
        /*let mappedData = _.clone(field.value);
        _.pullAt(mappedData, index);
        setValue(mappedData, false);*/
    }

    const _handleValueChange = (event: { target: { name: string; value: string } }) => {
        const index = parseInt(event.target.name.replace('metadata-value-', ''))
        const value = event.target.value;

        const newMetadata = _.map(metaData, (e: IMetaData, i: number) => ( i == index ? {key: e.key, value, document: e.documents} : e) )
        //setMetaData(newMetadata.map(dataMapper))
        setMetaData(newMetadata)
    }

    const _handleKeyChange = (event: { target: { name: string; value: string } }) => {
        const index = parseInt(event.target.name.replace('metadata-key-', ''))
        const key = event.target.value;
        const newMetadata: IMetaData[] = _.map(metaData, (e: IMetaData, i: number): IMetaData => { return i == index ? {key, value: e.value, documents: e.documents} : e}  )
        //setMetaData(newMetadata.map(dataMapper))
        setMetaData(newMetadata)
    }

    function _drawPrefixComponent() {
        if (prefixComponent) return prefixComponent
        else return null
    }

    function _drawSufixComponent() {
        if (sufixComponent) return sufixComponent
        else return null
    }

    function _drawHelperText() {
        if (helperText)
            return <Typography variant="caption" display="block" gutterBottom>
                {helperText}
            </Typography>
        else return null
    }

    const _handleDocumentChange = (documents: IDocument[], index: number) => {

        const newMetadata = _.map(metaData, (e: IMetaData, i: number) => ( i == index ? {key: e.key, value: e.value, document: documents} : e) )
        setMetaData(newMetadata.map(dataMapper));
    }

    function _drawUpladDocuments(index: number) {
        return (
            <>
                {
                    withDocument && !valueHidden &&
                    <Grid xs={0.5} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>

                        <JoinFile
                            maxFiles={1}
                            minSize={0}
                            onJoinDocument={(documents: IDocument[]) => {
                                _handleDocumentChange(documents, index)
                            }}
                            fileTypes={["doc", 'docx', 'pdf']}
                        />
                    </Grid>
                }

                {
                    withDocument && valueHidden &&
                    <Grid xs={2}>
                        <JoinFile
                            maxFiles={1}
                            minSize={0}
                            onJoinDocument={(documents: IDocument[]) => {
                                _handleDocumentChange(documents, index)
                            }}
                            fileTypes={["doc", 'docx', 'pdf']}
                        />
                    </Grid>
                }
            </>
        )
    }

    function _drawPreviewDocuments(documents: Document[]) {
        return (
            <>
                {
                    withDocument && !valueHidden &&
                    <Grid xs={0.5} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                        {
                            documents && documents.map((d: Document, index) => (
                                <Chip
                                    key={index}
                                    label={d.source}
                                    onDelete={() => { }}
                                />
                            ))
                        }
                    </Grid>
                }

                {
                    withDocument && valueHidden &&
                    <Grid xs={2}>
                        {
                            documents.map((d: Document, index) => (
                                <Chip
                                    key={index}
                                    label={d.source}
                                    onDelete={() => { }}
                                />
                            ))
                        }
                    </Grid>
                }
            </>
        )
    }
    function _drawDocuments(forItem: number, documents?: IDocument[] | Document[]) {
        if (documents && !_.isEmpty(documents)) return _drawPreviewDocuments(documents as Document[])
        else return _drawUpladDocuments(forItem)
    }

    

   


    function getPlaceholder(index: number): ISuggestedField {
        if (suggestedFields.length > index) return suggestedFields[index];
        else return { key: '', value: '' }
    }

    function drawMetadataLine(e: IMetaData, index: number) {
        const placeholder = getPlaceholder(index)
        return (
            <>
                <Grid key={`key-${index}`} xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <FormControl fullWidth>
                        {
                            fixedKeys.includes(e.key) &&

                            <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                {e.key}
                            </Typography>
                        }

                        {
                            !fixedKeys.includes(e.key) &&
                            <TextField
                                name={`metadata-key-${index}`}
                                placeholder={placeholder.key}
                                value={e.key}
                                size="small"
                                onChange={_handleKeyChange}
                                onBlur={_handleOnBlure}
                            />
                        }

                    </FormControl>
                </Grid>
                {
                    !valueHidden &&
                    <Grid key={`value-${index}`} xs={withDocument ? 1.40 : 1.90}>
                        <FormControl fullWidth>
                            <TextField
                                name={`metadata-value-${index}`}
                                placeholder={placeholder.value}
                                value={e.value}
                                size="small"
                                onChange={_handleValueChange}
                                onBlur={_handleOnBlure}
                                type={type}
                            />
                        </FormControl>
                    </Grid>
                }


                {
                    _drawDocuments(index, e.documents)
                }
                {
                    !fixedKeys.includes(e.key) &&
                    <Grid xs={0.10}>
                        <IconButton sx={tw`text-red-500 float-right`} onClick={() => _handleRemoveLine(index)}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Grid>

                }

            </>
        )
    }

    return (
        <Box {...rest}>
            <Box css={tw`flex flex-row justify-between`}>
                <Typography component="h1" variant="h6" css={tw`inline`} {...labelStyle}>
                    {label}
                </Typography>

                <Button size="small" color='secondary' variant="contained" onClick={_handleAddLine}>{buttonLabel}</Button>
            </Box>

            {
                _drawHelperText()
            }

            {
                _drawPrefixComponent()
            }

            {
                metaData.length > 0 &&
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                    {

                        metaData.map(drawMetadataLine)
                    }
                </Grid>
            }
            {
                _drawSufixComponent()
            }
        </Box>
    )
}


export default MetaDataFieldNew;