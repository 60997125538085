import tw from "twin.macro";
import AdId from "../asset/ad-id";
import AdStatus from "../asset/ad-status";
import { Listing_Badge } from '../../../graphql/__generated__/graphql-types';
import DaysCount from "../asset/days-count";
import TakersBadge from "../asset/takers-badge";
import { IFullAdsIndexMenu } from '../../common/types';
import { Link, List, ListItem } from "@mui/material";
import { SourcesIcon } from 'components/misc/SourcesIcon';

const Container = tw.div`
sticky top-0
`

const NavigationContainer = tw.div`
p-2 bg-gray-200 rounded flex flex-col
`

const TimeLineContainer = tw.div`
p-2 bg-gray-200 rounded flex flex-col mb-2
`

const BadgesContainer = tw.div`
flex flex-col mb-2
`

const TimelineHead = tw.h1`
text-base font-semibold text-green-500
`

const Timelinedescription = tw.h3`
text-sm text-secondary-300
`

const MakerContainer = tw.div`
p-2 bg-gray-200 rounded flex flex-col mt-2
`

const BriicksMakerLogo = tw.img`
w-auto h-8 mt-2
`

const sources = ["RealTMixed", "Brickx", "BrickS", "Lofty", "Reental", 'Vesta-Equity', 'Land-Share',
        'Grapen-Invest', 'Katapulte', 'La-Premiere-Brique', 'Smart-Crowd', 'Smart-Crowd-Pakistan',
        'Smart-Crowd-Direct', 'Invest-Dubai', 'Vairt', 'Aqar-Chain'];
    const source = sources[Math.floor(Math.random() * sources.length)];


const Navigation = (props: { visibleArticle: string, sections: IFullAdsIndexMenu[] }) => {
    
    return (
        <>
            <BadgesContainer>
                <AdId css={tw`bg-gray-100 ml-0`}>RT2555</AdId>
                <AdStatus status={Listing_Badge.Open}>open</AdStatus>
                <TakersBadge>bought by 3652 Takers</TakersBadge>
                <DaysCount>28 days 22 Hours</DaysCount>
            </BadgesContainer>

            <TimeLineContainer>
                <TimelineHead>Timeline</TimelineHead>
                <Timelinedescription>Last Update : 07.11.22 - 08:38</Timelinedescription>
            </TimeLineContainer>
            <Container>
                <NavigationContainer>
                    {
                        props.sections.map((e, index) => {
                            return (
                                <List
                                    key={index}
                                    sx={{
                                        padding: 0
                                    }}
                                    subheader={
                                        <h1 css={tw`text-primary-500 text-lg font-semibold`}>{e.section}</h1>
                                    }>
                                    {
                                        e.elements.map((o, index) => (
                                            <ListItem
                                                sx={{ paddingTop: 0, paddingBottom: 0 }}
                                                key={index}>
                                                <Link href={`#${o.name}`}
                                                    color={o.name === props.visibleArticle ? '#6415FF' : '#243E63'}
                                                    variant='body2' underline='none'>{o.name}</Link>
                                            </ListItem>
                                        ))
                                    }

                                </List>
                            )
                        })
                    }
                </NavigationContainer>

                <MakerContainer>
                    <h1 css={tw`text-primary-500 text-base font-semibold`}>Briicks maker</h1>

                    <BriicksMakerLogo
                        src={SourcesIcon(source)}
                    />
                </MakerContainer>
            </Container>


        </>
    )
}

export default Navigation;