import { combineReducers } from 'redux'
import Listings from 'redux/reducers/listings';
import HighlightedListings from 'redux/reducers/highlightedListings'
import AvailableCountries from './available-countries';
import DraftAdsSlice from './maker/draft-ads';
import AdsListingItemsSlice from './maker/ads-listing-items';
import AdsDetailsSlice from './ads-details';


export default combineReducers({
    listings: Listings.reducer,
    highlightedListings: HighlightedListings.reducer,
    availableCountrie: AvailableCountries.reducer,
    createAds: DraftAdsSlice.reducer,
    adsListingItems: AdsListingItemsSlice.reducer,
    adsDetails: AdsDetailsSlice.reducer,
})