import tw from "twin.macro";
import { List as ListBase, ListItemIcon, ListItem, ListItemText, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import SwitchField from "components/form-fields/switch-field";



const Container = tw.div`
flex p-4 flex-col
`

const List = styled(ListBase)<{ component?: React.ElementType }>({
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
    '& .MuiFormControl-root': {
        flexGrow: 1,
    },
});

const TokenizedFractionalize = styled(SwitchField)({
    '& .MuiFormControlLabel-labelPlacementStart': {
        marginLeft: 0,
        justifyContent: "space-between",
    }
})

const AdsCreationStart = () => {
    return (
        <Container>
            <Typography component="h1" variant="h6">
                To create an new ad on BRIICKMARKET make sure you have the following items:
            </Typography>
            <List sx={{ maxWidth: 600, marginBottom: 12 }}>
                <ListItem>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>

                    <TokenizedFractionalize
                        name="adsSetup.tokenizedFractionalized"
                        label="Is your asset already tokenized / fractionalized ?"
                        labelPlacement="start" />

                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <CheckIcon />
                    </ListItemIcon>
                    <ListItemText>Documents of the SPV</ListItemText>
                </ListItem>
            </List>


            <Typography >
                You can start the creation process and come back later to complete it.
            </Typography>

        </Container>
    )
}

export default AdsCreationStart;