import { IRoute } from ".";
import HomePage from "../pages/Home";
import MarketLayout from "../pages/Market";
import FaqPage from '../pages/Faq';
import AboutUsPage from '../pages/AboutUs';
import JoinUsPage from '../pages/JoinUs';
import BriicksMakerPage from '../pages/Briicks-Maker';
import JoinBriicksPage from '../pages/JoinBriicks';
import { FAQContent } from "pages/Faq/content";
import { AboutUsContent } from '../pages/AboutUs/content';

import MakerLayout from "components/layouts/maker";
import MakerDashboard from "pages/Maker-Dashboard";
import MakerDeveloper from "pages/Maker-Dashboard/developer"
import APIDocs from "pages/Maker-Dashboard/api-docs";
import TakerLayout from '../components/layouts/taker';
import NewCardPage from "pages/new-cards";
import MakerListingsPage from "pages/Maker-Dashboard/listings";
import MakerListings from "pages/Maker-Dashboard/listings/listing";
import CreateListing from "pages/Maker-Dashboard/listings/create";

import FullAdsPage from "pages/full-ads";
import ListingDetails from "pages/Market/listing";
import MarketPage from "pages/Market/market-palce";
import InscriptionPage from "pages/Inscription";
import MakerRegistration from "pages/Inscription/Maker";

const REACT_APP_ENV = process.env.REACT_APP_ENV || ""

const prod_routes: IRoute[] = [
    {
        path: "/",
        name: 'home',
        component: <HomePage />
    },
    {
        path: "/market",
        name: "market",
        component: <MarketLayout />,
        subRoutes: [
            {
                path: '',
                component: <MarketPage />,
            },
            {
                path: 'listing/:listingId',
                component: <ListingDetails />,
            },
        ]
    },
    {
        path: "/briicks-maker",
        name: "briicks maker",
        component: <BriicksMakerPage />
    },
    {
        path: "/faq",
        name: "faq",
        component: <FaqPage content={FAQContent} />
    },
    {
        path: "/about-us",
        name: "about us",
        component: <AboutUsPage content={AboutUsContent} />
    },
    /*{
        path: "/sign-in",
        name: "sign in",
        component: <h1>hello</h1>
    },*/
    {
        path: "/join-us",
        hidden: true,
        component: <JoinUsPage />
    },
    {
        path: "/join-briicks",
        hidden: true,
        component: <JoinBriicksPage />
    },
    {
        path: "/taker",
        hidden: true,
        component: <TakerLayout />,
    },
]

const dev_routes: IRoute[] = [
    {
        path: "/new-card",
        hidden: true,
        name: "new card",
        component: <NewCardPage />
    },
    {
        path: "/full-ads",
        name: "fullAds",
        hidden: true,
        component: <FullAdsPage />
    },
    {
        path: "/inscription",
        hidden: true,
        component: <InscriptionPage />,
        subRoutes: [
            {
                path: '',
                component: <MakerRegistration />
            },
            {
                path: 'maker',
                component: <MakerRegistration />
            }
        ]
    },
    {
        path: "/maker",
        hidden: true,
        component: <MakerLayout />,
        subRoutes: [
            {
                path: '',
                component: <MakerDashboard />,
            },
            {
                path: 'ads',
                component: <MakerListingsPage />,
                subRoutes: [
                    {
                        path: '',
                        component: <MakerListings />,
                    },
                    {
                        path: 'create/:assetId',
                        component: <CreateListing />,
                    },
                    {
                        path: 'create',
                        component: <CreateListing />,
                    },
                ]
            },
            {
                path: 'developer',
                component: <MakerDeveloper />,
            },
            {
                path: 'api-docs',
                component: <APIDocs />,
            }
        ]
    },
]

const extraRoutes = REACT_APP_ENV === "production" ? [] : dev_routes;

const RootesData: IRoute[] =
    [
        ...prod_routes,
        ...extraRoutes,
        {
            path: "/*",
            hidden: true,
            component: <h1>404</h1>
        }
    ]


export default RootesData