import { OBJModel } from "react-3d-viewer";
import model from "images/img-360/villa.obj";
import tw from "twin.macro";
//import mtl from "images/img-360/villa.mtl";

const Container = tw.div`
flex justify-center h-96
`
const View3D = () => {
    return (
        <Container>
            <OBJModel
               height={500}
                position={{ x: 0, y: -2.5, z: 0 }}
                //scale={{ x: 0.25, y: 0.25, z: 0.25 }}
                src={model}
                width={800}
            />
        </Container>
    )
}

export default View3D;