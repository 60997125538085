
import { DialogTitle, IconButton, Dialog, Paper, DialogContent, DialogActions, Button, PaperProps, Typography, FormControl } from "@mui/material"
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import _ from 'lodash'
import { DialogTitleProps } from './types'
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import tw from "twin.macro";
import { Form, Formik } from "formik";
import { ReservedShareInput } from '../../graphql/__generated__/graphql-types';
import InputField from "components/form-fields/input-field";
import * as Yup from 'yup';

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-add-new-reserver"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} sx={{
                //top: '-25%'
            }} />
        </Draggable>
    );
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{ cursor: 'move' }} >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const BriicksAddNewReserver = (props: {
    open: boolean,
    onClose: () => void,
    onAddNewReserver: (share: ReservedShareInput) => void,
    maxShareValue: number,
}) => {

    const [open, setOpen] = useState(false);

    /*const handleClickOpen = () => {
        setOpen(true);
    };*/

    const validationSchema = Yup.object().shape({
        distribution: Yup.object().shape({
            share: Yup.object().shape({
                as: Yup.string()
                    .required('as is required'),
                name: Yup.string()
                    .required('name is required'),
                shares: Yup.number()
                    .required('shares is required')
                    .min(1, "shares must be > 0")
                    .max(props.maxShareValue, `shares must be <= total shares (${props.maxShareValue})`)
            })
        })
    })

    const handleClose = () => {
        setOpen(false);
        props.onClose()
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open])


    function _handleSubmit(values: { distribution: { share: ReservedShareInput } }) {
        props.onAddNewReserver(values.distribution.share)
        handleClose()
    }


    return (
        <>
            <Dialog
                open={open}
                fullWidth={false}
                maxWidth='xl'

                onClose={() => { }}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-add-new-reserver"

            >
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        distribution: {
                            share: {
                                as: "",
                                description: undefined,
                                name: "",
                                shares: 0
                            }
                        }
                    }}
                    onSubmit={_handleSubmit}
                >
                    {
                        () => {

                            return (
                                <Form>
                                    <BootstrapDialogTitle id="draggable-dialog-add-new-reserver" onClose={handleClose}>
                                        Add a new reserver
                                    </BootstrapDialogTitle>
                                    <DialogContent>
                                        <Grid container css={tw`w-144`} spacing={{ xs: 2, md: 2 }} columns={{ xs: 5 }} >
                                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                                <Typography component="h3" variant="h6" css={tw`text-base`}>Reserver</Typography>
                                            </Grid>
                                            <Grid xs={2}>
                                                <FormControl fullWidth>
                                                    <InputField
                                                        name="distribution.share.name"
                                                        id="distribution.share.name"
                                                        size="small"
                                                        placeholder="Full name"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={2}>
                                                <FormControl fullWidth>
                                                    <InputField
                                                        name="distribution.share.shares"
                                                        id="distribution.share.shares"
                                                        size="small"
                                                        placeholder="8 000"
                                                        valueType="Int"
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                                <Typography component="h3" variant="h6" css={tw`text-base`}>As</Typography>
                                            </Grid>
                                            <Grid xs={2}>
                                                <FormControl fullWidth>
                                                    <InputField
                                                        name="distribution.share.as"
                                                        id="distribution.share.as"
                                                        size="small"
                                                        placeholder="Current owner"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={2} />
                                            <Grid xs={1}>
                                                <Typography component="h3" variant="h6" css={tw`text-base`}>Note</Typography>
                                            </Grid>
                                            <Grid xs={4}>
                                                <FormControl fullWidth>
                                                    <InputField
                                                        name="distribution.share.description"
                                                        id="distribution.share.description"
                                                        size="small"
                                                        multiline
                                                        rows={5}
                                                    />
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button autoFocus onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button type="submit">Add</Button>
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )

}

export default BriicksAddNewReserver;