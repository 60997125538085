import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';
import { Ads, TransactionType } from 'graphql/__generated__/graphql-types';
import moment from 'moment';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2  w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`
const Renting = (props: { ads: Ads, id?: any, viewPortObserver?: (isVisible: boolean) => void }) => {
    const { ads } = props
    if (ads.transactionType.includes(TransactionType.RentalInvestment)) {
        const annual_gross_rent = ads.rental?.leaseDetails.annualGrossRent || 0
        const monthly_gross_rent = Math.ceil(annual_gross_rent / 12);
        let rentStartDate: string | null = null
        let rentEndDate: string | null = null
        if (ads.rental?.leaseDetails.startDate) {
            try {
                rentStartDate = moment(ads.rental.leaseDetails.startDate).format('YYYY/MM/DD')
            } catch (error) {

            }
        }
        if (ads.rental?.leaseDetails.endtDate) {
            try {
                rentEndDate = moment(ads.rental.leaseDetails.endtDate).format('YYYY/MM/DD')
            } catch (error) {

            }
        }
        return (
            <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
                <Headline>Renting</Headline>
                <Divider />
                <Card>
                    <CardHead>
                        <HeadLine>Gross Rent</HeadLine>
                    </CardHead>
                    <Content>
                        <Label>Annual </Label>
                        <Value>
                            <NumberFormat
                                value={annual_gross_rent}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={'text'}
                                thousandSeparator={' '}
                                prefix={'$ '}
                                renderText={(value: string) => <span>{value}</span>}
                            />
                        </Value>
                    </Content>

                    <Content>
                        <Label>Monthly </Label>
                        <Value>
                            <NumberFormat
                                value={monthly_gross_rent}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={'text'}
                                thousandSeparator={' '}
                                prefix={'$ '}
                                renderText={(value: string) => <span>{value}</span>}
                            />
                        </Value>
                    </Content>


                    {
                        ads.rental?.leaseDetails.settlementFrequency &&
                        <>
                            <CardHead>
                                <HeadLine>Settlement</HeadLine>
                            </CardHead>
                            <Content>
                                <Label>Frequency </Label>
                                <Value>
                                    {ads.rental.leaseDetails.settlementFrequency || ""}
                                </Value>
                            </Content>
                        </>
                    }
                    {
                        /*
                    }
                    <Content>
                        <Label>Amount </Label>
                        <Value>
                            <NumberFormat
                                value={700}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={'text'}
                                thousandSeparator={' '}
                                prefix={'$ '}
                                renderText={(value: string) => <span>{value}</span>}
                            />
                        </Value>
                    </Content>
                    {
                        */
                    }


                    <CardHead>
                        <HeadLine>Lease details</HeadLine>
                    </CardHead>

                    <Content>
                        <Label>Type</Label>
                        <Value>{ads.rental?.leaseDetails.type || ""}</Value>
                    </Content>

                    <Content>
                        <Label>Statut </Label>
                        <Value>{ads.rental?.leaseDetails.statut || ""}</Value>
                    </Content>
                    {
                        rentStartDate &&
                        <Content>
                            <Label>Start </Label>
                            <Value>{rentStartDate}</Value>
                        </Content>
                    }
                    {
                        rentEndDate &&
                        <Content>
                            <Label>End </Label>
                            <Value>{rentEndDate}</Value>
                        </Content>
                    }


                    {
                        /*
                    }
                    <Content>
                        <Label>Deposit </Label>
                        <Value>
                            <NumberFormat
                                value={6000}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={'text'}
                                thousandSeparator={' '}
                                prefix={'$ '}
                                renderText={(value: string) => <span>{value}</span>}
                            />
                        </Value>
                    </Content>
                    {
                        */
                    }
                </Card>
            </ArticleContainer>
        )
    } else return <></>
}

export default Renting;