import { FormControl, TextField, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import tw from "twin.macro"
import { Financial, Flipping } from '../../../../graphql/__generated__/graphql-types';


const Container = tw.div`
flex p-4 flex-col
`

const FlippingProfitability = (props: { flipping: Flipping, financial?: Financial } & any) => {
    const {
        financial,
        flipping
    } = props

    const investment = (financial?.assetValue?.totalAssetValue || 0) + (flipping?.investment?.totalAmount || 0) + (financial?.aquisitionCost?.totalCost || 0)
    const profit = (flipping?.income?.totalAmount || 0) - investment
    const roiRatio = (profit / investment) * 100
    const totalDuration = flipping?.operationDuration?.totalDuration || 0
    return (
        <Container>
            <Typography component="h1" variant="h6">
                Return On Investement (R.O.I.)
            </Typography>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} >
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Income
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            css={tw`bg-gray-200`}
                            value={flipping?.income?.totalAmount || 0}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Investment
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            css={tw`bg-gray-200`}
                            value={investment}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>

                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Profit
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            css={tw`bg-gray-200`}
                            value={profit}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>

                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        R.O.I. Ratio
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            css={tw`bg-gray-200`}
                            value={`${roiRatio.toFixed(2)} %`}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Typography component="h1" variant="h6" sx={{ marginTop: 3 }}>
                Holding Time
            </Typography>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} >
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Recommended Time (in month)
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            size="small"
                            css={tw`bg-gray-200`}
                            value={totalDuration}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Container>
    )
}

export default FlippingProfitability;