import { configureStore } from "@reduxjs/toolkit";
import {
    useSelector as rawUseSelector,
    TypedUseSelectorHook,
} from "react-redux";
import createSagaMiddleware from 'redux-saga';
import sagas from 'redux/sagas'
import reducers from 'redux/reducers'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
})

sagaMiddleware.run(sagas)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
