import Header from "components/header"
import Footer from "components/footer"
import { Container as ContainerBase } from 'components/misc/Layouts.js'
//import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
//overflow hidden cause probleme sticky
import tw from 'twin.macro';
import FullCard from "components/cards/full-card-static";

const Container = tw(ContainerBase)`-mx-8 px-4 `
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const FullAdsPage = () => {

    return (
        <>
            <Header />
            <Container  >
                <Content>
                    <FullCard/>
                </Content>
            </Container>
            <Footer />
        </>
    )
}

export default FullAdsPage;