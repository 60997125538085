import { Box } from "@mui/material";
import tw from "twin.macro";
import Grid from '@mui/material/Unstable_Grid2';

const Container = tw(Box)`
`

const Item = tw.div`
flex flex-col
`
const Headline = tw.h1`
text-primary-100 font-medium text-base 
`
const ItemContent = tw.div`
flex
`

const LineTitle = tw.h3`
w-24 mr-1 text-secondary-300 font-medium text-sm
`

const LineValue = tw.span`
flex-grow text-secondary-300 text-sm text-right
`

const Address = () => {
    return (
        <Container>

            <Grid container spacing={3}>

                <Grid xs={12} md={6}>
                    <Item>
                        <Headline>Outside</Headline>
                        <ItemContent>
                            <LineTitle>Number</LineTitle>
                            <LineValue>4920</LineValue>
                        </ItemContent>
                        <ItemContent>
                            <LineTitle>Street Name</LineTitle>
                            <LineValue>E 110th St</LineValue>
                        </ItemContent>
                        <ItemContent>
                            <LineTitle>Neighborhood</LineTitle>
                            <LineValue>Garfield Heights</LineValue>
                        </ItemContent>
                        <ItemContent>
                            <LineTitle>City</LineTitle>
                            <LineValue>Cleveland</LineValue>
                        </ItemContent>
                        <ItemContent>
                            <LineTitle>Zip Code</LineTitle>
                            <LineValue>55236</LineValue>
                        </ItemContent>
                        <ItemContent>
                            <LineTitle>State</LineTitle>
                            <LineValue>(OH) Ohio </LineValue>
                        </ItemContent>
                        <ItemContent>
                            <LineTitle>Country</LineTitle>
                            <LineValue>United State of America</LineValue>
                        </ItemContent>
                    </Item>
                </Grid>
                <Grid xs={12} md={6}>
                    <Item>
                        <Headline>Inside</Headline>
                        <ItemContent>
                            <LineTitle>Building</LineTitle>
                            <LineValue>A</LineValue>
                        </ItemContent>
                        <ItemContent>
                            <LineTitle>Floor</LineTitle>
                            <LineValue>65</LineValue>
                        </ItemContent>
                        <ItemContent>
                            <LineTitle>Door</LineTitle>
                            <LineValue>35</LineValue>
                        </ItemContent>
                    </Item>
                    <Item css={tw`mt-3 `}>
                        <Headline>Anex</Headline>
                        <ItemContent>
                            <LineTitle>Parking</LineTitle>
                            <LineValue>23</LineValue>
                        </ItemContent>

                        <ItemContent>
                            <LineTitle>Parking</LineTitle>
                            <LineValue>12</LineValue>
                        </ItemContent>

                        <ItemContent>
                            <LineTitle>Cellar</LineTitle>
                            <LineValue>A18</LineValue>
                        </ItemContent>
                    </Item>
                </Grid>
            </Grid>
        </Container>


    )
}

export default Address;