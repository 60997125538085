import { Button, List, Pagination } from "@mui/material"
import { Box } from "@mui/system"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Link } from "react-router-dom";
import AssetItem from "components/cards/asset-item";
import { useDispatch } from "react-redux";
import { AdsListingItemsActions } from '../../../redux/reducers/maker/ads-listing-items';
import { useEffect, useState } from "react";
import { useSelector } from "redux/store";
import { AdsListing } from '../../../graphql/__generated__/graphql-types';
import tw from "twin.macro";


const Listings = () => {
    const dispatche = useDispatch()
    const [listingPage, setListingPage] = useState(1);
    const adsListingItemsAction = useSelector((state) => state.adsListingItems.action)
    const adsListingItemsPage = useSelector((state) => state.adsListingItems.adsListingItemsPage)

    useEffect(() => {
        dispatche(AdsListingItemsActions.loadAdsListingItemsRequest({ limit: 10, page: listingPage }));
    }, [listingPage])

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setListingPage(value);
    };

    return (
        <Box sx={{ padding: 2 }}>
            {
                adsListingItemsAction.loading
            }
            <Link to='create'>
                <Button> Create new Ads</Button>
            </Link>

            <Box>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel value="open" control={<Radio />} label="Open" />
                        <FormControlLabel value="expired" control={<Radio />} label="Expired" />
                        <FormControlLabel value="draf" control={<Radio />} label="Draft" />
                    </RadioGroup>
                </FormControl>
            </Box>
            <hr />
            <List>
                {
                    adsListingItemsPage.docs.map((doc: AdsListing, index: number) => (
                        <AssetItem key={index} asset={doc}/>
                    ))
                }
            </List>
            {
                adsListingItemsPage.totalPages > 1 &&
                <Box css={tw`flex justify-center pt-6`}>
                    <Pagination count={adsListingItemsPage.totalPages} page={listingPage} size="large" onChange={handlePageChange} />
                </Box>
            }
        </Box>
    )
}

export default Listings;