import tw from "twin.macro";
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2   w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2 flex flex-row
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`
const SubHeadLine = tw.h1`
text-primary-500 text-sm font-medium uppercase
`

const HoldingCost = (props: {id? : any, viewPortObserver?: (isVisible: boolean) => void }) => {
    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Holding cost</Headline>
            <Divider />
            <Card>
                <CardHead>
                    <HeadLine>Annuel Cost</HeadLine>
                    <Value css={tw`text-lg`}>
                        <NumberFormat
                            value={12500}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </CardHead>

                <Content>
                    <SubHeadLine>
                    Utilities
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>Global</Label>
                    <Value>
                        <NumberFormat
                            value={750}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>


                <Content>
                    <SubHeadLine>
                    Maintenance
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>Routine maintenance</Label>
                    <Value>
                        <NumberFormat
                            value={750}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>


                <Content>
                    <SubHeadLine>
                    Insurance
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>Homeowners Insurance</Label>
                    <Value>
                        <NumberFormat
                            value={600}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Renter’s Insurance</Label>
                    <Value>
                        <NumberFormat
                            value={600}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <SubHeadLine>
                    Insurance
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>Taxes</Label>
                    <Value>
                        <NumberFormat
                            value={350}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>



                <Content>
                    <SubHeadLine>
                    Management
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>Property Manager</Label>
                    <Value>
                        <NumberFormat
                            value={1000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Briick Manager</Label>
                    <Value>
                        <NumberFormat
                            value={1000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>



                <Content>
                    <SubHeadLine>
                    Administrative 
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>SPV Annual Fees</Label>
                    <Value>
                        <NumberFormat
                            value={200}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>



                <Content>
                    <SubHeadLine>
                    Loan 
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>Blue Bank</Label>
                    <Value>
                        <NumberFormat
                            value={200}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>
            </Card>
        </ArticleContainer>
    )
}


export default HoldingCost;