import { put, takeLatest } from "redux-saga/effects";
import clientGraphQL from "graphql/client";
import { AdsDetailsActions } from '../reducers/ads-details';
import { AdsLoadByIdDocument, AdsLoadByIdQuery, AdsLoadByIdQueryVariables, Ads } from '../../graphql/__generated__/graphql-types';
import {
    PayloadAction,
} from "@reduxjs/toolkit";

function* adsLoadByIdAsyncRequest(action: PayloadAction<AdsLoadByIdQueryVariables>) {
    try {
        const args = action.payload
        const adsLoadByIdQuery: AdsLoadByIdQuery = yield clientGraphQL.request(AdsLoadByIdDocument, args);

        if (adsLoadByIdQuery.adsLoadById.__typename === 'Ads')
            yield put(AdsDetailsActions.adsLoadByIdRequestSuccess(adsLoadByIdQuery.adsLoadById as Ads));
        else
            yield put(AdsDetailsActions.adsLoadByIdRequestFaillure(adsLoadByIdQuery.adsLoadById.message));
    } catch (error) {
        yield put(AdsDetailsActions.adsLoadByIdRequestFaillure("error"));
    }
}

/* ------------- Connect Types To Sagas ------------- */
export const AdsDetailsSaga = [
    //reducer-name/reducer-function
    takeLatest('adsDetails/adsLoadByIdRequest', adsLoadByIdAsyncRequest),
]