import tw from "twin.macro";
import AssetValue from "./asset-value";
import { Financial } from "graphql/__generated__/graphql-types";
import AcquisitionCost from "./acquisition-cost";
import HoldingCost from "./holding-cost";

const Container = tw.div`
flex p-4 flex-col
`

const BriicksFinancial = (props: { financial?: Financial }) => {

    return (
        <Container>
            <AssetValue assetValue={props.financial?.assetValue} />
            <AcquisitionCost sx={{ marginTop: 4 }} aquisitionCost={props.financial?.aquisitionCost} />
            <HoldingCost sx={{ marginTop: 4 }} holdingCost={props.financial?.holdingCost}/>
        </Container>
    )
}

export default BriicksFinancial;