import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { Ads } from 'graphql/__generated__/graphql-types';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2   w-128  self-center
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`
const SPVId = (props: {ads: Ads, id? : any, viewPortObserver?: (isVisible: boolean) => void }) => {
    const { spv } = props.ads
    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>SPV Id</Headline>
            <Divider/>
            <Card>
                <Content>
                    <Label>Name</Label>
                    <Value>{spv.name}</Value>
                </Content>

                <Content>
                    <Label>Registration Place</Label>
                    <Value>{spv.registrationPlace}</Value>
                </Content>

                <Content>
                    <Label>Registration Number</Label>
                    <Value>{spv.registrationNumber}</Value>
                </Content>

                <Content>
                    <Label>Sponsor</Label>
                    <Value>{spv.sponsor}</Value>
                </Content>
            </Card>
        </ArticleContainer>
    )
}

export default SPVId;