import { createTheme } from '@mui/material/styles';
export default createTheme({
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    marginTop: 8,
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgb(230, 231, 234)",
                    opacity: 0.3,
                    marginTop: 5,
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#0b1b36",
                    color: "#e0e4ec",
                    "& .Mui-selected.MuiMenuItem-root": {
                        backgroundColor: "rgb(59, 72, 94)",
                    },
                    "& .MuiListItemIcon-root": {
                        color: "inherit"
                    },
                    "& .MuiIconButton-root": {
                        color: "currentColor"
                    },
                    "& .MuiDivider-root": {
                        backgroundColor: "currentColor",
                        opacity: 0.3
                    }
                },
            },
        }
    }
});