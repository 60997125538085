import { FormControl, Box, Typography, Button } from "@mui/material";
import AssetFeatureCategoryPicker from "components/modale/asset-features-category-picker";
import { useState } from "react";
import tw from "twin.macro";
import MetaDataField from 'components/form-fields/metadata-field__';
import { useField } from 'formik';
import { AssetFeaturesInput } from "graphql/__generated__/graphql-types";
import _ from 'lodash'

const DEFAULT_FIELDS = {
    'Interior Rooms': [
        { key: "Interior Size", value: "1253" },
        { key: "Bedroom", value: "4" },
        { key: "Bathroom", value: "5" },
        { key: "Kitchen", value: "2" },
    ],
    "Exterior elements":
        [
            { key: "Car Parking", value: "2" },
            { key: "Garden", value: "120" },
        ],
    "Equipment":
        [
            { key: "Heating", value: "Air Force - Gas" },
            { key: "Cooling", value: "none" },
        ],

    "Construction":
        [
            { key: "Foundation", value: "Masonery" },
            { key: "Exterior Wall", value: "Frame" },
        ],

}

const AssetFeatures = (props: { label: string, buttonLabel: string } & any) => {
    const { label, buttonLabel, ...rest } = props
    const [openFeaturesCategoryPicker, setOpenFeaturesCategoryPicker] = useState(false)

   

    // eslint-disable-next-line
    //@ts-ignore 
    const [field, meta, helper] = useField(props);
    const { setValue } = helper;

    function _handleFeatureSelection(features: string[]) {
        const newCategories = _.reduce(features, (acc: Array<any>, name: string) => {
            if (_.find(field.value, name)) return acc
            else return [...acc, {
                name,
                metadata: []
            }]
        }, [])

        if (!_.isEmpty(newCategories)) setValue([...field.value, ...newCategories], false)
    }
    return (
        <>
            <FormControl {...rest}>
        
                    <Box css={tw`flex flex-row justify-between`}>
                    <Typography component="h1" variant="h6" css={tw`inline`}>
                        {label}
                    </Typography>
                    <Button size="small" color='secondary' variant="contained" onClick={() => { setOpenFeaturesCategoryPicker(true) }}>
                        {buttonLabel}
                    </Button>
                </Box>

                {
                    field.value.map((o: AssetFeaturesInput, index: number) => {

                        type PlaceHolderKey = keyof typeof DEFAULT_FIELDS
                        const key = o.name as PlaceHolderKey
                        let placeholders: any[] = [
                            { key: 'key', value: 'value' }
                        ]
                        if (o.name in DEFAULT_FIELDS) placeholders = DEFAULT_FIELDS[key]
                        return (
                            <MetaDataField
                                key={index}
                                name={`asset.assetFeatures[${index}].metadata`}
                                id={`asset.assetFeatures[${index}].metadata`}
                                label={o.name}
                                removeEmpptyFields={false}
                                buttonLabel="Add line"
                                /*dataMapperInversed={(metadata: IMetaData) => {
                                    const { key, value } = metadata
                                    return { key, value }
                                }}
                                dataMapper={(metadata: IMetaData) => {
                                    const { key, value } = metadata
                                    return { key, value }
                                }}*/
                                placeHolders={placeholders}
                                sx={{ marginTop: 3 }}
                                labelStyle={tw`text-sm`}
                            />
                        )
                    }
                    )
                }
            </FormControl>
            <AssetFeatureCategoryPicker
                open={openFeaturesCategoryPicker}
                onClose={() => { setOpenFeaturesCategoryPicker(false) }}
                onSelectFeatureCategories={_handleFeatureSelection}
            />
        </>
    )
}

export default AssetFeatures;