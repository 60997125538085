import { Box, Typography, FormControl } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import FileField from "components/form-fields/file-field"
import InputField from "components/form-fields/input-field"
import MetaDataField, { IMetaDataInput, IMetaDataOutput } from "components/form-fields/metadata-field__"
import { OtherDocument } from "graphql/__generated__/graphql-types"
import tw from "twin.macro"
import { DocumentInput } from '../../../../graphql/__generated__/graphql-types';
import MetaDataFieldNew from 'components/form-fields/metadata-field-new';

const Container = tw.div`
flex p-4 flex-col
`

const AdsSPV = () => {
    return (
        <Container>
            <Box>
                <Typography component="h1" variant="h6">
                    Information
                </Typography>
                <MetaDataFieldNew
                    label="Information"
                    labelStyle={tw`text-sm`}
                    buttonLabel="Add line"
                    name="spv.metadata"
                    id="spv.metadata"
                    /*dataMapper={(metadata: IMetaData) => {
                        const { key, value } = metadata
                        return { key, value }
                    }}*/
                    prefixComponent={
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                    Name*
                                </Typography>
                            </Grid>
                            <Grid xs={2}>
                                <FormControl fullWidth>
                                    <InputField
                                        name="spv.name"
                                        id="spv.name"
                                        size="small"
                                        placeholder="RTEN003"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                    Registration Place*
                                </Typography>
                            </Grid>
                            <Grid xs={2}>
                                <FormControl fullWidth>
                                    <InputField
                                        name="spv.registrationPlace"
                                        id="spv.registrationPlace"
                                        size="small"
                                        placeholder="Delaware, USA"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                    Registration Number*
                                </Typography>
                            </Grid>
                            <Grid xs={2}>
                                <FormControl fullWidth>
                                    <InputField
                                        name="spv.registrationNumber"
                                        id="spv.registrationNumber"
                                        size="small"
                                        placeholder="DE 456 845 456"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                    Sponsor*
                                </Typography>
                            </Grid>
                            <Grid xs={2}>
                                <FormControl fullWidth>
                                    <InputField
                                        name="spv.sponsor"
                                        id="spv.sponsor"
                                        size="small"
                                        placeholder="REALTOKEN NL B.V."
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>
                    }
                    suggestedFields={[
                        { key: 'Abc...', value: '...' }
                    ]}
                />

                <MetaDataFieldNew
                    sx={{ marginTop: 3 }}
                    label='Documents'
                    name='spv.documents.otherDocuments'
                    id='spv.documents.otherDocuments'
                    labelStyle={tw`text-sm`}
                    buttonLabel="Add line"
                    dataMapperInversed={(metadata: OtherDocument): IMetaDataOutput => {
                        const { description, document } = metadata
                        return { key: description, value: '', documents: !!document ? [document] : [] }
                    }}
                    dataMapper={(metadata: IMetaDataInput): OtherDocument => {
                        const { key } = metadata

                        let document: DocumentInput = {
                            source: '',
                            mimeType: ''
                        }

                        if (metadata.documents && metadata.documents.length > 0) {
                            const source = metadata.documents[0].remoteFile.filePath
                            const mimeType = metadata.documents[0].localeFile.type
                            document = {
                                source,
                                mimeType
                            }
                        }
                        return {
                            description: key,
                            document
                        }
                    }}
                    withDocument
                    valueHidden
                    prefixComponent={
                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                    Establishement Certificate*
                                </Typography>
                            </Grid>
                            <Grid xs={2}>
                                    <FileField
                                        maxFiles={1}
                                        minSize={0}
                                        preview={false}
                                        name="spv.documents.establishementCertificate"
                                        id="spv.documents.establishementCertificate"
                                        fileTypes={["doc", 'docx', 'pdf']}
                                        dataMapper={(file: File, imageKitResult: any) => {
                                            return {
                                                source: imageKitResult.filePath,
                                                mimeType: file.type
                                            }
                                        }}
                                    />
                            </Grid>
                        </Grid>


                    }
                />
            </Box>
        </Container>
    )
}

export default AdsSPV