import { createTheme } from '@mui/material/styles';


export const theme = createTheme({
    palette: {
        primary: {
            main: '#6415FF',
        },
        secondary: {
            main: '#6415FF',
        },
    },
});