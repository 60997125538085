import tw from 'twin.macro';
import { SourcesIcon } from 'components/misc/SourcesIcon';

const Container = tw.div`flex justify-end items-center`
const Header = tw.div`
font-medium text-gray-600 pr-2
`
const BriicksMakerLogo = tw.img`
w-auto h-8
`

const ListedBy = () => {
    const sources = ["RealTMixed", "Brickx", "BrickS", "Lofty", "Reental", 'Vesta-Equity', 'Land-Share',
        'Grapen-Invest', 'Katapulte', 'La-Premiere-Brique', 'Smart-Crowd', 'Smart-Crowd-Pakistan',
        'Smart-Crowd-Direct', 'Invest-Dubai', 'Vairt', 'Aqar-Chain'];
    const source = sources[Math.floor(Math.random() * sources.length)];
    return (
        <Container>
            <Header>Listed by</Header>
            <BriicksMakerLogo
                src={SourcesIcon(source)}
            />
        </Container>
    )
}

export default ListedBy;
