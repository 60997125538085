import { Outlet } from "react-router-dom";
import Footer from "components/footer"
import Header from "components/header"
import tw from "twin.macro";
import { Container as ContainerBase } from 'components/misc/Layouts.js'

const Container = tw(ContainerBase)`-mx-8`
const MarketLayout = () => (
    <>
            <Header />
            <Container  >
                <Outlet />
            </Container>
            <Footer />
    </>
)
export default MarketLayout;