import { Box, FormControl, Typography } from "@mui/material"
import InputField from "components/form-fields/input-field";
import { CountryIso2Codes, MeasurementsCode, AdsSetup, DraftAdsAsset, Gallery, Maybe, Plans, Document, EventMetadataInput } from "graphql/__generated__/graphql-types";
import VideoPreview from "components/media/video-preview";
import SelectField from 'components/form-fields/select-field';
import { Countries, CountriesCoordinates } from "components/global/countries";
import Grid from '@mui/material/Unstable_Grid2';
import CoordinatesField from "components/form-fields/coordinates-field";
import _ from "lodash"
import AboutTheCity from "components/form-fields/about-the-city";
import FileField from "components/form-fields/file-field/index";
import MediaGallery, { MediaGalleryDataType } from "components/form-fields/media-gallery/index";
import MetaDataField, { IMetaDataInput,  IMetaDataOutput} from "components/form-fields/metadata-field__";
import MetaDataFieldNew from "components/form-fields/metadata-field-new";
import { AssetDestination } from '../../../../components/global/asset-destination';
import AssetFeatures from "components/form-fields/asset-features";
import tw from "twin.macro"
import { useField } from "formik";
import { useEffect } from 'react';
import Location from '../../../../components/cards/full-card/asset/location/index';

const Container = tw.div`
flex p-4 flex-col
`

const AdsAsset = (props: { asset?: Maybe<DraftAdsAsset>, adsSetup: AdsSetup }) => {
    const isUSAdress = props.asset?.location?.address?.country === CountryIso2Codes.Us

    const country = props.asset?.location?.address?.country
    const countryCoordinates = _.find(CountriesCoordinates, (o) => o.alpha2 === country)

    let measurmentSymbole = "";
    if (props.adsSetup.measurement === MeasurementsCode.SquareMetre) measurmentSymbole = 'm² ';
    else if (props.adsSetup.measurement === MeasurementsCode.SquareFeet) measurmentSymbole = 'ft² ';

    return (
        <Container>
            <FormControl fullWidth>
                <Typography component="h1" variant="h6">
                    Presentation
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    insert a video link of the property presentation or let blank
                </Typography>
                <InputField
                    name="asset.video"
                    id="asset.video"
                    size="small"
                    placeholder="https://www.youtube.com/watch?v=INBdVVsxLLY"
                />
                <VideoPreview css={tw`mt-2`} source={props.asset?.video} />
            </FormControl>

            <FormControl sx={{ marginTop: 3 }} fullWidth>
                <Typography component="h1" variant="h6">
                    About the Property*
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    Describe the property here
                </Typography>
                <InputField
                    name="asset.description"
                    id="asset.description"
                    size="small"
                    multiline
                    rows={10}
                />
            </FormControl>

            <Box sx={{ marginTop: 3 }}>
                <Typography component="h1" variant="h6">
                    Location*
                </Typography>

                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 4, md: 12, lg: 16 }} sx={{ marginTop: 1 }}>
                    <Grid xs={4} >
                        <SelectField
                            name="asset.location.address.country"
                            labelId="asset.location.address.country"
                            id="asset.location.address.country"
                            label="Country*"
                            data={Countries.map(({ code, name }) => ({ label: name, value: code }))}
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={4} >
                        <InputField
                            fullWidth
                            name="asset.location.address.number"
                            id="asset.location.address.number"
                            size="small"
                            label="Number*"
                        />
                    </Grid>

                    <Grid xs={4} >
                        <InputField
                            name="asset.location.address.street"
                            id="asset.location.address.street"
                            size="small"
                            label="Street Name*"
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={4} >
                        <InputField
                            name="asset.location.address.neighborhood"
                            id="asset.location.address.neighborhood"
                            size="small"
                            label="Neighborhood"
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={4} >
                        <FormControl fullWidth>
                            <InputField
                                name="asset.location.address.city.name"
                                id="asset.location.address.city.name"
                                size="small"
                                label="City*"
                                fullWidth
                            />
                        </FormControl>
                    </Grid>

                    <Grid xs={4} >
                        <InputField
                            name="asset.location.address.zipCode"
                            id="asset.location.address.zipCode"
                            size="small"
                            label="Zip Code*"
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={4} >
                        <InputField
                            name="asset.location.address.stateRegion"
                            id="asset.location.address.stateRegion"
                            size="small"
                            label={isUSAdress ? "State*" : "Region*"}
                            fullWidth
                        />
                    </Grid>

                    {
                        !isUSAdress &&
                        <Grid xs={4} >
                            <InputField
                                name="asset.location.address.departement"
                                id="asset.location.address.departement"
                                size="small"
                                label="Departement"
                                fullWidth
                            />
                        </Grid>
                    }
                </Grid>



            </Box>
            <Box sx={{ marginTop: 2, zIndex: 30 }}>

                <Typography component="h1" variant="h6">
                    Coordinates*
                </Typography>

                <Typography variant="caption" display="block" gutterBottom>
                    Position the marker
                </Typography>

                <CoordinatesField
                    name="asset.location.coordinates"
                    id="asset.location.coordinates"
                    css={tw`mt-2 w-full`}
                    coordinates={[countryCoordinates?.latitude || 0, countryCoordinates?.longitude || 0]}
                />

            </Box>

            <MetaDataFieldNew
                name="asset.location.inside"
                id="asset.location.inside"
                label="Inside Location"
                buttonLabel="Add line"
                /*dataMapper={(metadata: IMetaData) => {
                    return metadata
                }}*/
                suggestedFields={[
                    { key: "Tower", value: "1" }
                ]}
                sx={{ marginTop: 2 }}
            />


            <MetaDataFieldNew
                name="asset.location.anex"
                id="asset.location.anex"
                label="Annexes Location"
                buttonLabel="Add line"
                /*dataMapper={(metadata: IMetaData) => {
                    return metadata
                }}*/
                suggestedFields={[
                    { key: "Car Parking", value: "Basement 2 - Place 26" }
                ]}
                sx={{ marginTop: 2 }}
            />



            <AboutTheCity
                sx={{ marginTop: 4 }}
                label="About the City*"
                helperText="add descritpion about the city"
                name="asset.location.address.city.descriptrion"
                id="asset.location.address.city.descriptrion"
            />

            <FormControl sx={{ marginTop: 3 }} fullWidth>
                <Typography component="h1" variant="h6">
                    Featured photo*
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    These default settings are taken from your Maker profile. You can
                    specify different parameters that will apply to this ad only.
                </Typography>
                <FileField
                    maxFiles={1}
                    name="asset.pictures.featured"
                    id="asset.pictures.featured"
                    fileTypes={["JPG", "JPEG", "PNG", "GIF"]}
                    dataMapper={(_file: any, imageKitResult: any) => {
                        return imageKitResult.filePath
                    }}
                />
            </FormControl>


            <MediaGallery
                label='Photo gallery'
                buttonLabel='add gallery'
                helpText='You can add one or more gallery and add photo in.'
                name="asset.pictures.galleries"
                id="asset.pictures.galleries"
                subObjectsName="pictures"
                dataMapper={(_file: any, imageKitResult: any) => {
                    return imageKitResult.filePath
                }}
                creatNewGalleryDelegate={(name: string): Gallery => {
                    return {
                        name,
                        pictures: []
                    }
                }}
                galleryMapper={(gallery: Gallery): MediaGalleryDataType => {
                    return {
                        galleryName: gallery.name,
                        content: gallery.pictures.map((e: string) => ({
                            link: e,
                            type: 'image'
                        }))
                    }
                }}
                sx={{ marginTop: 2 }} />


            <MediaGallery
                label='Plans'
                buttonLabel='add gallery'
                helpText='You can add one or more plan in one or more gallery.'
                name="asset.plans"
                id="asset.plans"
                subObjectsName="documents"
                dataMapper={(file: File, imageKitResult: any) => {
                    return {
                        mimeType: file.type,
                        source: imageKitResult.filePath
                    }
                }}
                creatNewGalleryDelegate={(name: string): Plans => {
                    return {
                        name,
                        documents: []
                    }
                }}
                galleryMapper={(gallery: Plans): MediaGalleryDataType => {
                    return {
                        galleryName: gallery.name,
                        content: gallery.documents.map((d: Document) => ({
                            link: d.source,
                            type: d.mimeType
                        }))
                    }
                }}
                sx={{ marginTop: 2 }} />


            <MetaDataFieldNew
                name="asset.assetType.metadata"
                id="asset.assetType.metadata"
                label="Asset Type"
                buttonLabel="Add line"
                /*dataMapper={(metadata: IMetaData) => {
                    return metadata
                }}*/
                helperText="Add the information that makes it possible to determine the destination and the nature of
                the asset"
                prefixComponent={
                    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                        <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                Destination*
                            </Typography>
                        </Grid>
                        <Grid xs={2}>
                            <FormControl fullWidth>
                                <SelectField
                                    name="asset.assetType.destination"
                                    id="asset.assetType.destination"
                                    data={AssetDestination.map(({ code, name }) => ({ label: name, value: code }))}
                                    size="small"
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                Type*
                            </Typography>
                        </Grid>
                        <Grid xs={2}>
                            <FormControl fullWidth>
                                <InputField
                                    name="asset.assetType.type"
                                    id="asset.assetType.type"
                                    size="small"
                                    placeholder="Multi-Family"
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                Lot Size*
                            </Typography>
                        </Grid>
                        <Grid xs={2}>
                            <FormControl fullWidth>
                                <InputField
                                    name="asset.assetType.area"
                                    id="asset.assetType.area"
                                    size="small"
                                    placeholder={`${measurmentSymbole} 245.55`}
                                    valueType='Float'
                                    prefix={measurmentSymbole}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                }
                sx={{ marginTop: 2 }}
            />


            <MetaDataFieldNew
                name="asset.assetCondition.event.eventMetadata"
                id="asset.assetCondition.event.eventMetadata"
                label="Condition"
                buttonLabel="Add line"
                dataMapperInversed={(metadata: EventMetadataInput): IMetaDataOutput => {
                    const { title, date, document } = metadata
                    return { key: title, value: date, documents: document ? [document] : [] }
                }}
                dataMapper={(metadata: IMetaDataInput): EventMetadataInput => {
                    let source = ""
                    let mimeType = ""
                    if (metadata.documents && metadata.documents.length > 0) {
                        source = metadata.documents[0].remoteFile.filePath
                        mimeType = metadata.documents[0].localeFile.type
                    }
                    return {
                        title: metadata.key,
                        date: metadata.value,
                        document: {
                            source,
                            mimeType
                        }
                    }

                }}
                withDocument
                helperText="Give informattion about condition and describe"
                prefixComponent={
                    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                        <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                            <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                Construction Year*
                            </Typography>
                        </Grid>
                        <Grid xs={1.5}>
                            <FormControl fullWidth>
                                <InputField
                                    name="asset.assetCondition.event.constructionYear.year"
                                    id="asset.assetCondition.event.constructionYear.year"
                                    size="small"
                                    placeholder="2015"
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={0.5} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                            <FileField
                                maxFiles={1}
                                minSize={0}
                                preview={false}
                                name="asset.assetCondition.event.constructionYear.document"
                                id="asset.assetCondition.event.constructionYear.document"
                                fileTypes={["doc", 'docx', 'pdf']}
                                dataMapper={(file: File, imageKitResult: any) => {
                                    return {
                                        source: imageKitResult.filePath,
                                        mimeType: file.type
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                suggestedFields={[
                    { key: "Last Renovation", value: "2021/02/10" }
                ]}

                sufixComponent={
                    <FormControl sx={{ marginTop: 3 }} fullWidth>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Description
                        </Typography>
                        <InputField
                            name="asset.assetCondition.description"
                            id="asset.assetCondition.description"
                            size="small"
                            multiline
                            rows={10}
                        />
                    </FormControl>
                }
                sx={{ marginTop: 2 }}
                type='date'
            />

            <AssetFeatures
                sx={{ marginTop: 3 }}
                label="Features"
                buttonLabel="Add category"
                name="asset.assetFeatures"
                id="asset.assetFeatures"
            />
        </Container>
    )
}

export default AdsAsset