
import { DialogTitle, IconButton, Dialog, Paper, DialogContent, DialogActions, Button, PaperProps, Typography, FormControl } from "@mui/material"
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { DialogTitleProps } from './types'
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import tw from "twin.macro";
import { Form, Formik } from "formik";
import { BriicksRestrictionItemInput } from '../../graphql/__generated__/graphql-types';
import InputField from "components/form-fields/input-field";
import * as Yup from 'yup';
import FileField from "components/form-fields/file-field";

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-briicks-restriction-creator"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} sx={{
                //top: '-25%'
            }} />
        </Draggable>
    );
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{ cursor: 'move' }} >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const BriicksRestrictionCreator = (props: {
    open: boolean,
    onClose: () => void,
    onAddNewRestriction: (restrictionItem: BriicksRestrictionItemInput) => void,
    maxQuantityValue: number,
}) => {

    const [open, setOpen] = useState(false);

    /*const handleClickOpen = () => {
        setOpen(true);
    };*/

    const validationSchema = Yup.object().shape({
        restrictionItem: Yup.object().shape({
            title: Yup.string()
                .required('title is required'),
            /*quantity: Yup.number()
                .required('Quantity is required')
                .min(1, "Quantity must be > 0")
                .max(props.maxQuantityValue, `Quantity must be <= total shares for sale (${props.maxQuantityValue})`),
                */
        })

    })

    const handleClose = () => {
        setOpen(false);
        props.onClose()
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open])


    function _handleSubmit(values: { restrictionItem: BriicksRestrictionItemInput }) {
        props.onAddNewRestriction(values.restrictionItem)
        handleClose()
    }


    return (
        <>
            <Dialog
                open={open}
                fullWidth={false}
                maxWidth='xl'

                onClose={() => { }}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-briicks-restriction-creator"

            >
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        restrictionItem: {
                            title: '',
                            quantity: 0,
                            stock: 0,
                        }
                    }}
                    onSubmit={_handleSubmit}
                >
                    {
                        () => {

                            return (
                                <Form>
                                    <BootstrapDialogTitle id="draggable-dialog-briicks-restriction-creator" onClose={handleClose}>
                                        Add a new restriction
                                    </BootstrapDialogTitle>
                                    <DialogContent>
                                        <Grid container css={tw`w-144`} spacing={{ xs: 2, md: 2 }} columns={{ xs: 4 }} >
                                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                                <Typography component="h3" variant="h6" css={tw`text-base`}>Title</Typography>
                                            </Grid>
                                            <Grid xs={3}>
                                                <FormControl fullWidth>
                                                    <InputField
                                                        name="restrictionItem.title"
                                                        id="restrictionItem.title"
                                                        size="small"
                                                        placeholder="REG. D - QUALIFY U.S. ONLY"
                                                    />
                                                </FormControl>
                                            </Grid>
{/*

                                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                                <Typography component="h3" variant="h6" css={tw`text-base`}>Quantity</Typography>
                                            </Grid>
                                            <Grid xs={3}>
                                                <FormControl fullWidth>
                                                    <InputField
                                                        name="restrictionItem.quantity"
                                                        id="restrictionItem.quantity"
                                                        size="small"
                                                        placeholder="10 000"
                                                        valueType="Int"
                                                    />
                                                </FormControl>
                                            </Grid>

*/}

                                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                                <Typography component="h3" variant="h6" css={tw`text-base`}>URL</Typography>
                                            </Grid>
                                            <Grid xs={3}>
                                                <FormControl fullWidth>
                                                    <InputField
                                                        name="restrictionItem.url"
                                                        id="restrictionItem.url"
                                                        size="small"
                                                        placeholder="https://www.domain-name.com/reg-d.html"
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                                <Typography component="h3" variant="h6" css={tw`text-base`}>Attachement</Typography>
                                            </Grid>
                                            <Grid xs={3}>
                                                <FormControl fullWidth>
                                                    <FileField
                                                        maxFiles={1}
                                                        preview={false}
                                                        name="restrictionItem.document"
                                                        id="restrictionItem.document"
                                                        fileTypes={["pdf", "doc", "docx"]}
                                                        dataMapper={(file: File, imageKitResult: any) => {
                                                            return {
                                                                mimeType: file.type,
                                                                source: imageKitResult.filePath
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={1}>
                                                <Typography component="h3" variant="h6" css={tw`text-base`}>Explanation</Typography>
                                            </Grid>
                                            <Grid xs={3}>
                                                <FormControl fullWidth>
                                                    <InputField
                                                        name="restrictionItem.description"
                                                        id="restrictionItem.description"
                                                        size="small"
                                                        multiline
                                                        rows={5}
                                                    />
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button autoFocus onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button type="submit">Add</Button>
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
        </>
    )

}

export default BriicksRestrictionCreator;