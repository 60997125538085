import { Box, FormControl, Typography, Button } from "@mui/material";
import { DocumentInput, HoldingCost } from "graphql/__generated__/graphql-types";
import { useEffect, useState } from "react";
import tw from "twin.macro";
import MetaDataAddNewCategory from "components/modale/metadata-add-new-category";
import { useField } from "formik";
import { OtherFinancialMetadata, FinancialMetadata } from '../../../../../graphql/__generated__/graphql-types';
import _ from "lodash"
import MetaDataField from 'components/form-fields/metadata-field__';
import { IMetaDataInput, IMetaDataOutput } from '../../../../../components/form-fields/metadata-field__/index';
import MetaDataFieldNew from 'components/form-fields/metadata-field-new';


const DEFAULT_METADATA = [
    {
        name: 'financial.holdingCost.utilities',
        label: 'Utilities',
        placeHolders: [
            { key: "Water", value: "1 200" },
            { key: "Electricity", value: "1 200" },
        ]
    },
    {
        name: 'financial.holdingCost.maintenance',
        label: 'Maintenance',
        placeHolders: [
            { key: "Routine maintenance", value: "1 200" },
        ]
    },
    {
        name: 'financial.holdingCost.inusrance',
        label: 'Inusrance',
        placeHolders: [
            { key: "Homeowners Insurance", value: "1 200" },
            { key: "Renter’s Insurance", value: "1 200" },
        ]
    },
    {
        name: 'financial.holdingCost.taxes',
        label: 'Taxes',
        placeHolders: [
            { key: "Property Taxes", value: "1 200" },
        ]
    },
    {
        name: 'financial.holdingCost.management',
        label: 'Management',
        placeHolders: [
            { key: "Property Manager", value: "1 200" },
            { key: "Briick Maker Managment fees", value: "1 200" },
        ]
    },
    {
        name: 'financial.holdingCost.administrative',
        label: 'Administrative',
        placeHolders: [
            { key: "SPV Fees", value: "1 200" },
            { key: "Accounting", value: "1 200" },
        ]
    },
    {
        name: 'financial.holdingCost.debt',
        label: 'Debt',
        placeHolders: [
            { key: "Loan", value: "1 200" },
        ]
    },
]

const FinancialHoldingCost = (props: {
    holdingCost?: HoldingCost
} & any) => {
    const [openModalAddNewCategory, setOpenModalAddNewCategory] = useState(false)
    const {
        holdingCost,
        ...rest
    } = props

    const totalCostField = useField("financial.holdingCost.totalCost")
    const otherCostsField = useField("financial.holdingCost.otherCosts")

    useEffect(() => {
        //console.log(holdingCost)

        let totalCost = 0.0

        if (holdingCost) {
            if (holdingCost?.administrative) totalCost += _.reduce(holdingCost.administrative, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
            if (holdingCost?.debt) totalCost += _.reduce(holdingCost.debt, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
            if (holdingCost?.inusrance) totalCost += _.reduce(holdingCost.inusrance, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
            if (holdingCost?.maintenance) totalCost += _.reduce(holdingCost.maintenance, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
            if (holdingCost?.management) totalCost += _.reduce(holdingCost.management, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
            if (holdingCost?.taxes) totalCost += _.reduce(holdingCost.taxes, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
            if (holdingCost?.utilities) totalCost += _.reduce(holdingCost.utilities, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
            if (holdingCost?.otherCosts)
                totalCost += _.reduce(holdingCost.otherCosts, (accTot: number, cc: OtherFinancialMetadata) => (
                    accTot + _.reduce(cc.metadata, (acc: number, cc: FinancialMetadata) => (acc + cc.value), 0)
                ), 0)
        }

        totalCostField[2].setValue(totalCost, false)

    }, [holdingCost])
    function _handleOnCreateCategory(name: string) {
        const newCost: OtherFinancialMetadata[] = [{
            name,
            metadata: []
        }]

        if (otherCostsField[0].value) otherCostsField[2].setValue([...otherCostsField[0].value, ...newCost], false)
        else otherCostsField[2].setValue(newCost, false)
    }

    return (
        <>
            <Box {...rest}>
                <FormControl fullWidth>
                    <Box css={tw`flex flex-row justify-between`}>
                        <Typography component="h1" variant="h6">
                            Holding Cost ( Annual )
                        </Typography>
                        <Button size="small" color='secondary' variant="contained" onClick={() => setOpenModalAddNewCategory(true)}>
                            Add category
                        </Button>
                    </Box>

                    <Typography variant="caption" display="block" gutterBottom>
                        Insert all costs to purchase and commission this asset
                    </Typography>
                </FormControl>

                {
                    DEFAULT_METADATA.map((metadata, index: number) => (
                        <MetaDataFieldNew
                            key={`default-metadata-${index}`}
                            name={metadata.name}
                            id={metadata.name}
                            sx={{ marginTop: 3 }}
                            label={metadata.label}
                            buttonLabel="Add line"
                            withDocument
                            type="number"
                            dataMapperInversed={(metadata: FinancialMetadata): IMetaDataOutput => {
                                const { title, value, document } = metadata
                                return { key: title, value: `${value}`, documents: !!document ? [document] : [] }
                            }}
                            dataMapper={(metadata: IMetaDataInput): FinancialMetadata => {
                                let document: DocumentInput | null = null

                                if (metadata.documents && metadata.documents.length > 0) {
                                    const source = metadata.documents[0].remoteFile.filePath
                                    const mimeType = metadata.documents[0].localeFile.type
                                    document = {
                                        source,
                                        mimeType
                                    }
                                }
                                return {
                                    title: metadata.key,
                                    value: parseFloat(metadata.value || '0'),
                                    document
                                }

                            }}
                            suggestedFields={metadata.placeHolders}
                        />
                    ))
                }

                {
                    otherCostsField[0].value &&
                    otherCostsField[0].value.map((otherCost: OtherFinancialMetadata, index: number) => (
                        <MetaDataFieldNew
                            sx={{ marginTop: 3 }}
                            key={index}
                            name={`financial.holdingCost.otherCosts[${index}].metadata`}
                            id={`financial.holdingCost.otherCosts[${index}].metadata`}
                            label={otherCost.name}
                            buttonLabel="Add line"
                            withDocument
                            type="number"
                            dataMapperInversed={(metadata: FinancialMetadata): IMetaDataOutput => {
                                const { title, value, document } = metadata
                                return { key: title, value: `${value}`, documents: !!document ? [document] : [] }
                            }}
                            dataMapper={(metadata: IMetaDataInput): FinancialMetadata => {
                                let document: DocumentInput | null = null

                                if (metadata.documents && metadata.documents.length > 0) {
                                    const source = metadata.documents[0].remoteFile.filePath
                                    const mimeType = metadata.documents[0].localeFile.type
                                    document = {
                                        source,
                                        mimeType
                                    }
                                }
                                return {
                                    title: metadata.key,
                                    value: parseFloat(metadata.value || '0'),
                                    document
                                }

                            }}
                        />
                    ))
                }

            </Box>

            <MetaDataAddNewCategory
                open={openModalAddNewCategory}
                onClose={() => setOpenModalAddNewCategory(false)}
                onCreateCategory={_handleOnCreateCategory}
            />
        </>
    )
}

export default FinancialHoldingCost;