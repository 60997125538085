import tw from 'twin.macro'
import styled from "styled-components";
import { Container as ContainerBase } from 'components/misc/Layouts.js'
import { SectionHeading } from 'components/misc/Headings.js'
import { ReactElement } from 'react';

const Container = tw(ContainerBase)`bg-gray-200 -mx-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8`
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`

const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`
const Heading = tw(SectionHeading)`text-secondary-500 sm:text-center leading-none`
const Description = tw.p`text-gray-700 font-medium text-lg mt-8 sm:mt-4 text-center sm:text-center`
const SocialLinksContainer = tw.div`mt-8 md:mt-6 flex items-center justify-center`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-8 h-8`}
  }
`;

export interface ISubscribeToSocialsSection {
    heading: string;
    description: string;
    socials: {
        link: string;
        icon: ReactElement
    }[]
}

const SubscribeToSocialsSection = (props: { content: ISubscribeToSocialsSection }) => {
    return <Container>
        <Content>
            <Row>
                <TextColumn>
                    <HeadingInfoContainer>
                        <Heading>
                            {props.content.heading}
                        </Heading>
                        <Description>
                            {props.content.description}
                        </Description>
                        <SocialLinksContainer>
                            {
                                props.content.socials.map((social, index) => (
                                    <SocialLink key={index} href={social.link}>
                                        {social.icon}
                                    </SocialLink>
                                ))
                            }
                        </SocialLinksContainer>
                    </HeadingInfoContainer>
                </TextColumn>
            </Row>
        </Content>
    </Container>
}

export default SubscribeToSocialsSection