import { IKCore } from "imagekitio-react";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import './style.css'
import { BarLoader } from "react-spinners";
import { FormHelperText, CardMedia, ImageList, Chip, ImageListItem, Box, Card, IconButton } from "@mui/material";
import tw from "twin.macro";
import _, { at } from 'lodash';
import { useField } from 'formik';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const imagekit = new IKCore({
    publicKey: "public_rKhBJRST/VWaL6naL+MFfXelgjQ=",
    urlEndpoint: "https://ik.imagekit.io/brma",
    authenticationEndpoint: "https://backend.v0.1.briicksmarket.com/file-signing",
});


function srcset(image: string, size: number, rows = 1, cols = 1) {
    const c = Math.floor(Math.random() * 10)
    const height = size * rows + c;
    const width = size * cols + c;
    return {
        height,
        width,
        src: `https://ik.imagekit.io/brma/tr:w-${width},h-${height}/${image}`
    }
}

interface ITile {
    img: string;
    title: string;
    rows: number;
    cols: number;
}

function tiles(images: string[], inverse: boolean): ITile[] {
    if (images.length === 1)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: 4,
        }]
    else if (images.length === 2)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: 2,
        },
        {
            img: images[1],
            title: '',
            rows: 2,
            cols: 2,
        }]

    else if (images.length === 3)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: inverse ? 4 : 2,
        },
        {
            img: images[1],
            title: '',
            rows: 2,
            cols: 2,
        },
        {
            img: images[2],
            title: '',
            rows: 2,
            cols: inverse ? 2 : 4,
        }]

    else if (images.length === 4)
        return [{
            img: images[0],
            title: '',
            rows: inverse ? 2 : 3,
            cols: 2,
        },
        {
            img: images[1],
            title: '',
            rows: inverse ? 3 : 2,
            cols: 2,
        },
        {
            img: images[2],
            title: '',
            rows: 1,
            cols: 1,
        }
            ,
        {
            img: images[3],
            title: '',
            rows: 1,
            cols: 1,
        }]
    else if (images.length >= 5)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: 4,
        },
        {
            img: images[1],
            title: '',
            rows: inverse ? 1 : 2,
            cols: 2,
        },
        {
            img: images[2],
            title: '',
            rows: inverse ? 2 : 1,
            cols: 2,
        }
            ,
        {
            img: images[3],
            title: '',
            rows: 1,
            cols: 1,
        },
        {
            img: images[4],
            title: '',
            rows: 1,
            cols: 1,
        }]
    else return []
}

const QuiltedImageList = (props: { images: string[], name: string, removeFile: (index: number) => void }) => {

    const imagesItems = _.chain(props.images).chunk(5).reduce<ITile[]>((acc, o, index) => {
        return [...acc, ...tiles(o, index % 2 === 0)]
    }, []).value();



    return (
        <ImageList
            sx={{ marginTop: 3 }}
            variant="quilted"
            cols={4}
            gap={20}
            rowHeight={200}
        >
            {imagesItems.map((item, index: number) => {
                const { src } = srcset(item.img, 200, item.rows, item.cols);
                return (
                    <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>

                        <IconButton sx={tw`absolute text-red-500 right-0 top-0`} onClick={() => { props.removeFile(index) }}>
                            <HighlightOffIcon />
                        </IconButton>
                        <img
                            src={src}
                            alt={item.title}
                            loading="lazy"
                        >
                        </img>

                    </ImageListItem>
                )
            })}
        </ImageList>
    )
}


const _fileTypes = ["*"];

const FileField = (props: any & {
    preview?: boolean,
    fileTypes: string[],
    maxFiles?: number,
    min?: number,
    onRemoveFile?: () => void,
    dataMapper: (input: File, output: any) => any
}) => {
    const { fileTypes = _fileTypes, preview = true, maxFiles = 0, min = 0 } = props

    const [file, setFile] = useState<File | null>(null);
    const [uploding, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState<Error | null>(null)
    const handleChange = (file: File) => {
        setFile(file);
    };

    useEffect(() => {
        if (file) {
            setUploading(true)
            upload(file)
        }
    }, [file])

    const [field, meta, helper] = useField(props);
    const { setValue } = helper;
    const [error] = at(meta, 'error');
    const isError = error && true;

    const canUploadMoreFile = field.value === null || field.value === undefined || (_.isArray(field.value) && ((field.value.length < maxFiles) || (maxFiles === 0)))

    function _renderHelperText() {
        let msg = '';
        if (_.isString(error)) msg = error
        else if (_.isObject(error)) msg = _.first(Object.values(error))
        if (isError) {
            return <FormHelperText key='form-error' error sx={{ paddingLeft: 0 }}>{msg}</FormHelperText>
        } return null
    }

    function removeFile(index: number) {
        if (_.isArray(field.value)) {
            const fieldValue = field.value as Array<any>
            const value = _.remove(fieldValue, (e) => e !== fieldValue[index])
            setValue(value, false)
        } else setValue(undefined, false)
    }


    // Upload function internally uses the ImageKit.io javascript SDK
    function upload(file: File) {
        setUploadError(null);
        imagekit.upload({
            file: file,
            fileName: file.name,
            folder: 'makers/temp/'
            //tags: ["tag1"]
        }, function (err, result) {
            setUploading(false)
            if (err) {
                setUploadError(err)
            } else if (result) {
                const data = props.dataMapper(file, result)
                if (_.isArray(field.value)) {
                    const value = field.value as Array<any>
                    value.push(data)
                    setValue(value, false)
                } else setValue(data, false)


                // setFile(null)
            }
        })
    }


    return (
        <Box>
            {
                _renderHelperText()
            }

            {
                canUploadMoreFile && !uploding && <FileUploader
                    classes='file-uploader'
                    handleChange={handleChange}
                    name="file"
                    min={min}
                    types={fileTypes} />
            }

            {
                uploadError &&
                <FormHelperText key='upload-error' error sx={{ paddingLeft: 0 }}>{uploadError?.message}</FormHelperText>
            }

            <BarLoader
                key="bar-loader"
                loading={uploding}
                color="#1976d2"
                height={20}
                speedMultiplier={0.75}
                width="100%"
            />

            {
                preview && field.value && !_.isArray(field.value) &&
                <Card variant="outlined" sx={{ maxWidth: 400 }}>
                    <IconButton sx={tw`text-red-500 float-right`} onClick={() => { removeFile(0) }}>
                        <HighlightOffIcon />
                    </IconButton>
                    <CardMedia
                        key='image'
                        css={tw`max-h-96 max-w-lg mt-4`}
                        src={`https://ik.imagekit.io/brma/tr:w-400,h-400/${field.value}`}
                        component="img"
                    />
                </Card>

            }

            {
                !preview && field.value && !_.isArray(field.value) &&
                <div>
                    <Chip
                        label={file?.name || "file"}
                        onDelete={() => { removeFile(0) }}
                    />
                </div>


            }

            {
                preview && field.value && _.isArray(field.value) &&
                <QuiltedImageList name={field.name} images={field.value} removeFile={removeFile} />
            }

        </Box>
    )
}

export default FileField