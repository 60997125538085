import tw from "twin.macro";
import FullCard from "components/cards/full-card";
import { useEffect } from "react";
import { useSelector } from '../../redux/store';
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AdsDetailsActions } from '../../redux/reducers/ads-details';
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`
const ListingDetails = () => {
    const adsDetail = useSelector((state) => state.adsDetails)
    const dispatch = useDispatch()
    const { listingId = "" } = useParams()
    useEffect(() => {
      dispatch(AdsDetailsActions.adsLoadByIdRequest({ id: listingId }))
    }, [])
    return (
        <Content>
            {
                adsDetail.error && 
                <Typography component='h3' css={tw`text-sm`}>{adsDetail.error}</Typography>
            }
            {
                adsDetail.ads &&
                <FullCard ads={adsDetail.ads}/>
            }
        </Content>
    )
}

export default ListingDetails;