import Header from "components/header"
import Footer from "components/footer"
import tw from "twin.macro"
import { ContentWithPaddingXl } from 'components/misc/Layouts'
import MakerInscriptionForm from "components/form/maker-inscription"

const Container = tw.div`p-8`
const MakerRegistration = () => {

    return (
        <Container>
            <Header />
            <ContentWithPaddingXl>
                <MakerInscriptionForm />
            </ContentWithPaddingXl>

            <Footer />
        </Container>
    )
}

export default MakerRegistration;