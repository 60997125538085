import tw from "twin.macro"
import { Form, Formik, } from "formik";//FormikProps, useField
import { Avatar, Typography, Box, Grid, Button, Link, Divider } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import InputField from 'components/form-fields/input-field';

const MakerInscriptionForm = () => {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

        }}>
            { 
            //bgcolor: 'secondary.main'
            }
            <Avatar sx={tw`bg-primary-400 mt-1`} >
                <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign up
            </Typography>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                }}
                onSubmit={() => { }}
            >
                {
                    () => {
                        return (
                            <Form css={tw`mt-4 max-w-xs`}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <InputField
                                            required
                                            fullWidth
                                            id="name"
                                            label="Your name"
                                            name="name"
                                            autoComplete="off"
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="off"
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                            id="password"
                                            autoComplete="off"
                                            size="small"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputField
                                            required
                                            fullWidth
                                            name="confirm-password"
                                            label="Re-enter password"
                                            type="password"
                                            id="confirm-password"
                                            autoComplete="off"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            sx={tw`bg-secondary-500`}
                                            type="submit"
                                            fullWidth
                                            variant="contained">
                                            Sign Up
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1 }}>
                                    By creating an account, you agree to Briicks's{' '}
                                    <Link href="#" variant="caption">
                                        Conditions of Use
                                    </Link>{' '}
                                    and{' '}
                                    <Link href="#" variant="caption">
                                        Privacy Notice
                                    </Link>.
                                </Typography>

                                <Divider />

                                <Grid container css={tw`mt-1`}>
                                    <Grid item>
                                        <Typography variant="body1" gutterBottom>
                                            Already have an account?{' '}
                                            <Link href="#" variant="body1">
                                                Sign in
                                            </Link>
                                        </Typography>

                                    </Grid>
                                </Grid>

                            </Form>
                        )
                    }
                }
            </Formik>
        </Box>
    )
}

export default MakerInscriptionForm