import { useState } from 'react';
import Slider from 'react-slick';
import tw from 'twin.macro';
import styled from 'styled-components';
import ImageSlider from './image-slider';
import NoImagePlaceHolder from 'images/no_picture.png';
import AdStatus from './ad-status';
import TakersBadge from './takers-badge';
import { Listing_Badge } from 'graphql/__generated__/graphql-types';
import DaysCount from './days-count';
import AdId from './ad-id';
import TakerRestriction from './taker-restriction';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons'
import { ReactComponent as ChevronLeftIcon } from 'feather-icons/dist/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg'
import Briicks from './briicks';
import ReturnOnInvestment from './return-on-investment';
import Address from './address';
import Type from './type';
import Payment from './payment';
import AddCartWichList from './add-cart-wichlist';
import Maker from './maker';
import VistBuy from './visit-buy';
import Progress from './progress';

//min-w-96 
const Card = tw.div`min-w-86 max-w-sm overflow-hidden h-auto border-gray-400 rounded-lg border`
const ImageContainer = styled.div`
${tw`w-full h-64 lg:h-72 border-gray-400 border-b relative`}
`

//
//w-52
const Badges = tw.div`
absolute mx-2 my-2 top-0 left-0 flex-row w-48 text-xs
`

const ControlsContainer = tw.div`
absolute flex bottom-0 left-0 right-0 flex-row text-xs
items-end
`
const Controls = tw.div`
flex items-center justify-center bottom-0 right-0 p-3 bg-white 
rounded-tl-3xl place-self-end
`
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`sm:mt-0 first:ml-0 ml-4 rounded-full p-0 text-lg`}
  svg {
    ${tw`w-6 h-6`}
  }
`
const PrevButton = tw(ControlButton)``
const NextButton = tw(ControlButton)``

const DetailsContainer = tw.div`
w-full p-2
`

const Divider = tw.hr`
-mr-2 -ml-2 mt-1
`

const Grid2ColsContainer = tw.div`
flex grid grid-cols-3 pt-1
`

const AssetCard = () => {
    const [sliderRef, setSliderRef] = useState<Slider | null>(null)

    const size1 = Math.floor(Math.random() * 50) + 800
    const size2 = Math.floor(Math.random() * 60) + 800
    const images = Math.floor(Math.random() * 10) < 1 ? [NoImagePlaceHolder] : [`https://picsum.photos/${size2}/${size2}`, `https://picsum.photos/${size1}/${size1}`]

    return (
        <Card>
            <ImageContainer>
                <ImageSlider imagesSrc={images} onSliderRef={setSliderRef} />
                <Badges>
                    <AdStatus status={Listing_Badge.Open}>open</AdStatus>
                    <TakersBadge>bought by 3652 Takers</TakersBadge>
                    <DaysCount>28 days 22 Hours</DaysCount>
                </Badges>
                <Progress progress={10.59} status={Listing_Badge.Open}/>
                <ControlsContainer>
                    <div css={tw`flex-1 flex flex-row`}>
                        <AdId>35M3N5MJ</AdId>
                        <TakerRestriction />
                    </div>
                    <Controls>
                        <PrevButton onClick={sliderRef?.slickPrev}>
                            <ChevronLeftIcon />
                        </PrevButton>
                        <NextButton onClick={sliderRef?.slickNext}>
                            <ChevronRightIcon />
                        </NextButton>
                    </Controls>

                </ControlsContainer>
            </ImageContainer>
            <DetailsContainer>
                <Briicks />
                <Divider />
                <ReturnOnInvestment />
                <Divider />
                <Grid2ColsContainer>
                    <Address />
                    <Type />
                </Grid2ColsContainer>

                <Divider />

                <Grid2ColsContainer>
                    <Payment />
                    <AddCartWichList />
                </Grid2ColsContainer>

                <Divider />

                <Grid2ColsContainer>
                    <Maker />
                    <VistBuy />
                </Grid2ColsContainer>
            </DetailsContainer>
        </Card>
    )
}

export default AssetCard;