import { HighlightedListingsActions } from "redux/reducers/highlightedListings";
import { put, takeLatest } from "redux-saga/effects";
import clientGraphQL from "graphql/client";
import { BriicksHighlightedListingsDocument, BriicksHighlightedListingsQuery, BriicksHighlightedListingsQueryVariables } from '../../graphql/__generated__/graphql-types';
import {
    PayloadAction,
} from "@reduxjs/toolkit";

function* loadHighlightedListingsAsyncRequest(action: PayloadAction<BriicksHighlightedListingsQueryVariables>){
    const args = action.payload
    try {
        const briicksHighlightedListingsDocument: BriicksHighlightedListingsQuery = yield clientGraphQL.request(BriicksHighlightedListingsDocument, args)
        yield put(HighlightedListingsActions.loadHighlightedListingsSuccess(briicksHighlightedListingsDocument.briiksHighlightedListings));
    } catch (error) {
        //console.log(error)
        yield put(HighlightedListingsActions.loadHighlightedListingsFaillure("error"));
        
    }
}
/* ------------- Connect Types To Sagas ------------- */
export const HighlightedListingSaga = [
    //reducer-name/reducer-function
    takeLatest('highlightedListings/loadHighlightedListingsRequest', loadHighlightedListingsAsyncRequest,
    ),
];