import { useMemo, useEffect, useState } from "react";

export function useIsInViewport(ref: any): boolean {

  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () => {
      const option = {
        root: null,
        rootMargin: '0px',
        threshold: 0
      }
      return new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting)
      },
        option
      )
    },
    [],
  );

  useEffect(() => {



    observer.observe(ref.current);


    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
