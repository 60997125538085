//import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { Coordinate } from 'recharts/types/util/types';

const Maps = (props: { coordinates: Coordinate} & any) => {
    const {coordinates} = props
    return (
        <MapContainer
            style={{ width: "100%", height: "100%" }}
            center={[coordinates.latitude, coordinates.longitude]} zoom={13} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[coordinates.latitude, coordinates.longitude]}>
                {
                    false &&
                    <Popup>
                        <h1>Title</h1>. <br /> <p>Descritpion</p>
                    </Popup>
                }

            </Marker>
        </MapContainer>

    )
}

export default Maps;