import { put, takeLatest } from "redux-saga/effects";
import { AdsListingItemsActions } from '../../reducers/maker/ads-listing-items';
import clientGraphQL from "graphql/client";
import { AdsListingItemsDocument, AdsListingItemsPaginateResult, AdsListingItemsQuery, Error, AdsListingItemsQueryVariables, AdsDeleteItemByIdMutation, AdsDeleteItemByIdDocument, AdsDeleteItemByIdMutationVariables } from '../../../graphql/__generated__/graphql-types';
import { PayloadAction } from '@reduxjs/toolkit';

function* loadAdsListingItemsAsyncRequest(action: PayloadAction<AdsListingItemsQueryVariables>) {
    try {
        const args = action.payload;
        const adsListingItemsQuery: AdsListingItemsQuery = yield clientGraphQL.request(AdsListingItemsDocument, args);
        if (adsListingItemsQuery.adsListItems.__typename === "AdsListingItemsPaginateResult")
            yield put(AdsListingItemsActions.loadAdsListingItemsRequestSuccess(adsListingItemsQuery.adsListItems as AdsListingItemsPaginateResult));
        else if (adsListingItemsQuery.adsListItems.__typename === "Error")
            yield put(AdsListingItemsActions.loadAdsListingItemsRequestFailed((adsListingItemsQuery.adsListItems as Error).message))
    } catch (error) {
        yield put(AdsListingItemsActions.loadAdsListingItemsRequestFailed("error"))
    }
}

function* deleteAdsListingItemAsyncRequest(action: PayloadAction<AdsDeleteItemByIdMutationVariables>) {
    try {
        const args = action.payload;
        const adsDeleteItemByIdMutation: AdsDeleteItemByIdMutation = yield clientGraphQL.request(AdsDeleteItemByIdDocument, args);
        if (adsDeleteItemByIdMutation.adsDeleteItemById.__typename === "Success")
            yield put(AdsListingItemsActions.deleteAdsListingItemRequestSuccess(args));
        else if (adsDeleteItemByIdMutation.adsDeleteItemById.__typename === "Error")
            yield put(AdsListingItemsActions.deleteAdsListingItemRequestFailed((adsDeleteItemByIdMutation.adsDeleteItemById as Error).message))
    } catch (error) {
        yield put(AdsListingItemsActions.deleteAdsListingItemRequestFailed("error"))
    }
}

export const AdsListingItemsSaga = [
    takeLatest('AdsListingItemsSlice/loadAdsListingItemsRequest', loadAdsListingItemsAsyncRequest,
    ),
    takeLatest('AdsListingItemsSlice/deleteAdsListingItemRequest', deleteAdsListingItemAsyncRequest,
    ),
]