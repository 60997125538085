import tw from 'twin.macro';
import parse from 'html-react-parser';
import styled from 'styled-components';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const RestrictionContent = styled.div`
${tw`mt-2`}
h1{
 ${tw`font-semibold py-2 mt-2 text-lg`}
}
a{
    ${tw`text-primary-400`}
}
`

const restrinctionsContent = `
<h1>1000 BRIICK ONLY</h1>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu volutpat metus. Nam placerat, ante a malesuada rhoncus, turpis mi eleifend augue, non tempus justo ex vel ex. Donec in lectus ut leo pharetra cursus. Duis et tellus sit amet quam feugiat cursus ut vel quam. Duis vel maximus lectus.</p>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut rutrum tellus. Nam ut dolor rutrum, dictum nisl in, tincidunt.</p>
<a href='https://www.google.com'>google.com</a>
<h1>REG. D US QUALIFY</h1>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vulputate tincidunt justo, a semper magna mollis vel. Curabitur facilisis eros vel lorem tristique tempor. Sed tristique tortor nunc, et porttitor nunc vehicula vitae. Sed malesuada erat lectus, in egestas odio porttitor vitae. Donec sodales, libero non iaculis luctus, leo purus fringilla dui, ullamcorper sodales ante purus quis mauris. Aliquam eu auctor nisi. In tincidunt diam at ante sollicitudin, et suscipit.</p>
`

const Restrinctions = (props: {id? : any, viewPortObserver?: (isVisible: boolean) => void }) => {

    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Restrinctions</Headline>
            <Divider />
            
            <RestrictionContent>
                {
                    parse(restrinctionsContent, { trim: true})
                }
            </RestrictionContent>
        </ArticleContainer>
    )
}

export default Restrinctions;
