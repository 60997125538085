import tw from 'twin.macro';
import styled from 'styled-components';
import { Listing_Badge } from '../../../graphql/__generated__/graphql-types';

const Span = styled.span<{ status: Listing_Badge }>(({ status }) => [
    status === Listing_Badge.NewLaunch && tw`bg-green1-500`,
    status === Listing_Badge.AlmostDone && tw`bg-orange1-500`,
    status === Listing_Badge.SoldOut && tw` bg-red1-500`,
    status === Listing_Badge.ComingSoon && tw` bg-blue-500`,
    status === Listing_Badge.Open && tw`bg-teal-500`,
    status === Listing_Badge.LastChance && tw` bg-yellow-500`,
    status === Listing_Badge.NotFunded && tw` bg-secondary-100`,

    tw`w-full rounded-md text-center p-1 text-white uppercase block`
])
//tw`absolute w-24 rounded-md text-center mx-2 my-2 px-2 py-1 text-white uppercase top-0 left-0`
export const AdStatus = (props: {
    status: Listing_Badge,
    children: React.ReactNode
}) => {
    return (
        <Span status={props.status}>{props.children}</Span>
    )
}

export default AdStatus;