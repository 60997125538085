//import styled from 'styled-components';
import { FC } from 'react';
import tw from 'twin.macro';

const Conatiner = tw.div`
flex justify-center pt-1
`
const Content = tw.div`
inline p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 
`
const Title = tw.span`
font-medium
`
const ErrorAlert: FC<{message: string}> = (props) => {
    return <>
        <Conatiner>
            <Content>
                <Title>Error! </Title>
                {props.message}
            </Content>
        </Conatiner>
    </>
}

export default ErrorAlert;