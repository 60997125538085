import {
    createSlice,
    PayloadAction,
    //current,
} from "@reduxjs/toolkit";
import { AdsListingItemsPaginateResult, AdsListingItemsQueryVariables } from "graphql/__generated__/graphql-types";
import produce from 'immer'
import { AdsListing, AdsDeleteItemByIdMutationVariables } from '../../../graphql/__generated__/graphql-types';
import _ from 'lodash';

export interface AdsListingItemsState {
    adsListingItemsPage: AdsListingItemsPaginateResult;
    action: {
        loading: boolean;
        error: string;
    }
}

const initialState: AdsListingItemsState = {
    adsListingItemsPage: {
        docs: [],
        limit: 1,
        page: 1,
        totalDocs: 0,
        totalPages: 0,
        pagingCounter: 0,
    },
    action: {
        loading: false,
        error: '',
    }
}
export const AdsListingItemsSlice = createSlice({
    name: 'AdsListingItemsSlice',
    initialState,
    reducers: {
        loadAdsListingItemsRequest: (state, _action: PayloadAction<AdsListingItemsQueryVariables>) => {
            state.action.loading = true;
            state.action.error = ''
        },
        loadAdsListingItemsRequestSuccess: (state, action: PayloadAction<AdsListingItemsPaginateResult>) => {
            state.action.loading = false;
            state.adsListingItemsPage = action.payload;
        },
        loadAdsListingItemsRequestFailed: (state, action: PayloadAction<string>) => {
            state.action.loading = false;
            state.action.error = action.payload;
        },
        deleteAdsListingItemRequest: (state, _action: PayloadAction<AdsDeleteItemByIdMutationVariables>) => {
            state.action.loading = true;
            state.action.error = ''
        },
        deleteAdsListingItemRequestSuccess: (state, action: PayloadAction<AdsDeleteItemByIdMutationVariables>) => {
            return produce(state, (draftState) => {
                draftState.action.loading = false;
                draftState.adsListingItemsPage.docs = _.filter(state.adsListingItemsPage.docs, (e: AdsListing) => (e._id !== action.payload._id))
            });
        },
        deleteAdsListingItemRequestFailed: (state, action: PayloadAction<string>) => {
            state.action.loading = false;
            state.action.error = action.payload;
        },
    }
})

export const AdsListingItemsActions = AdsListingItemsSlice.actions
export default AdsListingItemsSlice