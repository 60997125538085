import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { Ads, FinancialMetadata } from 'graphql/__generated__/graphql-types';
import _ from 'lodash'


const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Documents = (props: { ads: Ads, id?: any, viewPortObserver?: (isVisible: boolean) => void }) => {

    const { spv, asset, financial, rental, flipping } = props.ads

    let docs: { name: string, type: string, source: string }[] = [];
    docs.push({
        name: "Establishement Certificate",
        type: spv.documents.establishementCertificate.mimeType,
        source: spv.documents.establishementCertificate.source
    })

    if (spv.documents.otherDocuments) spv.documents.otherDocuments.forEach(element => {
        docs.push({
            name: element.description,
            type: element.document.mimeType,
            source: element.document.source
        })
    });

    if (asset.assetCondition.event.constructionYear.document && !_.isEmpty(asset.assetCondition.event.constructionYear.document.source))
        docs.push({
            name: "Construction Year",
            type: asset.assetCondition.event.constructionYear.document.mimeType,
            source: asset.assetCondition.event.constructionYear.document.source
        })

    const extractDocFromMetadata = (metadata?: FinancialMetadata[]) => {
        if (metadata) metadata.forEach(element => {
            if (element.document && !_.isEmpty(element.document.source))
                docs.push({
                    name: element.title,
                    type: element.document.mimeType,
                    source: element.document.source
                })
        })
    }

    console.log(asset.assetCondition.event.eventMetadata)

    if (asset.assetCondition.event.eventMetadata) asset.assetCondition.event.eventMetadata.forEach(element => {
        if (element.document)
            docs.push({
                name: element.title,
                type: element.document.mimeType,
                source: element.document.source
            })
    });

    extractDocFromMetadata(financial.assetValue.metadata || undefined);
    extractDocFromMetadata(financial.aquisitionCost.closingCost || undefined);
    extractDocFromMetadata(financial.aquisitionCost.reserve || undefined);
    extractDocFromMetadata(financial.aquisitionCost.setupCost || undefined);
    if (financial.aquisitionCost.otherCosts) financial.aquisitionCost.otherCosts.forEach(el => {
        extractDocFromMetadata(el.metadata);
    })

    extractDocFromMetadata(financial.holdingCost.administrative || undefined);
    extractDocFromMetadata(financial.holdingCost.debt || undefined);
    extractDocFromMetadata(financial.holdingCost.inusrance || undefined);
    extractDocFromMetadata(financial.holdingCost.maintenance || undefined);
    extractDocFromMetadata(financial.holdingCost.management || undefined);
    extractDocFromMetadata(financial.holdingCost.taxes || undefined);
    extractDocFromMetadata(financial.holdingCost.utilities || undefined);
    if (financial.holdingCost.otherCosts) financial.holdingCost.otherCosts.forEach(el => {
        extractDocFromMetadata(el.metadata);
    })

    extractDocFromMetadata(rental?.investment.metadata || undefined);

    extractDocFromMetadata(flipping?.investment.metadata || undefined);
    extractDocFromMetadata(flipping?.income.metadata || undefined);
    extractDocFromMetadata((flipping?.operationDuration.metadata as FinancialMetadata[]) || undefined);


    /*const docs = [
        {
            name: 'Document 1',
            description: 'C\'est ceci et cela',
            type: 'pdf',
            size: '3 Mo'
        },
        {
            name: 'Document 2',
            description: 'C\'est ceci et cela',
            type: 'image',
            size: '500 Kb   '
        }
    ]*/



    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Documents</Headline>
            <Divider />

            <Table css={tw`min-w-86`} size="small" >
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {docs.map((e, index) => (<TableRow key={index}>
                        <TableCell>{e.name}</TableCell>
                        <TableCell>{e.type === 'application/pdf' ? "pdf" : e.type}</TableCell>
                        <TableCell align="right">
                            <Link href={`https://ik.imagekit.io/brma${e.source}`} target="_blank">Preview</Link>
                        </TableCell>
                    </TableRow>))}
                </TableBody>
            </Table>
        </ArticleContainer>
    )
}

export default Documents;