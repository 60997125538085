import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import Details from './details';
import ArticleContainer from 'components/article/article-container';
import { Ads } from 'graphql/__generated__/graphql-types';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2  w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2 flex flex-row
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`
const Investment = (props: {ads: Ads, id? : any, viewPortObserver?: (isVisible: boolean) => void }) => {
    const { ads } = props;
    const { assetValue, aquisitionCost, /*holdingCost */} = ads.financial;
    const totalInvestment = assetValue.totalAssetValue + aquisitionCost.totalCost;
    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Investment</Headline>
            <Divider />
            <Card>
                <CardHead>
                    <HeadLine>Total Investment</HeadLine>
                    <Value css={tw`text-lg`}>
                        <NumberFormat
                            value={totalInvestment}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </CardHead>
                <Content>
                    <Label>Underlying Asset</Label>
                    <Value>
                        <NumberFormat
                            value={assetValue.totalAssetValue}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Operation Cost</Label>
                    <Value>
                        <NumberFormat
                            value={aquisitionCost.totalCost}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>
            </Card>

            <Details aquisitionCost={aquisitionCost} assetValue={assetValue}/>
        </ArticleContainer>
    )
}

export default Investment