import Header from "components/header"
import tw from 'twin.macro';
import styled from 'styled-components';


const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25 w-full`
//const OpacityOverlay = tw.div``
const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`
const LeftColumn = tw.div`flex flex-col items-center lg:block`
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg text-white`
const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: '';
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`

const PrimaryAction = styled.button`
${tw`px-8 py-3 mt-0 text-sm sm:text-base sm:mt-0 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`}
`

export interface IMainSection {
    HeadingText: {
      normal: string;
      highlighted: string;
      
    };
    description:string;
    CTAButton: {
      text: string;
      link: string;
    };
  }

const MainSection = (props : {content : IMainSection}) => {
    return (
        <Container>
            <OpacityOverlay />
            <HeroContainer>
                <Header appearance="light" />
                <TwoColumn>
                    <LeftColumn>
                        <Heading>
                            <span>{props.content.HeadingText.normal}</span>
                            {
                                props.content.HeadingText.highlighted && <>
                                    <br />
                                    <SlantedBackground>{props.content.HeadingText.highlighted}</SlantedBackground>
                                </>
                            }
                        </Heading>
                        <Paragraph>{props.content.description}</Paragraph>
                        <PrimaryAction as='a' href={props.content.CTAButton.link}>{props.content.CTAButton.text}</PrimaryAction>
                    </LeftColumn>
                    <RightColumn></RightColumn>
                </TwoColumn>
            </HeroContainer>
        </Container>
    )
}


export default MainSection