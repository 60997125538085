import { IKCore } from "imagekitio-react";
import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import './style.css'
import { BarLoader } from "react-spinners";
import { FormHelperText, Chip } from "@mui/material";

const imagekit = new IKCore({
    publicKey: "public_rKhBJRST/VWaL6naL+MFfXelgjQ=",
    urlEndpoint: "https://ik.imagekit.io/brma",
    authenticationEndpoint: "https://backend.v0.1.briicksmarket.com/file-signing",
});




const _fileTypes = ["*"];
export interface IDocument {
    localeFile: File;
    remoteFile: {
        filePath: string
    };
}

const JoinFile = (props: any & {
    fileTypes: string[],
    maxFiles?: number,
    onJoinDocument: (documents: IDocument[]) => void,
}) => {
    const { fileTypes = _fileTypes, onJoinDocument, maxFiles = 0 } = props
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [file, setFile] = useState<File | null>(null);

    const canUploadMoreFile = maxFiles === 0 || maxFiles > documents.length

    const [uploding, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState<Error | null>(null)
    const handleChange = (file: File) => {
        setFile(file);
    };

    useEffect(() => {
        if (file) {
            setUploading(true)
            upload(file)
        }
    }, [file])

    useEffect(()=> {
        onJoinDocument(documents)
    }, [documents])


    // Upload function internally uses the ImageKit.io javascript SDK
    function upload(file: File) {
        setUploadError(null);
        imagekit.upload({
            file: file,
            fileName: file.name,
            folder: 'makers/temp/'
            //tags: ["tag1"]
        }, function (err, result) {
            setUploading(false)
            if (err) {
                setUploadError(err)
            } else if (result) {
                setDocuments([...documents, {
                    localeFile: file,
                    remoteFile: result
                }])
            }
        })
    }


    return (
        <>

            {
                canUploadMoreFile && !uploding && <FileUploader
                    classes='file-uploader'
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes} />
            }

            {
                uploadError &&
                <FormHelperText error sx={{ paddingLeft: 0 }}>{uploadError?.message}</FormHelperText>
            }

            <BarLoader
                loading={uploding}
                color="#1976d2"
                height={20}
                speedMultiplier={0.75}
                width="100%"
            />

            {
                documents.map((e, index) => (
                    <Chip
                        key={index}
                        label={e.localeFile.name}
                        onDelete={() => { }}
                    />
                ))

            }


        </>
    )
}

export default JoinFile