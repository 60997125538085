import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
const Container = tw.div`
col-span-1
`
const Heading2 = tw.h2`
font-medium text-base text-secondary-900 mr-1
`
const Val = tw.div`
text-sm text-secondary-500
`

const Type = () => {
    return (
        <Container>
            <Heading2>Type</Heading2>
            <Val>Residential</Val>
            <Val>Vacant</Val>
            <Val>1 bed | 1.5 bath</Val>
            <Val>
                <NumberFormat
                    value={1025}
                    decimalScale={0}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    suffix={" m²"}
                    renderText={(value: string) => <span>{value}</span>}
                />
            </Val>
        </Container>
    )
}

export default Type;