import tw from 'twin.macro';
const Container = tw.div`
col-span-2 pr-1
`
const Heading2 = tw.h2`
font-medium text-base text-secondary-900 mr-1
`
const Val = tw.div`
text-sm text-secondary-500
`

const Address = () => {
    return (
        <Container>
            <Heading2>Address</Heading2>
            <Val>11501 Century Oaks Terrace</Val>
            <Val>Austin, Texas 78758</Val>
            <Val>United States</Val>
        </Container>
    )
}

export default Address;