import { FormControl, Typography, TextField } from "@mui/material"
import MetaDataField from "components/form-fields/metadata-field__"
import { IMetaDataInput, IMetaDataOutput } from '../../../../components/form-fields/metadata-field__/index';
import { FinancialMetadataInput, Financial, Rental, DocumentInput } from '../../../../graphql/__generated__/graphql-types';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect } from "react";
import _ from 'lodash'
import InputField from "components/form-fields/input-field";
import { useField } from "formik";
import tw from "twin.macro"
import MetaDataFieldNew from 'components/form-fields/metadata-field-new';


const Container = tw.div`
flex p-4 flex-col
`

const RentalTransaction = (props: { rental?: Rental, financial?: Financial } & any) => {
    const {
        financial,
        rental
    } = props

    const investmentTotalAmountField = useField('rental.investment.totalAmount')

    useEffect(() => {
        const metadata = rental?.investment?.metadata || []
        const totalAmount = _.reduce(metadata, (acc: number, o: FinancialMetadataInput) => (acc + o.value), 0);
        //(financial?.aquisitionCost?.totalCost || 0) + (financial?.assetValue?.totalAssetValue || 0)
        /*if (rental?.investment?.metadata) {
            totalInvest = _.reduce(rental.investment.metadata, (acc: number, o: FinancialMetadataInput) => (acc + o.value), totalInvest)
        }*/

        investmentTotalAmountField[2].setValue(totalAmount, false)
        
    }, [rental?.investment?.metadata])

    function drawInvestmentPrefixe() {
        return (
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Asset Value
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={financial?.assetValue?.totalAssetValue || 0}
                            size="small"
                            css={tw`bg-gray-200`}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Acquisition Cost
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={financial?.aquisitionCost?.totalCost || 0}
                            css={tw`bg-gray-200`}
                            size="small"
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    function drawInvestment() {
        return (
            <MetaDataFieldNew
                name="rental.investment.metadata"
                id="rental.investment.metadata"
                label="Investment"
                buttonLabel="Add line"
                type="number"
                withDocument
                prefixComponent={
                    drawInvestmentPrefixe()
                }
                dataMapperInversed={(metadata: FinancialMetadataInput): IMetaDataOutput => {
                    const { title, value, document } = metadata
                    return { key: title, value: `${value}`, documents: !!document ? [document] : [] }
                }}
                dataMapper={(metadata: IMetaDataInput): FinancialMetadataInput => {

                    let document: DocumentInput | null = null

                    if (metadata.documents && metadata.documents.length > 0) {
                        const source = metadata.documents[0].remoteFile.filePath
                        const mimeType = metadata.documents[0].localeFile.type
                        document = {
                            source,
                            mimeType
                        }
                    }
                    return {
                        title: metadata.key,
                        value: parseFloat(metadata.value),
                        document
                    }

                }}
                suggestedFields={[
                    { key: "key", value: "100 000" }
                ]}
            />
        )
    }

    function drawLeaseDetailsPrefix() {
        return (
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Type*
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputField
                            name="rental.leaseDetails.type"
                            id="rental.leaseDetails.type"
                            size="small"
                            placeholder="Long-term"
                        />
                    </FormControl>
                </Grid>

                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Statut*
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputField
                            name="rental.leaseDetails.statut"
                            id="rental.leaseDetails.statut"
                            size="small"
                            placeholder="Rented"
                        />
                    </FormControl>
                </Grid>

                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Start
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputField
                            name="rental.leaseDetails.startDate"
                            id="rental.leaseDetails.startDate"
                            size="small"
                            placeholder=""
                            type='date'
                        />
                    </FormControl>
                </Grid>

                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        End
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputField
                            name="rental.leaseDetails.endtDate"
                            id="rental.leaseDetails.endtDate"
                            size="small"
                            placeholder=""
                            type='date'
                        />
                    </FormControl>
                </Grid>

                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Settlement Frequency
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputField
                            name="rental.leaseDetails.settlementFrequency"
                            id="rental.leaseDetails.settlementFrequency"
                            size="small"
                            placeholder="Weekly"
                        />
                    </FormControl>
                </Grid>

                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                    Annual Gross Rent*
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputField
                            name="rental.leaseDetails.annualGrossRent"
                            id="rental.leaseDetails.annualGrossRent"
                            size="small"
                            placeholder="12 000.00"
                            valueType='Float'
                        />
                    </FormControl>
                </Grid>


            </Grid>
        )
    }

    function drawLeaseDetails() {
        return (
            <MetaDataFieldNew
                name="rental.leaseDetails.metadata"
                id="rental.leaseDetails.metadata"
                label="Lease Details"
                buttonLabel="Add line"
                prefixComponent={
                    drawLeaseDetailsPrefix()
                }
            />
        )
    }
    return (
        <Container>
            <FormControl fullWidth>
                {drawInvestment()}
            </FormControl>

            <FormControl fullWidth sx={{ paddingTop: 3 }}>
                {drawLeaseDetails()}
            </FormControl>
        </Container>
    )
}

export default RentalTransaction
