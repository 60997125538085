import tw from "twin.macro";
import SwitchField from "components/form-fields/switch-field";
import { styled } from '@mui/material/styles';
import { FormControl, TextField, Typography } from "@mui/material";
import { BriicksRestrictionItemInput, BriicksRestrictionsInput, DistributionInput } from '../../../../graphql/__generated__/graphql-types';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import BriicksRestrictionsField from "components/form-fields/briicks-restrictions-field";
import _ from 'lodash'
import { forwardRef, useImperativeHandle, useState } from "react";

const Container = tw.div`
flex p-4 flex-col
`

const HasRestrictions = styled(SwitchField)({
    '& .MuiFormControlLabel-labelPlacementStart': {
        marginLeft: 0,
        justifyContent: "space-between",
    }
})

const AllowanceLine = (props: {
    label: string;
    value: number;
    percent: number;
    helperText?: string;
    error?: boolean;
} & any) => {
    const { label, percent, helperText = null, value, error = false } = props
    return (
        <>
            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                <Typography component="h3" variant="h6" css={tw`text-sm`}>
                    {label}
                </Typography>
            </Grid>
            <Grid xs={2}>
                <FormControl fullWidth>
                    <TextField
                        error={error}
                        size="small"
                        value={value}
                        helperText={helperText}
                        inputProps={
                            {
                                readOnly: true,
                                style: tw`bg-gray-200`
                            }
                        }
                    />
                </FormControl>
            </Grid>
            <Grid xs={2}>
                <FormControl fullWidth>
                    <TextField
                        size="small"
                        value={`${percent.toFixed(0)}%`}
                        inputProps={
                            {
                                readOnly: true,
                                style: tw`bg-gray-200`
                            }
                        }
                    />
                </FormControl>
            </Grid>
        </>
    )
}

const BriicksRestrictions = forwardRef((props: {
    restrictions: BriicksRestrictionsInput,
    distribution: DistributionInput,
    errors?: { restrictionItems: string },
} & any, ref) => {
    const [submited, setSubmiyed] = useState(false);
    useImperativeHandle(ref, () => ({
        submited() {
            setSubmiyed(true);
        }
    }));

    const { restrictions, distribution, errors } = props

    let totalRestriction = 0
    if (restrictions && restrictions.restrictionItems)
        totalRestriction = _.reduce(restrictions.restrictionItems,
            (acc: number, restriction: BriicksRestrictionItemInput) => (acc + restriction.quantity), 0)

    const calculatePercent = (quantite: number) => (quantite * 100 / distribution.sharesOnSale)

    return (
        <Container>
            <FormControl fullWidth>
                <Typography component="h1" variant="h6">
                    Restrictions
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    Here you can inform investors of the existence of restrictions on all or part of the shares.
                    These restrictions can for example be the cause of various factors such as local law,
                    nationality or other
                </Typography>
                <HasRestrictions
                    css={tw`w-144`}
                    name="restrictions.hasRestriction"
                    label="Do you want to apply restriction rules on the sale of shares ?"
                    labelPlacement="start" />
            </FormControl>

            {
                restrictions.hasRestriction &&
                <>
                    <FormControl fullWidth sx={{ marginTop: 3 }}>
                        <Typography component="h1" variant="h6">
                            Allowance
                        </Typography>

                        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 5 }} sx={{ marginTop: 1 }}>
                            <AllowanceLine
                                label="Total for sale"
                                percent={100}
                                value={distribution.sharesOnSale}
                            />
                            <AllowanceLine
                                label="Without restriction"
                                percent={calculatePercent(distribution.sharesOnSale - totalRestriction)}
                                value={distribution.sharesOnSale - totalRestriction}
                            />
                            <AllowanceLine
                                label="With restriction"
                                percent={calculatePercent(totalRestriction)}
                                value={totalRestriction}
                                error={!!errors?.restrictionItems && submited}
                                helperText={submited ? errors?.restrictionItems : null}
                            />
                        </Grid>
                    </FormControl>

                    
                        <FormControl fullWidth sx={{ marginTop: 4 }}>
                        <BriicksRestrictionsField
                            label='Restrictions'
                            buttonLabel='Add new restriction'
                            maxQuantityValue={distribution.sharesOnSale - totalRestriction}
                            totalBriicksForSale={distribution.sharesOnSale}
                        />
                    </FormControl>
                    
                </>
            }

        </Container>
    )
})

export default BriicksRestrictions