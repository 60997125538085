import { Box, FormControl, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import InputField from "components/form-fields/input-field";
import { useField } from "formik";
import { useEffect } from "react";
import tw from "twin.macro";

const BriicksMinMaxComponent = (props: {price: number, totalBriicks: number} & any) => {
    const { price, totalBriicks } = props

    const minField = useField("briicksMinMax.min")
    const maxField = useField("briicksMinMax.max")

    useEffect(()=> {
        if(!minField[0].value) minField[2].setValue(1, false)
        if(!maxField[0].value) maxField[2].setValue(totalBriicks, false)

    }, [])

    const minBriicksPrice = (minField[0].value || 1) * price
    const maxBriicksPrice = (maxField[0].value || 1) * price

    return (
        <Box sx={{ marginTop: 3 }}>
            <Typography component="h1" variant="h6">
                Min/Max Briicks Purchase
            </Typography>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 5 }} sx={{ marginTop: 1 }}>
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Minimum of BRIICK*
                    </Typography>
                </Grid>

                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputField
                            name='briicksMinMax.min'
                            id='briicksMinMax.min'
                            size="small"
                            valueType='Int'
                            min={1}
                        />
                    </FormControl>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={minBriicksPrice.toFixed(2)}
                            size="small"
                            css={tw`bg-gray-200`}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>





                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                      Maximum of BRIICK*
                    </Typography>
                </Grid>

                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputField
                            name='briicksMinMax.max'
                            id='briicksMinMax.max'
                            size="small"
                            valueType='Int'
                        />
                    </FormControl>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={maxBriicksPrice.toFixed(2)}
                            size="small"
                            css={tw`bg-gray-200`}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}
export default BriicksMinMaxComponent;