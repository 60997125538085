import tw from 'twin.macro';
import AssetCard from 'components/cards/asset';
import { Content2Xl as ContainerBase } from 'components/misc/Layouts.js'
const ListingContainer = tw.div`flex flex-col items-center flex-grow`
const Grid = tw.div`grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 items-center relative`
const Container = tw(ContainerBase)``

const NewCardPage = () => {
    const list = Array(27).fill('1')
    return (

        <Container>
            <ListingContainer>
                <Grid css={tw`px-4 sm:px-0`}>
                    {
                        list.map((_listing, index) =>
                            <div key={index}>
                                <AssetCard />
                            </div>
                        )
                    }
                </Grid>
            </ListingContainer>
        </Container>

    )
}
export default NewCardPage