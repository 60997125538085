import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import _ from 'lodash';

function srcset(image: string, size: number, rows = 1, cols = 1) {
    const c = Math.floor(Math.random() * 10)
    return {
        src: `${image}${size * cols + c}/${size * rows + c}`
    }
    /*return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };*/
}

interface ITile {
    img: string;
    title: string;
    rows: number;
    cols: number;
}

function tiles(images: string[], inverse: boolean): ITile[] {
    if (images.length === 1)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: 4,
        }]
    else if (images.length === 2)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: 2,
        },
        {
            img: images[1],
            title: '',
            rows: 2,
            cols: 2,
        }]

    else if (images.length === 3)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: inverse ? 4 : 2,
        },
        {
            img: images[1],
            title: '',
            rows: 2,
            cols: 2,
        },
        {
            img: images[2],
            title: '',
            rows: 2,
            cols: inverse ? 2 : 4,
        }]

    else if (images.length === 4)
        return [{
            img: images[0],
            title: '',
            rows: inverse ? 2 : 3,
            cols: 2,
        },
        {
            img: images[1],
            title: '',
            rows: inverse ? 3 : 2,
            cols: 2,
        },
        {
            img: images[2],
            title: '',
            rows: 1,
            cols: 1,
        }
            ,
        {
            img: images[3],
            title: '',
            rows: 1,
            cols: 1,
        }]
    else if (images.length >= 5)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: 4,
        },
        {
            img: images[1],
            title: '',
            rows: inverse ? 1 : 2,
            cols: 2,
        },
        {
            img: images[2],
            title: '',
            rows: inverse ? 2 : 1,
            cols: 2,
        }
            ,
        {
            img: images[3],
            title: '',
            rows: 1,
            cols: 1,
        },
        {
            img: images[4],
            title: '',
            rows: 1,
            cols: 1,
        }]
    else return []
}

const QuiltedImageList = () => {

    const len = 5 //Math.floor((Math.random() ) * 8)+ 1

    const imgs = Array(len).fill('').map(() => {
        //const size = Math.floor(Math.random() * 50) + 800
        //return `https://picsum.photos/${size}/${size}`
        return `https://picsum.photos/`
    })

    const imagesItems = _.chain(imgs).chunk(5).reduce<ITile[]>((acc, o, index) => {
        return [...acc, ...tiles(o, index % 2 === 0)]
    }, []).value();



    return (
        <ImageList
            //sx={{ width: 500, height: 450 }}
            variant="quilted"
            cols={4}
            rowHeight={200}
        >
            {imagesItems.map((item) => (
                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                    <img
                        {...srcset(item.img, 200, item.rows, item.cols)}
                        alt={item.title}
                        loading="lazy"
                    />
                </ImageListItem>
            ))}
        </ImageList>
    )
}
/*
const itemData = [
    {
        img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        title: 'Breakfast',
        rows: 2,
        cols: 4,
    },
    {
        img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
        title: 'Burger',
        rows: 1,
        cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        title: 'Camera',
        rows: 2,
        cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
        title: 'Coffee',
    },
    {
        img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
        title: 'Hats',
    },

    {
        img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
        title: 'Honey',
        author: '@arwinneil',
        rows: 2,
        cols: 4,
    },
    {
        img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
        title: 'Basketball',
        rows: 2,
        cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
        title: 'Fern',
        rows: 1,
        cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
        title: 'Mushrooms',
    },
    {
        img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
        title: 'Tomato basil',
    },

    {
        img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
        title: 'Sea star',
        rows: 2,
        cols: 2,
    },
    {
        img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
        title: 'Bike',
        cols: 2,
        rows: 2,
    },
];
*/
export default QuiltedImageList;