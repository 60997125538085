
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { Asset, AssetFeatures, EventMetadata, Metadata } from 'graphql/__generated__/graphql-types';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2 w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`

const SubHeadLine = tw.h1`
text-primary-500 text-sm font-medium uppercase
`

const Feature = (props: { feature: AssetFeatures } & any) => {
    const { feature } = props
    return (
        <>
            <Content>
                <SubHeadLine>
                    {feature.name}
                </SubHeadLine>
            </Content>
            {
                feature.metadata.map((e: Metadata, index: number) => (
                    <Content key={index}>
                        <Label>{e.key}</Label>
                        <Value>{e.value}</Value>
                    </Content>
                ))
            }
        </>
    )

}

const Features = (props: { asset: Asset, id?: any, viewPortObserver?: (isVisible: boolean) => void }) => {

    const { assetType, assetCondition, assetFeatures } = props.asset

    const hasDescription = assetFeatures.reduce((acc: boolean, feature: AssetFeatures) => {
        return (feature.metadata && feature.metadata.length > 0) || acc
    }, false)

    //console.log("hasDescription", hasDescription)

    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Features</Headline>
            <Divider />
            <Card>
                <CardHead>
                    <HeadLine>
                        Type
                    </HeadLine>
                </CardHead>
                <Content>
                    <Label>Destination</Label>
                    <Value>{assetType.destination}</Value>
                </Content>
                <Content>
                    <Label>type</Label>
                    <Value>{assetType.type}</Value>
                </Content>
                <Content>
                    <Label>Area</Label>
                    <Value>{assetType.area}</Value>
                </Content>
                {
                    assetType.metadata?.map((e: Metadata, index: number) => (
                        <Content key={index}>
                            <Label>{e.key}</Label>
                            <Value>{e.value}</Value>
                        </Content>
                    ))
                }
                {/*** CONDITION */}
                <CardHead>
                    <HeadLine>
                        conditions
                    </HeadLine>
                </CardHead>

                <Content>
                    <Label>Construction Year</Label>
                    <Value>{assetCondition.event.constructionYear.year}</Value>
                </Content>

                {
                    assetCondition.event.eventMetadata?.map((event: EventMetadata, index: number) => (
                        <Content key={index}>
                            <Label>{event.title}</Label>
                            <Value>{event.date}</Value>
                        </Content>
                    ))
                }

                { /******* DESCRIPTION */}

                {
                    hasDescription &&
                    <CardHead>
                        <HeadLine>
                            description
                        </HeadLine>
                    </CardHead>
                }

                {

                    assetFeatures.map((e: AssetFeatures, index: number) => (
                        e.metadata && e.metadata.length > 0 ?
                            <Feature feature={e} key={index} />
                            : null
                    ))
                }
            </Card>
        </ArticleContainer>
    )
}

export default Features;