import tw from "twin.macro";
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';
import { Ads, FinancialMetadata, OtherFinancialMetadata } from 'graphql/__generated__/graphql-types';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2   w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2 flex flex-row
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`
const SubHeadLine = tw.h1`
text-primary-500 text-sm font-medium uppercase
`

const HoldingCostItem = (props: { title: string, metadata: FinancialMetadata[] }) => {
    const { title, metadata } = props
    return (
        <>
            <Content>
                <SubHeadLine>
                    {title}
                </SubHeadLine>
            </Content>

            {
                metadata.map((data: FinancialMetadata, index: number) => (
                    <Content key={index}>
                        <Label>{data.title}</Label>
                        <Value>
                            <NumberFormat
                                value={data.value}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={'text'}
                                thousandSeparator={' '}
                                prefix={'$ '}
                                renderText={(value: string) => <span>{value}</span>}
                            />
                        </Value>
                    </Content>
                ))
            }
        </>
    )
}

const HoldingCost = (props: { ads: Ads, id?: any, viewPortObserver?: (isVisible: boolean) => void }) => {
    const { holdingCost } = props.ads.financial;
    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Holding cost</Headline>
            <Divider />
            <Card>
                <CardHead>
                    <HeadLine>Annuel Cost</HeadLine>
                    <Value css={tw`text-lg`}>
                        <NumberFormat
                            value={holdingCost.totalCost}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </CardHead>

                {
                    holdingCost.utilities &&
                    <HoldingCostItem title="Utilities" metadata={holdingCost.utilities} />
                }

                {
                    holdingCost.maintenance &&
                    <HoldingCostItem title="Maintenance" metadata={holdingCost.maintenance} />
                }

                {
                    holdingCost.inusrance &&
                    <HoldingCostItem title="Insurance" metadata={holdingCost.inusrance} />
                }


                {
                    holdingCost.taxes &&
                    <HoldingCostItem title="Taxes" metadata={holdingCost.taxes} />
                }

                {
                    holdingCost.management &&
                    <HoldingCostItem title="Management" metadata={holdingCost.management} />
                }

                {
                    holdingCost.administrative &&
                    <HoldingCostItem title="Administrative" metadata={holdingCost.administrative} />
                }

                {
                    holdingCost.debt &&
                    <HoldingCostItem title="Loan" metadata={holdingCost.debt} />
                }

                {
                    holdingCost.otherCosts && holdingCost.otherCosts.map((cost: OtherFinancialMetadata, index: number) => (
                        <HoldingCostItem title={cost.name} metadata={cost.metadata} key={index}/>
                    ))
                }

            </Card>
        </ArticleContainer>
    )
}


export default HoldingCost;