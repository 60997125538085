import { FormControl, Typography, Box, TextField } from "@mui/material"
import { Financial, Rental } from '../../../../graphql/__generated__/graphql-types';

import Grid from "@mui/material/Unstable_Grid2/Grid2"
import InputField from "components/form-fields/input-field"
import tw from "twin.macro"
import { useField } from "formik";
import { useEffect } from "react";

const Container = tw.div`
flex p-4 flex-col
`




const RentalProfitability = (props: {
    rental?: Rental,
    financial?: Financial
} & any) => {

    const {
        rental = null,
        financial = null
    } = props

    const cashFlowField = useField('rental.profitability.cashFlow')
    const cashOnCashField = useField('rental.profitability.cashOnCash')
    const internalRateOfReturnField = useField('rental.profitability.internalRateOfReturn')

    const holdingCost = financial?.holdingCost?.totalCost || 0
    const annualGrossRent = rental?.leaseDetails?.annualGrossRent || 0

    const tatalInvestment = (rental?.investment?.totalAmount || 0) + (financial?.aquisitionCost?.totalCost || 0) + (financial?.assetValue?.totalAssetValue || 0)
    const cashFlow = annualGrossRent + holdingCost

    const assetAppreciation = rental?.profitability?.assetAppreciation || 0

    const cashOnCash = tatalInvestment === 0 ? 0 : cashFlow * 100 / tatalInvestment

    const internalRateReturn = cashOnCash + assetAppreciation
    useEffect(() => {
        cashFlowField[2].setValue(cashFlow, false)
        cashOnCashField[2].setValue(cashOnCash, false)
        internalRateOfReturnField[2].setValue(internalRateReturn, false)

    }, [rental?.profitability?.assetAppreciation])

    useEffect(()=> {
        cashFlowField[2].setValue(cashFlow, false)
    },[]);

    function drawCashFlow() {

        return (
            <Box>
                <Typography component="h1" variant="h6">
                    Cash Flow
                </Typography>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} >
                    <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Gross Rent Income
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                css={tw`bg-gray-200`}
                                value={annualGrossRent}
                                inputProps={
                                    { readOnly: true, }
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Holding Cost
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                css={tw`bg-gray-200`}
                                value={holdingCost}
                                inputProps={
                                    { readOnly: true, }
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Cash Flow
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                css={tw`bg-gray-200`}
                                value={cashFlowField[0].value}
                                inputProps={
                                    { readOnly: true, }
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    function drawCashOnCashFlow() {

        return (
            <Box sx={{ marginTop: 3 }}>
                <Typography component="h1" variant="h6">
                    Cash On Cash (CoC)
                </Typography>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} >
                    <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Cash Flow
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                css={tw`bg-gray-200`}
                                value={cashFlowField[0].value}
                                inputProps={
                                    { readOnly: true, }
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Investment
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                css={tw`bg-gray-200`}
                                value={tatalInvestment}
                                inputProps={
                                    { readOnly: true, }
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Cash On Cash
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                value={`${cashOnCash.toFixed(2)} %`}
                                css={tw`bg-gray-200`}
                                inputProps={
                                    { readOnly: true, }
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        )
    }


    function drawIRR() {
        return (
            <Box sx={{ marginTop: 3 }}>
                <Typography component="h1" variant="h6">
                    Internal Rate of Return (IRR)
                </Typography>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} >
                    <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Asset Appreciation ( 1 Year )
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <InputField
                                name="rental.profitability.assetAppreciation"
                                id="rental.profitability.assetAppreciation"
                                size="small"
                                valueType='Float'
                                suffix=' %'
                            />
                        </FormControl>
                    </Grid>

                    <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Cash-On-Cash ( CoC)
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                css={tw`bg-gray-200`}
                                value={`${cashOnCash.toFixed(2)} %`}
                                inputProps={
                                    { readOnly: true, }
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Internal Rate of Return (IRR)
                        </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                css={tw`bg-gray-200`}
                                value={`${internalRateReturn.toFixed(2)} %`}
                                inputProps={
                                    { readOnly: true, }
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Container>
            {
                drawCashFlow()
            }
            {
                drawCashOnCashFlow()
            }
            {
                drawIRR()
            }
        </Container>
    )
}

export default RentalProfitability;