import * as Yup from 'yup';
import { AdsCreationSteps } from "graphql/__generated__/graphql-types";

export const ValidationSchema = {
    [AdsCreationSteps.Initial]: null,
    //adsSetup
    [AdsCreationSteps.AdsSetup]: Yup.object().shape({
        adsSetup: Yup.object().shape({
            "title": Yup.string()
                .required('Ads title is required'),
            "redirection": Yup.object().shape({
                "params": Yup.string().test('params', 'Original Ad Link not valide URL', (value) => {
                    try {
                        if (!value) return false;
                        var urlRegex = "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?";
                        var url = new RegExp(urlRegex, 'i');
                        return value.length < 2083 && url.test(value);
                    } catch {
                        return false
                    }
                }).required('Original Ad Link is required')//.url('Original Ad Link not valide URL')

            }),
            "transactionType": Yup.array().min(1, 'Transaction Type is required'),
            "currency": Yup.string()
                .nullable()
                .required('Currency is required'),
            "language": Yup.string()
                .nullable()
                .required('Language is required'),
            "measurement": Yup.string()
                .nullable()
                .required('Unit of measurement is required'),
        })
    }),

    [AdsCreationSteps.Asset]: Yup.object().shape({
        asset: Yup.object().shape({
            "pictures": Yup.object().shape({
                "featured": Yup.string()
                    .required('Featured photo is required'),
                /*"galleries": Yup.array().of(
                    Yup.object().shape({
                        "name": Yup.string(),
                        "pictures": Yup.array()
                            .of(Yup.string())
                            .min(1, 'Photo gallery is required')
                    })
                ).min(1, 'Photo gallery is required'),*/
            }),
            "description": Yup.string()
                .required('About the Property is required'),
            "location": Yup.object().shape({
                "coordinates": Yup.object().required("Coordinates is required"),
                "address": Yup.object().shape({
                    "number": Yup.string()
                        .nullable()
                        .required('Number is required'),
                    "street": Yup.string()
                        .nullable()
                        .required('Street is required'),
                    "city": Yup.object().shape({
                        name: Yup.string()
                            .nullable()
                            .required('City is required'),
                        descriptrion: Yup.string()
                            .nullable()
                            .required('About the city is required'),
                    }),
                    "country": Yup.string()
                        .nullable()
                        .required('Country is required'),
                    "zipCode": Yup.string()
                        .nullable()
                        .required('Zip Code is required'),
                    "stateRegion": Yup.string()
                        .nullable()
                        .required('State/Region is required'),
                })
            }),
            "assetType": Yup.object().shape({
                "type": Yup.string()
                    .required('Type is required'),
                "area": Yup.string()
                    .required('Lot size is required'),
                "destination": Yup.string()
                    .required('Destination is required'),
            }),
        })

    }),

    [AdsCreationSteps.Spv]: Yup.object().shape({
        spv: Yup.object().shape({
            "name": Yup.string()
                .required('SPV name is required'),
            "registrationPlace": Yup.string()
                .required('Registration place is required'),
            "registrationNumber": Yup.string()
                .required('Registration number is required'),
            "sponsor": Yup.string()
                .required('Sponsor is required'),
            "documents": Yup.object().shape({
                'establishementCertificate': Yup.object().shape({
                    'source': Yup.string()
                        .required('Establishement certificate is required')
                }),
            })
        })
    }),
    [AdsCreationSteps.Distribution]: Yup.object().shape({
        distribution: Yup.object().shape({
            totalShares: Yup.number()
                .required('total shares is required')
                .min(1, 'total shares must be > 0'),
            sharesOnSale: Yup.number()
                .required('total shares is required')
                .min(1, 'shares on sale must be > 0'),
            hasReservedShares: Yup.boolean(),
            sharesOffSale: Yup.number().truncate().when('hasReservedShares', {
                is: true,
                then: Yup.number().required()
                    .min(1, 'shares off sale must be > 0'),
            })
        })
    }),
    [AdsCreationSteps.Restrictions]: Yup.object().shape({
        restrictions: Yup.object().shape({
            hasRestriction: Yup.boolean(),
            restrictionItems: Yup.array().ensure().when("hasRestriction", {
                is: true,
                then: Yup.array().required()
                    .min(1, "Restriction cannot be empty")
                /*then: () => {
                    return Yup.array().of(
                        Yup.object().shape({
                            quantity: Yup.number()
                            .min(1, 'restrictions quatity must > 0')
                            .required('restrictions quatity required'),
                        }),
                    )
                },*/
            })
        })
    }),
    [AdsCreationSteps.Financial]: Yup.object().shape({
        financial: Yup.object().shape({
            assetValue: Yup.object().shape({
                totalAssetValue: Yup.number().required()
                    .moreThan(0, "Total asset value must be > 0, add one or more asset Value")
            })
        })
    }),
    [AdsCreationSteps.TransactionRental]: Yup.object().shape({
        rental: Yup.object().shape({
            leaseDetails: Yup.object().shape({
                type: Yup.string()
                    .required('Type is required'),
                statut: Yup.string()
                    .required('Status is required'),
                annualGrossRent: Yup.number()
                    .required()
                    .moreThan(0, 'Annual Gross Rent must be > 0'),
            })
        })
    })
}

export type ValidationSchemaKey = keyof typeof ValidationSchema

export default ValidationSchema;