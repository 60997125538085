import { FormControl, Box, Button, Typography, TextField, Chip, IconButton } from "@mui/material"
import tw from "twin.macro"
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from "react";
import { useField } from 'formik';
import _ from 'lodash';
import JoinFile, { IDocument } from "./join-field";
import { Document } from "graphql/__generated__/graphql-types";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface IPlaceHolder {
    key: string;
    value: string
}

interface IMetaData {
    key: string;
    value: string;
    documents?: IDocument[] | Document[];
}

export interface IMetaDataInput {
    key: string;
    value: string;
    documents?: IDocument[];
}

export interface IMetaDataOutput {
    key: string;
    value: string;
    documents?: Document[];
}

interface IState {
    data: IMetaData;
    placeHolder: IPlaceHolder,
}


const MetaDataField = (props: {
    label: string,
    buttonLabel: string,
    placeHolders?: IPlaceHolder[],
    helperText?: string,
    withDocument?: boolean,
    labelStyle?: any,
    valueHidden?: boolean,
    removeEmpptyFields?: boolean,
    dataMapper: (metadata: IMetaDataInput) => any,
    dataMapperInversed: (data: any) => IMetaDataOutput,
    prefixComponent?: React.ReactNode,
    sufixComponent?: React.ReactNode,
    valueType?: "Str" | "Float" | "Int",
    valueProps: any,
    fixedKeys?: string[]
} & any) => {

    const {
        label,
        fixedKeys = [],
        valueHidden = false,
        labelStyle = {},
        removeEmpptyFields = true,
        withDocument = false,
        type,
        helperText,
        buttonLabel,
        dataMapper = (o: any) => (o),
        dataMapperInversed = (o: any) => (o),
        placeHolders = [],
        prefixComponent,
        sufixComponent,
        name,
        id,
        ...rest } = props
    const [metaData, setMetaData] = useState<IState[]>([])

    // eslint-disable-next-line
    //@ts-ignore 
    const [field, meta, helper] = useField(props);
    const { setValue } = helper;

    useEffect(() => {
        if (field.value) {
            let previousState: IState[] = []
            let newPlaceHolder: IPlaceHolder[] = [...placeHolders];

            previousState = _.reduce(field.value, (acc: IState[], data: any) => {
                const dataMapped = dataMapperInversed(data)
                let pholder = _.find(placeHolders, (o: IPlaceHolder) => (o.key.toLowerCase() === dataMapped.key.toLowerCase()));
                if (pholder) {
                    _.remove(newPlaceHolder, (o: IPlaceHolder) => (o.key.toLowerCase() === pholder.key.toLowerCase()))
                } else pholder = { key: "key", value: "value" }

                return [...acc, {
                    data: dataMapped,
                    placeHolder: pholder,
                }]
            }, [])

            previousState = [...previousState, ..._.reduce(newPlaceHolder, (acc: IState[], o: IPlaceHolder) => ([...acc, {
                data: { key: "", value: "" },
                placeHolder: o
            }]), [])]

            setMetaData(previousState)
        } else if (placeHolders) {
            setMetaData(placeHolders.reduce((acc: IState[], o: IPlaceHolder, index: number) => {
                let key = ""
                if ((fixedKeys.length > index) && (fixedKeys.includes(o.key))) key = o.key

                return [...acc, {
                    data: { key, value: "" },
                    placeHolder: o
                }]
            }, []))
        }
    }, [])

    const _handleAddLine = () => {
        setMetaData([...metaData, {
            data: { key: "", value: "" },
            placeHolder: { key: "Key", value: "Value" }
        }])
    }



    const _handleDocumentChange = (documents: IDocument[], index: number) => {
        setMetaData(metaData.map<IState>((o: IState, i: number, _array: IState[]) => {
            if (i !== index) return o;
            else return {
                data: {
                    key: o.data.key,
                    value: o.data.value,
                    documents: documents
                },
                placeHolder: o.placeHolder
            }
        }))

    }

    const _handleValueChange = (event: { target: { name: string; value: string } }) => {
        const index = parseInt(event.target.name.replace('metadata-value-', ''))
        const value = event.target.value;

        setMetaData(metaData.map<IState>((o: IState, i: number, _array: IState[]) => {
            if (i !== index) return o;
            else return {
                data: {
                    key: o.data.key,
                    value: value,
                },
                placeHolder: o.placeHolder
            };
        }))
    }

    function _drawPrefixComponent() {
        if (prefixComponent) return prefixComponent
        else return null
    }

    function _drawSufixComponent() {
        if (sufixComponent) return sufixComponent
        else return null
    }

    function _drawHelperText() {
        if (helperText)
            return <Typography variant="caption" display="block" gutterBottom>
                {helperText}
            </Typography>
        else return null
    }

    function _handleOnBlure() {
        handleDataChanged(metaData)
    }

    function handleDataChanged(metaData: IState[]) {
        const value = _.reduce(metaData, (acc: any, o: IState) => {
            if (((!_.isEmpty(o.data.key) || !_.isEmpty(o.data.value) || !_.isEmpty(o.data.documents)) && removeEmpptyFields) || (!removeEmpptyFields)) {
                return [...acc, dataMapper(o.data)]
            }
            else return acc
        }, [])

        if (!_.isEqual(value, field.value)) {
            setValue(value, false)
        }
    }

    function _drawUpladDocuments(index: number) {
        return (
            <>
                {
                    withDocument && !valueHidden &&
                    <Grid xs={0.5} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>

                        <JoinFile
                            maxFiles={1}
                            minSize={0}
                            onJoinDocument={(documents: IDocument[]) => {
                                _handleDocumentChange(documents, index)
                            }}
                            fileTypes={["doc", 'docx', 'pdf']}
                        />
                    </Grid>
                }

                {
                    withDocument && valueHidden &&
                    <Grid xs={2}>
                        <JoinFile
                            maxFiles={1}
                            minSize={0}
                            onJoinDocument={(documents: IDocument[]) => {
                                _handleDocumentChange(documents, index)
                            }}
                            fileTypes={["doc", 'docx', 'pdf']}
                        />
                    </Grid>
                }
            </>
        )
    }

    function _drawPreviewDocuments(documents: Document[]) {
        return (
            <>
                {
                    withDocument && !valueHidden &&
                    <Grid xs={0.5} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                        {
                            documents && documents.map((d: Document, index) => (
                                <Chip
                                    key={index}
                                    label={d.source}
                                    onDelete={() => { }}
                                />
                            ))
                        }
                    </Grid>
                }

                {
                    withDocument && valueHidden &&
                    <Grid xs={2}>
                        {
                            documents.map((d: Document, index) => (
                                <Chip
                                    key={index}
                                    label={d.source}
                                    onDelete={() => { }}
                                />
                            ))
                        }
                    </Grid>
                }
            </>
        )
    }
    function _drawDocuments(forItem: number, documents?: IDocument[] | Document[]) {
        if (documents && !_.isEmpty(documents)) return _drawPreviewDocuments(documents as Document[])
        else return _drawUpladDocuments(forItem)
    }

    /*
    useEffect(() => {
    
        const value = _.reduce(metaData, (acc: any, o: IState) => {
            if (((!_.isEmpty(o.data.key) || !_.isEmpty(o.data.value) || !_.isEmpty(o.data.documents)) && removeEmpptyFields) || (!removeEmpptyFields)) {
                return [...acc, dataMapper(o.data)]
            }
            else return acc
        }, [])
    
        //console.log(value)
    
        if (!_.isEqual(value, field.value))
            setValue(value, false)
    
    }, [metaData])//[metaData, dataMapper, setValue, field.value]
    */
    const _handleKeyChange = (event: { target: { name: string; value: string } }) => {
        const index = parseInt(event.target.name.replace('metadata-key-', ''))
        const key = event.target.value;
        setMetaData(metaData.map<IState>((o: IState, i: number, _array: IState[]) => {
            if (i !== index) return o;
            else return {
                data: {
                    key: key,
                    value: o.data.value,
                },
                placeHolder: o.placeHolder
            };
        }))
    }

    function _handleRemoveLine (index: number) {
        let newMetadata = _.clone(metaData);
        _.pullAt(newMetadata, index);
        setMetaData(newMetadata);
        handleDataChanged(newMetadata)
    }

    return (
        <Box {...rest}>
            <Box css={tw`flex flex-row justify-between`}>
                <Typography component="h1" variant="h6" css={tw`inline`} {...labelStyle}>
                    {label}
                </Typography>

                <Button size="small" color='secondary' variant="contained" onClick={_handleAddLine}>{buttonLabel}</Button>
            </Box>

            {
                _drawHelperText()
            }

            {
                _drawPrefixComponent()
            }

            {
                metaData.length > 0 &&
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                    {

                        metaData.map((e: IState, index: number) => (
                            <>
                                <Grid key={`key-${index}`} xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                    <FormControl fullWidth>
                                        {
                                            fixedKeys.includes(e.placeHolder.key) &&

                                            <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                                {e.placeHolder.key}
                                            </Typography>
                                        }




                                        {
                                            !fixedKeys.includes(e.placeHolder.key) &&
                                            <TextField
                                                name={`metadata-key-${index}`}
                                                placeholder={e.placeHolder.key}
                                                value={e.data.key}
                                                size="small"
                                                onChange={_handleKeyChange}
                                                onBlur={_handleOnBlure}
                                            />
                                        }

                                    </FormControl>
                                </Grid>
                                {
                                    !valueHidden &&
                                    <Grid key={`value-${index}`} xs={withDocument ? 1.40 : 1.90}>
                                        <FormControl fullWidth>

                                            <TextField
                                                name={`metadata-value-${index}`}
                                                placeholder={e.placeHolder.value}
                                                value={e.data.value}
                                                size="small"
                                                onChange={_handleValueChange}
                                                onBlur={_handleOnBlure}
                                                type={type}
                                            />


                                        </FormControl>
                                    </Grid>
                                }


                                {
                                    _drawDocuments(index, e.data.documents)
                                }

                                <Grid xs={0.10}>
                                    <IconButton sx={tw`text-red-500 float-right`} onClick={()=> _handleRemoveLine(index)}>
                                        <HighlightOffIcon />
                                    </IconButton>
                                </Grid>



                            </>
                        ))
                    }
                </Grid>
            }
            {
                _drawSufixComponent()
            }
        </Box>
    )
}


export default MetaDataField;