import tw from 'twin.macro';
import { SourcesIcon } from 'components/misc/SourcesIcon';

const Container = tw.div`
col-span-1 pr-1
`
const Heading2 = tw.h2`
font-medium text-base text-secondary-900 mr-1
`

const BriicksMakerLogo = tw.img`
w-auto h-8
`
const Maker = () => {
    const sources = ["RealTMixed", "Brickx", "BrickS", "Lofty", "Reental", 'Vesta-Equity', 'Land-Share',
        'Grapen-Invest', 'Katapulte', 'La-Premiere-Brique', 'Smart-Crowd', 'Smart-Crowd-Pakistan',
        'Smart-Crowd-Direct', 'Invest-Dubai', 'Vairt', 'Aqar-Chain'];
    const source = sources[Math.floor(Math.random() * sources.length)];

    return (
        <Container>
            <Heading2>Briick maker</Heading2>
            <BriicksMakerLogo
                src={SourcesIcon(source)}
            />
        </Container>
    )
}

export default Maker;