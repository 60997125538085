import React from 'react';
import tw from 'twin.macro';
import CardMedia from '@mui/material/CardMedia';
import { Tab, Tabs } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import VillaIcon from '@mui/icons-material/Villa';
import { TabPanel as TabPanelBase } from 'components/tabs/tab-panel';
import styled from '@emotion/styled';
import Pictures from './pictures';
import Plan from './plan';
import ArticleContainer from 'components/article/article-container';
import { Asset } from 'graphql/__generated__/graphql-types';
/*
import View360 from './view-360';
import Virtual from './virtual';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import VrpanoIcon from '@mui/icons-material/Vrpano';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import View3D from './view-3d';
*/
const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
mb-8
`

const TabPanel = styled(TabPanelBase)`
${tw`mt-1`}
`

const View = (props: { asset: Asset, id?: any, viewPortObserver?: (isVisible: boolean) => void }) => {

    const [value, setValue] = React.useState(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const { asset } = props
    //const featured = `https://ik.imagekit.io/brma/tr:w-${size * cols + c},h-${size * rows + c}/${image}`
    const featured = `https://ik.imagekit.io/brma/${asset.pictures.featured}`

    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>View</Headline>
            <Divider />
            <CardMedia
                component="img"
                image={featured}
            //alt="green iguana"
            />

            <Tabs value={value}
                onChange={handleChange} centered css={tw`mt-4`}>
                <Tab icon={<CollectionsIcon />} label='Pictures' />
                {
                    asset.plans && asset.plans.length > 0 &&
                    <Tab icon={<VillaIcon />} label='Plan' />
                }
                {
                    /*
                }
                <Tab icon={<VrpanoIcon />} label='360' />
                <Tab icon={<CameraswitchIcon />} label='Virtual' />
                <Tab icon={<ViewInArIcon />} label='3D' />
                {
                    /*/
                }
            </Tabs>

            <TabPanel index={0} value={value}>
                <Pictures galleries={asset.pictures.galleries}/>
            </TabPanel>
            {
                asset.plans && asset.plans.length > 0 &&
                <TabPanel index={1} value={value}>
                    <Plan plans={asset.plans}/>
                </TabPanel>
            }
            {
                /*
            }
            <TabPanel index={1} value={value}>
                <View360 />
            </TabPanel>
            <TabPanel index={2} value={value}>
                <Virtual />
            </TabPanel>
            <TabPanel index={3} value={value}>
                <View3D />
            </TabPanel>


            {
                */
            }

        </ArticleContainer>
    )
}

export default View