import tw from "twin.macro"
import BriicksPrice from "./briicks-price"
import { Financial, Distribution, BriicksMinMax, Rental, Flipping } from '../../../../../graphql/__generated__/graphql-types';
import BriicksMinMaxComponent from "./briicks-min-max";
import BriickMinMaxTakers from "./briicks-min-max-takers";
import * as BriicksLib from 'briicks-lib';

const Container = tw.div`
flex p-4 flex-col
`

const OfferingInformations = (props: { financial?: Financial, rental?: Rental, flipping?: Flipping, distribution: Distribution, briicksMinMax?: BriicksMinMax } & any) => {
    const {
        financial,
        distribution,
        briicksMinMax,
        rental,
        flipping,
    } = props
    const totalBriicks = distribution.sharesOnSale || 1
    const briickPrice = BriicksLib.calculateBriicksPrice(
        totalBriicks,
        financial?.aquisitionCost?.totalCost || 0,
        financial?.assetValue?.totalAssetValue || 0,
        rental?.investment?.totalAmount || 0,
        flipping?.investment?.totalAmount || 0,
        )
    const investment = (financial?.aquisitionCost?.totalCost || 0) + (financial?.assetValue?.totalAssetValue || 0) + (rental?.investment?.totalAmount || 0)
    return (
        <Container>
            <BriicksPrice price={briickPrice} investment={investment} totalBriicks={totalBriicks} />
            <BriicksMinMaxComponent price={investment / totalBriicks} totalBriicks={totalBriicks} />
            <BriickMinMaxTakers briicksMinMax={briicksMinMax} totalBriicks={totalBriicks}/>
        </Container>
    )
}

export default OfferingInformations