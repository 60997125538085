import Documents from './documents';
import Providers from './provider';
import { IFullAdsSection } from 'components/common/types';
import SectionContainer from 'components/article/section-container';
import { Ads } from 'graphql/__generated__/graphql-types';



export const elements: IFullAdsSection[] = [
    {
        name: 'Providers',
        element: Providers
    },
    {
        name: 'Documents',
        element: Documents
    },
]

const Resources = (props: {ads: Ads, setVisibleArticle: (articleName: string) => void }) => {

    return (
        <SectionContainer>
            {
                elements.map((e, index) => {
                    const V = e.element

                    return <V ads={props.ads} id={e.name} key={index} viewPortObserver={(isVisible: boolean) => {
                        if (isVisible) props.setVisibleArticle(elements[index].name)
                    }} />
                })
            }
        </SectionContainer>
    )
}

export default Resources;