import Header from "components/header"
import Footer from "components/footer"
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import JoinUsForm from "components/form/join-us"

const JoinUsPage = () => {
    return <>
        <AnimationRevealPage>
            <Header />
            <JoinUsForm content={{
                textOnLeft: true,
                heading: <>Feel free to <span tw="text-primary-500">get in touch</span><wbr /> with us.</>,
                subheading: "",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                submitButtonText: "Contact Me",
            }
            } />
            <Footer />
        </AnimationRevealPage>
    </>
}

export default JoinUsPage