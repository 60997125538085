import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';
import { Ads, BriicksRestrictionItem } from 'graphql/__generated__/graphql-types';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2  w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`

const SubHeadLine = tw.h1`
text-primary-500 text-sm font-medium uppercase
`

const Restriction = (props: { restriction: BriicksRestrictionItem } & any) => {
    const { restriction } = props;

    return (
        <>
            <Content>
                <SubHeadLine>
                    {restriction.title}
                </SubHeadLine>
            </Content>

            <Content>
                <Label>Total</Label>
                <Value>
                    <NumberFormat
                        value={restriction.quantity}
                        decimalScale={0}
                        fixedDecimalScale
                        displayType={'text'}
                        thousandSeparator={' '}
                        prefix={''}
                        renderText={(value: string) => <span>{value}</span>}
                    />
                </Value>
            </Content>

            <Content>
                    <Label>Sold</Label>
                    <Value>
                        <NumberFormat
                            value={restriction.quantity - restriction.stock}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Remaining</Label>
                    <Value>
                        <NumberFormat
                            value={restriction.stock}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>
        </>
    )
}

const Briicks = (props: { ads: Ads, id?: any, viewPortObserver?: (isVisible: boolean) => void }) => {
    const { ads } = props
    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Briicks</Headline>
            <Divider />
            <Card>
                <CardHead>
                    <HeadLine>Total Genral Briick</HeadLine>
                </CardHead>
                <Content>
                    <Label>Total</Label>
                    <Value>
                        <NumberFormat
                            value={ads.briicks.distribution.totalShares}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Part on sale</Label>
                    <Value>
                        <NumberFormat
                            value={ads.briicks.distribution.sharesOnSale}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Remaining</Label>
                    <Value>
                        <NumberFormat
                            value={ads.briicks.distribution.stock}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <CardHead>
                    <HeadLine>Purchase Volume</HeadLine>
                </CardHead>

                <Content>
                    <Label>Minimum </Label>
                    <Value>
                        <NumberFormat
                            value={ads.briicks.minBriicksPurchase}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Maximum </Label>
                    <Value>
                        <NumberFormat
                            value={ads.briicks.maxBriicksPurchase}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                {
                    ads.briicks.restrictions && ads.briicks.restrictions.hasRestriction
                    && ads.briicks.restrictions.restrictionItems && ads.briicks.restrictions.restrictionItems.length > 0
                    && (
                        <>
                            <CardHead>
                                <HeadLine>ALLOWANCE</HeadLine>
                            </CardHead>

                            {
                                ads.briicks.restrictions.restrictionItems.map((restriction: BriicksRestrictionItem, index: number) => (
                                    <Restriction restriction={restriction} key={index} />
                                ))
                            }
                        </>
                    )
                }               
            </Card>
        </ArticleContainer>
    )
}

export default Briicks;