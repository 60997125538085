import { FormControl, Typography, Box } from "@mui/material"
import AdsTransactionsTypeField from "components/form-fields/ads-transactions-type-field";
import { Languages } from '../../../../components/global/languages';
import { Currencies } from '../../../../components/global/currencies';
import InputField from "components/form-fields/input-field";
import SelectField from "components/form-fields/select-field";
import { Measurments } from "components/global/measurments";
import tw from "twin.macro"

const Container = tw.div`
flex p-4 flex-col
`

const AdsSetupForm = () => {

    return (
        <Container>
            <FormControl fullWidth>
                <Typography component="h1" variant="h6">
                    Ad Name*
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    This will be the public name of the ad
                </Typography>
                <InputField
                    name="adsSetup.title"
                    id="adsSetup.title"
                    size="small"
                />
            </FormControl>

            <Box
                component="fieldset"
                //error
                sx={{ marginTop: 3, maxWidth: 500 }}>

                <Typography component="h1" variant="h6">
                    Transaction Type*
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    Select the type of real estate transaction
                </Typography>
                <AdsTransactionsTypeField
                    name="adsSetup.transactionType"
                />
            </Box>


            <FormControl fullWidth sx={{ marginTop: 3 }}>
                <Typography component="h1" variant="h6">
                    Original Ad Link*
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    You have chosen the simple redirect of takers on your site. You can update the method
                    by clic on button "manage"
                </Typography>
                <InputField
                    id="adsSetup.redirection.params"
                    name="adsSetup.redirection.params"
                    size="small"
                />
            </FormControl>

            <Box sx={{ marginTop: 3 }}>
                <Typography component="h1" variant="h6">
                    Basic Settings*
                </Typography>

                <Typography variant="caption" display="block" gutterBottom>
                    These default settings are taken from your Maker profile. You can specify different parameters
                    that will apply to this ad only.
                </Typography>

                <SelectField
                    name="adsSetup.measurement"
                    labelId="adsSetup.measurement.label"
                    id="adsSetup.measurement"
                    label="Unit of measure"
                    data={Measurments.map(({ code, name }) => ({ label: name, value: code }))}
                    size="small"
                    sx={{ width: 300, marginTop: 2 }}
                />

                <SelectField
                    name="adsSetup.currency"
                    labelId="adsSetup.currency.label"
                    id="adsSetup.currency"
                    label="Currency"
                    data={Currencies.map(({ code, name }) => ({ label: name, value: code }))}
                    size="small"
                    sx={{ width: 300, marginTop: 2, marginLeft: 2 }}
                />

                <SelectField
                    name="adsSetup.language"
                    labelId="adsSetup.language.label"
                    id="adsSetup.language"
                    label="Language"
                    data={Languages.map(({ code, name }) => ({ label: name, value: code }))}
                    size="small"
                    sx={{ width: 300, marginTop: 2, marginLeft: 2 }}
                />

            </Box>

        </Container>
    )
}

export default AdsSetupForm