import tw from "twin.macro";
import { FormControl, TextField, Typography } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import InputField from "components/form-fields/input-field";
import SwitchField from "components/form-fields/switch-field";
import { styled } from '@mui/material/styles';
import { DistributionInput, ReservedShareInput } from '../../../../graphql/__generated__/graphql-types';
import { useField } from "formik";
import ReservedSharesField from "components/form-fields/reserved-shares-field";
import _ from "lodash"


const Container = tw.div`
flex p-4 flex-col
`

const HasReservedShares = styled(SwitchField)({
    '& .MuiFormControlLabel-labelPlacementStart': {
        marginLeft: 0,
        justifyContent: "space-between",
    }
})

function BriicksDistribution(props: { distribution?: DistributionInput }) {
    const { distribution } = props

    const sharesOnSale = useField('distribution.sharesOnSale')
    const sharesOffSale = useField('distribution.sharesOffSale')
    const totalShares = useField('distribution.totalShares')

    const setSharesOnSale = (value: number) => {
        sharesOnSale[2].setValue(value, false)
    }

    const setSharesOffSale = (value: number) => {
        sharesOffSale[2].setValue(value, false)
    }

    function _handleTotleShareChange(event: { target: { value: string } }) {
        setSharesOnSale(parseInt(event.target.value))
        setSharesOffSale(0)
    }

    function _handleSharesOnSaleChange(event: { target: { value: string } }) {
        const value = totalShares[0].value - parseInt(event.target.value)
        setSharesOffSale(value)
    }

    function _handleSharesOffSaleChange(event: { target: { value: string } }) {
        const value = totalShares[0].value - parseInt(event.target.value)
        setSharesOnSale(value)
    }

    function _handleOnAddShares(shares: ReservedShareInput[]) {
        const totalOffSale = _.reduce(shares, (acc: number, share: ReservedShareInput): number => {
            return (acc + share.shares);
        }, 0) || 0
        setSharesOffSale(totalOffSale)
        
        const value = totalShares[0].value - totalOffSale 
        setSharesOnSale(value)
       
    }

    return (
        <Container>
            <FormControl fullWidth>
                <Typography component="h1" variant="h6">
                    Created Shares
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    Enter the total amount of shares of the SPV :
                </Typography>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 5 }} sx={{ marginTop: 1 }}>
                    <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Total Shares*
                        </Typography>
                    </Grid>

                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <InputField
                                name="distribution.totalShares"
                                id="distribution.totalShares"
                                size="small"
                                valueType="Int"
                                placeHolder='10 000'
                                onChange={_handleTotleShareChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <TextField
                                value={totalShares[0].value === 0 ? "0%" : "100%"}
                                css={tw`bg-gray-200`}
                                size="small"
                                inputProps={
                                    { readOnly: true, }
                                }
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 3 }}>
                <Typography component="h1" variant="h6">
                    Shares Off Market
                </Typography>
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 5 }} sx={{ marginTop: 1 }}>
                    <Grid xs={4}>
                        <Typography component="h3" variant="h6" css={tw`text-sm`}>
                            Are there shares already reserved that are not intended for the market?
                        </Typography>
                    </Grid>
                    <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                        <HasReservedShares
                            name="distribution.hasReservedShares"
                        />
                    </Grid>

                    {
                        distribution && distribution.hasReservedShares &&
                        <>
                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                    Shares on Sale
                                </Typography>
                            </Grid>
                            <Grid xs={2}>
                                <FormControl fullWidth>
                                    <InputField
                                        name="distribution.sharesOnSale"
                                        id="distribution.sharesOnSale"
                                        size="small"
                                        valueType="Int"
                                        placeHolder='10 000'
                                        onChange={_handleSharesOnSaleChange}
                                        inputProps={
                                            {
                                                readOnly: true,
                                                style: tw`bg-gray-200`
                                            }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={2}>
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        value={`${totalShares[0].value === 0 ? 0 : ((sharesOnSale[0].value * 100) / totalShares[0].value).toFixed(0)}%`}
                                        inputProps={
                                            {
                                                readOnly: true,
                                                style: tw`bg-gray-200`
                                            }
                                        }
                                    />
                                </FormControl>
                            </Grid>

                            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                                <Typography component="h3" variant="h6" css={tw`text-sm`}>
                                    Shares off sale
                                </Typography>
                            </Grid>
                            <Grid xs={2}>
                                <FormControl fullWidth>
                                    <InputField
                                        name="distribution.sharesOffSale"
                                        id="distribution.sharesOffSale"
                                        size="small"
                                        valueType="Int"
                                        placeHolder='10 000'
                                        onChange={_handleSharesOffSaleChange}
                                        inputProps={
                                            {
                                                readOnly: true,
                                                style: tw`bg-gray-200`
                                            }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={2}>
                                <FormControl fullWidth>
                                    <TextField
                                        size="small"
                                        css={tw`bg-gray-200`}
                                        value={`${totalShares[0].value === 0 ? 0 : ((sharesOffSale[0].value * 100) / totalShares[0].value).toFixed(0)}%`}
                                        inputProps={
                                            { readOnly: true, }
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    }
                </Grid>
            </FormControl>
            {
                distribution && distribution.hasReservedShares &&
                <FormControl fullWidth sx={{ marginTop: 4 }}>
                    <ReservedSharesField
                        name="distribution.reservedShare"
                        id="distribution.reservedShare"
                        label='Reservers'
                        buttonLabel="Add new reserve"
                        maxShareValue={sharesOnSale[0].value}
                        totalShares={totalShares[0].value}
                        onChangeShares={_handleOnAddShares}
                    />
                </FormControl>
            }
        </Container>

    )
}

export default BriicksDistribution