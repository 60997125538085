import { ThemeProvider } from '@mui/material';
import SectionContainer from 'components/article/section-container';
import { IFullAdsSection } from 'components/common/types';
import { theme } from '../theme';
import Abstract from './abstract';
import Features from './features';
import Location from './location';
import View from './view';
import { Asset } from 'graphql/__generated__/graphql-types';


export const elements: IFullAdsSection[] = [
    {
        name: 'Abstract',
        element: Abstract
    },
    {
        name: 'Location',
        element: Location
    },
    {
        name: 'View',
        element: View
    },
    {
        name: 'Features',
        element: Features
    },
]

const AssetInfos = (props: { asset: Asset, setVisibleArticle: (articleName: string) => void}) => {
    return (
        <ThemeProvider theme={theme}>
            <SectionContainer>
                {
                    elements.map((e, index) => {
                        const V = e.element
                        
                        return<V asset={props.asset} id={e.name} key={index} viewPortObserver={(isVisible: boolean ) => { 
                            if(isVisible) props.setVisibleArticle(elements[index].name)
                        }}/>
                    })
                }
            </SectionContainer>
        </ThemeProvider>
    )
}

export default AssetInfos;
