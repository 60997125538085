import { ListingsActions } from "redux/reducers/listings";
import { put, takeLatest } from "redux-saga/effects";
import clientGraphQL from "graphql/client";
import { BriicksListingsDocument, BriicksListingsQuery, BriicksListingsQueryVariables } from '../../graphql/__generated__/graphql-types';
import {
    PayloadAction,
} from "@reduxjs/toolkit";

function* loadListingsAsyncRequest(action: PayloadAction<BriicksListingsQueryVariables>) {
    const args = action.payload
    try {
        const briicksListingsDocument: BriicksListingsQuery = yield clientGraphQL.request(BriicksListingsDocument, args)
        yield put(ListingsActions.loadListingsSuccess(briicksListingsDocument.briicksListings));
    } catch (error) {
        yield put(ListingsActions.loadListingsFaillure("error"));
    }
}

/* ------------- Connect Types To Sagas ------------- */
export const ListingSaga = [
    //reducer-name/reducer-function
    takeLatest('listings/loadListingsRequest', loadListingsAsyncRequest,
    ),
];