import tw from 'twin.macro';
import parse from 'html-react-parser';
import styled from 'styled-components';
import ArticleContainer from 'components/article/article-container';
import { Asset } from 'graphql/__generated__/graphql-types';
import VideoPreview from 'components/media/video-preview';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`
const Title = tw.h2`
text-secondary-200 font-medium text-xl mt-8
`
const AboutContent = styled.div`
${tw`mt-2`}
h1{
 ${tw`font-semibold py-2 mt-2 text-lg`}
}
`


const Abstract = (props: { asset: Asset, id?: any, viewPortObserver?: (isVisible: boolean) => void }) => {
    const {
        asset
    } = props;

    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} css={tw`mt-0!`}>
            <Headline>Overview</Headline>
            <Divider />
            {
                asset.video &&
                <>
                    <Title>Presentation</Title>
                    <VideoPreview
                        source={asset.video}
                    />
                </>
            }


            <Title>About the property</Title>
            <AboutContent>
                {
                    parse(asset.description, { trim: true })
                }
            </AboutContent>
        </ArticleContainer>
    )
}

export default Abstract;
