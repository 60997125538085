import { FormControl, TextField, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import InputField from "components/form-fields/input-field"
import { useField } from "formik"
//import moment, { Moment } from "moment"
import { useEffect, useState } from "react"
import tw from "twin.macro"
import { OtherDate } from '../../../../graphql/__generated__/graphql-types';
import _ from "lodash";
import moment from "moment"

const Container = tw.div`
flex p-4 flex-col
`

interface IState {
    key: string | null;
    value: string | null;
}

const OfferingCalendar = () => {

    const [metaData, setMetaData] = useState<IState[]>([
        { key: null, value: null },
        { key: null, value: null },
    ])

    const otherDatesField = useField("calendar.otherDates")
    const saleStartField = useField('calendar.salesStart')
    const advertiseField = useField('calendar.advertise')

    

    useEffect(() => {
        if (otherDatesField[0].value && !_.isEmpty(otherDatesField[0].value)) {
            //2023-01-20T06:42
            setMetaData(_.reduce(otherDatesField[0].value, (acc: IState[], o: OtherDate) => {
                const { date, title } = o
                return [...acc, {
                    key: title || null,
                    value: date ? moment(date).format("YYYY-MM-DDThh:mm") : null
                }]
            }, []))
        }
    }, [])

    useEffect(() => {
        const otherDates = _.reduce(metaData, (acc: OtherDate[], o: IState) => {
            const { key, value } = o
            return [...acc, {
                title: key,
                date: value ? moment(value).toISOString() : null
            }]
        }, [])
        otherDatesField[2].setValue(otherDates, false)
    }, [metaData])

    function _handleChangeValue(event: { target: { name: string, value: string } }) {
        console.log("changevalue")
        const index = parseInt(event.target.name.replace('calendar-date-value-', ''))
        setMetaData(metaData => ({
            ...metaData,
            [index]: {
                key: metaData[index].key,
                value: event.target.value
            }
        }))
    }

    function _handleChangeKey(event: { target: { name: string, value: string } }) {
        console.log("changeKey")
        const index = parseInt(event.target.name.replace('calendar-date-key-', ''))
        setMetaData(metaData => ({
            ...metaData,
            [index]: {
                value: metaData[index].value,
                key: event.target.value
            }
        }))
    }

    function drawMetadata(index: number, palceholder: string) {

        return (
            <>
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <FormControl fullWidth>
                        <TextField
                            name={`calendar-date-key-${index}`}
                            id={`calendar-date-key-${index}`}
                            size="small"
                            placeholder={palceholder}
                            value={metaData[index].key || ''}
                            onChange={_handleChangeKey}
                        />
                    </FormControl>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            name={`calendar-date-value-${index}`}
                            id={`calendar-date-value-${index}`}
                            size="small"
                            placeholder=""
                            type="datetime-local"
                            value={metaData[index].value || ''}
                            onChange={_handleChangeValue}
                        />
                    </FormControl>
                </Grid>
            </>
        )
    }

    return (
        <Container>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h1" variant="h6">
                        Advertise*
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputField
                            name="calendar.advertise"
                            id="calendar.advertise"
                            size="small"
                            placeholder=""
                            type="datetime-local"
                            value={advertiseField[0].value || ""}
                            disablePast
                        />
                    </FormControl>
                </Grid>

                {
                    drawMetadata(0, 'Pre-order')
                }


                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h1" variant="h6">
                        Sales Start*
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputField
                            name="calendar.salesStart"
                            id="calendar.salesStart"
                            size="small"
                            placeholder=""
                            type="datetime-local"
                            value={saleStartField[0].value || ''}
                        />
                    </FormControl>
                </Grid>

                {
                    drawMetadata(1, 'Sales End')
                }

            </Grid>

        </Container>
    )
}

export default OfferingCalendar;