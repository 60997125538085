import tw from "twin.macro"
import { Scalars, Maybe } from "graphql/__generated__/graphql-types";
import _ from 'lodash'
const Container = tw.div`
py-4
`

const Iframe = tw.iframe`
w-full h-144
`

const Video = tw.video`
w-full h-144
`


const YoutubeVideo = (props: { videoId: string }) => (
    <Container {...props}>
        <Iframe
            src={`https://www.youtube.com/embed/${props.videoId}`}
            //allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            //allowFullScreen
            title="Asset presentation"
        />
    </Container>
)

const OtherVideo = (props: { videoURL: string }) => (
    <Container {...props}>
        <Video src={props.videoURL} controls />
    </Container>
)


const VideoPreview = (props: { source?: Maybe<Scalars['String']> }) => {

    const ytrx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    const ytr = props.source?.match(ytrx);



    if (ytr && ytr.length > 1 && !_.isEmpty(ytr[1]))
        return <YoutubeVideo {...props} videoId={ytr[1]} />

    const isValidUrl = (urlString: string) => {
        var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    //const urlrx = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    //const urlr = props.source?.match(ytrx);

    if (isValidUrl(props.source || ''))
        return <OtherVideo {...props} videoURL={props.source!} />


    return null
}

export default VideoPreview