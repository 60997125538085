import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2  w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`

const SubHeadLine = tw.h1`
text-primary-500 text-sm font-medium uppercase
`

const Briicks = (props: {id? : any, viewPortObserver?: (isVisible: boolean) => void }) => {
    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Briicks</Headline>
            <Divider />
            <Card>
                <CardHead>
                    <HeadLine>Total Genral Briick</HeadLine>
                </CardHead>
                <Content>
                    <Label>Total</Label>
                    <Value>
                        <NumberFormat
                            value={10000}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Part on sale</Label>
                    <Value>
                        <NumberFormat
                            value={6000}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Remaining</Label>
                    <Value>
                        <NumberFormat
                            value={1254}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <CardHead>
                    <HeadLine>Purchase Volume</HeadLine>
                </CardHead>

                <Content>
                    <Label>Minimum </Label>
                    <Value>
                        <NumberFormat
                            value={1}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Maximum </Label>
                    <Value>
                        <NumberFormat
                            value={1000}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>


                <CardHead>
                    <HeadLine>ALLOWANCE</HeadLine>
                </CardHead>

                <Content>
                    <SubHeadLine>
                        REG. D ONLY QUALIFY US
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>Total</Label>
                    <Value>
                        <NumberFormat
                            value={1000}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Sold</Label>
                    <Value>
                        <NumberFormat
                            value={250}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Remaining</Label>
                    <Value>
                        <NumberFormat
                            value={750}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>


                <Content>
                    <SubHeadLine>
                        REG. S - NON US
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>Total</Label>
                    <Value>
                        <NumberFormat
                            value={5000}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Sold</Label>
                    <Value>
                        <NumberFormat
                            value={460}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Remaining</Label>
                    <Value>
                        <NumberFormat
                            value={4540}
                            decimalScale={0}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={''}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>
            </Card>
        </ArticleContainer>
    )
}

export default Briicks;