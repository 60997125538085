import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    FormHelperText
} from '@mui/material';

function SelectField(props: any) {

    const { label, data, labelId, ...rest } = props;
    const [field, meta, helper] = useField(props);
    const { value: selectedValue } = field;
    const { setValue } = helper;

    //const [touched, error] = at(meta, 'touched', 'error');
    const [error] = at(meta, 'error');
    //const isError = touched && error && true;
    const isError = error && true;

    

    function _renderHelperText(): any {
        if (isError) {
            return <FormHelperText>{error}</FormHelperText>;
        } else return null
    }
    function _handleChange(event: { target: { value: string } }) {
        setValue(event.target.value, false)
    }

    return (
        <FormControl {...rest} error={isError}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select 
            label={label} 
            onChange={_handleChange}  
            //{...field} 
            value={selectedValue ? selectedValue : ''}>
                {data.map((item: any, index: any) => (
                    <MenuItem key={index} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {_renderHelperText()}
        </FormControl>
    );
}

SelectField.defaultProps = {
    data: []
};

SelectField.propTypes = {
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    sx: PropTypes.any,
    id: PropTypes.any,
    labelId: PropTypes.any,
    label: PropTypes.any,
    fullWidth: PropTypes.bool,
};

export default SelectField;
