import tw from "twin.macro";
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2   w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2 flex flex-row
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`
const SubHeadLine = tw.h1`
text-primary-500 text-sm font-medium uppercase
`

const Debt = (props: {id? : any, viewPortObserver?: (isVisible: boolean) => void }) => {
    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Debt and warranty</Headline>
            <Divider />

            <Card>
                <CardHead>
                    <HeadLine>Debt</HeadLine>
                    <Value css={tw`text-lg`}>
                        <NumberFormat
                            value={0}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </CardHead>

                <Content>
                    <SubHeadLine>
                    Loan
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>BankBLue</Label>
                    <Value>
                        <NumberFormat
                            value={100000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>
            </Card>



            <Card>
                <CardHead>
                    <HeadLine>warranty</HeadLine>
                    <Value css={tw`text-lg`}>
                        <NumberFormat
                            value={0}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </CardHead>

                <Content>
                    <SubHeadLine>
                    Mortgage
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>BankBLue</Label>
                    <Value>
                        <NumberFormat
                            value={100000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <SubHeadLine>
                    Lien
                    </SubHeadLine>
                </Content>

                <Content>
                    <Label>Taxe Local - 1</Label>
                    <Value>
                        <NumberFormat
                            value={10000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>
            </Card>
        </ArticleContainer>
    )
}

export default Debt;