import { FormControl, TextField, Typography } from "@mui/material"
import MetaDataField from "components/form-fields/metadata-field__"
import { IMetaDataInput, IMetaDataOutput } from '../../../../components/form-fields/metadata-field__/index';
import { FinancialMetadataInput, DocumentInput, Financial, Flipping } from '../../../../graphql/__generated__/graphql-types';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import InputField from "components/form-fields/input-field";
import { useEffect } from "react";
import { useField } from "formik";
import _ from 'lodash'
import tw from "twin.macro"
import MetaDataFieldNew from 'components/form-fields/metadata-field-new';

const Container = tw.div`
flex p-4 flex-col
`

const FlippingTransaction = (props: { flipping: Flipping, financial?: Financial } & any) => {
    const {
        financial,
        flipping
    } = props

    const investmentTotalAmountField = useField('flipping.investment.totalAmount')
    const incomeTotalAmountField = useField('flipping.income.totalAmount')
    const operationDurationtotalDurationField = useField('flipping.operationDuration.totalDuration')

    useEffect(() => {
        let totalAmount = 0
        if (flipping?.investment?.metadata) {
            totalAmount = _.reduce(flipping.investment.metadata, (acc: number, o: FinancialMetadataInput) => (acc + o.value), 0)
        }
        investmentTotalAmountField[2].setValue(totalAmount, false)
    }, [flipping?.investment?.metadata])

    useEffect(() => {
        let totalAmount = 0
        if (flipping?.income?.metadata) {
            totalAmount = _.reduce(flipping.income.metadata, (acc: number, o: FinancialMetadataInput) => (acc + o.value), 0)
        }
        incomeTotalAmountField[2].setValue(totalAmount, false)
    }, [flipping?.income?.metadata])

    useEffect(() => {
        let totalDuration = 0
        if (flipping?.operationDuration?.metadata) {
            totalDuration = _.reduce(flipping.operationDuration.metadata, (acc: number, o: FinancialMetadataInput) => (acc + o.value), 0)
        }
        operationDurationtotalDurationField[2].setValue(totalDuration, false)
    }, [flipping?.operationDuration?.metadata])

    const HoldingCost = ((financial?.holdingCost?.totalCost || 0) / 12) * (flipping?.investment?.holdingDuration || 0)

    function drawInvestmentPrefixe() {
        return (
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Asset Value
                    </Typography>
                </Grid>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={financial?.assetValue?.totalAssetValue || 0}
                            size="small"
                            css={tw`bg-gray-200`}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Acquisition Cost
                    </Typography>
                </Grid>

                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={financial?.aquisitionCost?.totalCost || 0}
                            css={tw`bg-gray-200`}
                            size="small"
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>

                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Holding Cost Period (in month)*
                    </Typography>
                </Grid>

                <Grid xs={1}>
                    <FormControl fullWidth>
                        <InputField
                            name='flipping.investment.holdingDuration'
                            id='flipping.investment.holdingDuration'
                            size="small"
                            valueType="Int"
                        />
                    </FormControl>
                </Grid>

                <Grid xs={1}>
                    <FormControl fullWidth>
                        <TextField
                            //value={financial?.holdingCost?.totalCost || 0}
                            value={HoldingCost.toFixed(2)}
                            css={tw`bg-gray-200`}
                            size="small"
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
    function drawInvestement() {

        return (
            <MetaDataFieldNew
                name="flipping.investment.metadata"
                id="flipping.investment.metadata"
                label="Investment"
                buttonLabel="Add line"
                type="number"
                withDocument
                prefixComponent={
                    drawInvestmentPrefixe()
                }
                dataMapperInversed={(metadata: FinancialMetadataInput): IMetaDataOutput => {
                    const { title, value, document } = metadata
                    return { key: title, value: `${value}`, documents: !!document ? [document] : [] }
                }}
                dataMapper={(metadata: IMetaDataInput): FinancialMetadataInput => {

                    let document: DocumentInput | null = null

                    if (metadata.documents && metadata.documents.length > 0) {
                        const source = metadata.documents[0].remoteFile.filePath
                        const mimeType = metadata.documents[0].localeFile.type
                        document = {
                            source,
                            mimeType
                        }
                    }
                    return {
                        title: metadata.key,
                        value: parseFloat(metadata.value),
                        document
                    }

                }}
                suggestedFields={[
                    { key: "Sale Costs", value: "100 000" },
                    { key: "Rehabilitation", value: "100 000" },
                ]}
            />
        )
    }
    return (
        <Container>
            <FormControl fullWidth>
                {drawInvestement()}
            </FormControl>
            <FormControl fullWidth sx={{ paddingTop: 3 }}>
                <MetaDataFieldNew
                    name="flipping.income.metadata"
                    id="flipping.income.metadata"
                    label="Income"
                    buttonLabel="Add line"
                    type="number"
                    withDocument
                    dataMapperInversed={(metadata: FinancialMetadataInput): IMetaDataOutput => {
                        const { title, value, document } = metadata
                        return { key: title, value: `${value}`, documents: !!document ? [document] : [] }
                    }}
                    dataMapper={(metadata: IMetaDataInput): FinancialMetadataInput => {

                        let document: DocumentInput | null = null

                        if (metadata.documents && metadata.documents.length > 0) {
                            const source = metadata.documents[0].remoteFile.filePath
                            const mimeType = metadata.documents[0].localeFile.type
                            document = {
                                source,
                                mimeType
                            }
                        }
                        return {
                            title: metadata.key,
                            value: parseFloat(metadata.value),
                            document
                        }

                    }}
                    suggestedFields={[
                        { key: "Resale Price", value: "100 000" },
                        { key: "Rental", value: "1 000" },
                    ]}
                />
            </FormControl>
            <FormControl fullWidth sx={{ paddingTop: 3 }}>
                <MetaDataFieldNew
                    name="flipping.operationDuration.metadata"
                    id="flipping.operationDuration.metadata"
                    label="Operation times"
                    buttonLabel="Add line"
                    type="number"
                    withDocument
                    dataMapperInversed={(metadata: FinancialMetadataInput): IMetaDataOutput => {
                        const { title, value, document } = metadata
                        return { key: title, value: `${value}`, documents: !!document ? [document] : [] }
                    }}
                    dataMapper={(metadata: IMetaDataInput): FinancialMetadataInput => {

                        let document: DocumentInput | null = null

                        if (metadata.documents && metadata.documents.length > 0) {
                            const source = metadata.documents[0].remoteFile.filePath
                            const mimeType = metadata.documents[0].localeFile.type
                            document = {
                                source,
                                mimeType
                            }
                        }
                        return {
                            title: metadata.key,
                            value: parseInt(metadata.value),
                            document
                        }

                    }}
                    suggestedFields={[
                        { key: "Works Duration", value: "6" },
                        { key: "Time to Sell", value: "3" },
                        { key: "", value: "" },
                    ]}
                />
            </FormControl>
        </Container>
    )
}

export default FlippingTransaction