import { Box, Typography, Button, Card, CardContent, TextField, FormControl, CardHeader, IconButton } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import BriicksAddNewReserver from "components/modale/briicks-add-new-reserver"
import { useState } from "react"
import tw from "twin.macro"
import { useField } from 'formik';
import { ReservedShareInput } from "graphql/__generated__/graphql-types"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _ from 'lodash'
import InputField from "./input-field"

const ReservedSharesField = (props: {
    maxShareValue: number,
    totalShares: number,
    label?: string,
    buttonLabel?: string,
    labelStyle?: any,
    onChangeShares?: (sahres: ReservedShareInput[]) => void
} & any) => {

    const {
        maxShareValue,
        label = "Shares",
        buttonLabel = 'Add new share',
        labelStyle = {},
        ...rest } = props

    const [openModalAddNewReserver, setOpenModalAddNewReserver] = useState(false);
    const sharesField = useField(props);
    const { setValue } = sharesField[2];

    function _handleOnAddNewReserver(share: ReservedShareInput) {
        let shares = [share]
        if (sharesField[0].value) shares = [...sharesField[0].value, ...shares]

        setValue(shares, false)

        if (props.onChangeShares) props.onChangeShares(shares)
    }

    function calculePerentage(value: number): string {
        return `${(value * 100 / props.totalShares).toFixed(0)}%`
    }

    function removeReserver(index: number) {
        const oldShares = sharesField[0].value as ReservedShareInput[];
        const item = oldShares[index]
        const newShares = _.remove(oldShares, (e) => e !== item)
        setValue(newShares, false)
        if (props.onChangeShares) props.onChangeShares(newShares)
    }

    function onChangeShares(e: { target: { value: string, id: string } }) {
        const oldShares = sharesField[0].value as ReservedShareInput[];
        const index = parseInt(e.target.id)
        oldShares[index].shares = parseInt(e.target.value || '0');
        if (props.onChangeShares) props.onChangeShares(oldShares)
    }

    return (
        <>
            <Box {...rest}>
                <Box css={tw`flex flex-row justify-between`}>
                    <Typography component="h1" variant="h6" css={tw`inline`} {...labelStyle}>
                        {label}
                    </Typography>

                    <Button size="small" color='secondary' variant="contained" onClick={() => { setOpenModalAddNewReserver(true) }}>{buttonLabel}</Button>
                </Box>
                {
                    sharesField[0].value &&
                    <Grid container spacing={{ xs: 2 }} columns={{ xs: 1 }} sx={{ marginTop: 1 }}>
                        {
                            sharesField[0].value.map((share: ReservedShareInput, index: number) => (
                                <Grid xs={1} key={index}>
                                    <Card variant="outlined" >
                                        <IconButton sx={tw`text-red-500 float-right`} onClick={() => removeReserver(index)}>
                                            <HighlightOffIcon />
                                        </IconButton>
                                        <CardContent sx={tw`mt-4`}>
                                            <Grid container spacing={{ xs: 2 }} columns={{ xs: 5 }} >
                                                <Grid xs={1}>
                                                    <Typography component="h3" variant="h6" css={tw`text-base`}>Reserver</Typography>
                                                </Grid>
                                                <Grid xs={2}>
                                                    <FormControl fullWidth>
                                                        <InputField

                                                            name={`${sharesField[0].name}[${index}].name`}
                                                            autoComplete="off"
                                                            size="small"
                                                        />
                                                        {
                                                            /*
                                                           <TextField
                                                            css={tw`bg-gray-200`}
                                                            size="small"
                                                            value={share.name}
                                                        inputProps={
                                                            { readOnly: true, }
                                                        }
                                                        /> 
                                                        */
                                                        }

                                                    </FormControl>
                                                </Grid>
                                                <Grid xs={1}>
                                                    <FormControl fullWidth>
                                                        <InputField
                                                            id={index}
                                                            key={index}
                                                            name={`${sharesField[0].name}[${index}].shares`}
                                                            autoComplete="off"
                                                            size="small"
                                                            onChange={onChangeShares}
                                                        />
                                                        {
                                                            /*
                                                        <TextField
                                                            css={tw`bg-gray-200`}
                                                            size="small"
                                                            value={share.shares}
                                                        inputProps={
                                                            { readOnly: true, }
                                                        }
                                                        />
                                                        */
                                                        }
                                                    </FormControl>
                                                </Grid>
                                                <Grid xs={1}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            css={tw`bg-gray-200`}
                                                            size="small"
                                                            value={calculePerentage(share.shares)}
                                                            inputProps={
                                                                { readOnly: true, }
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid xs={1}>
                                                    <Typography component="h3" variant="h6" css={tw`text-base`}>As</Typography>
                                                </Grid>
                                                <Grid xs={2}>
                                                    <FormControl fullWidth>
                                                        <InputField
                                                            name={`${sharesField[0].name}[${index}].as`}
                                                            autoComplete="off"
                                                            size="small"
                                                        />
                                                        {/*<TextField
                                                            css={tw`bg-gray-200`}
                                                            size="small"
                                                            value={share.as}
                                                            inputProps={
                                                                { readOnly: true, }
                                                            }
                                                        />*/}
                                                    </FormControl>
                                                </Grid>
                                                <Grid xs={2} />
                                                <Grid xs={1}>
                                                    <Typography component="h3" variant="h6" css={tw`text-base`}>Note</Typography>
                                                </Grid>
                                                <Grid xs={4}>
                                                    <FormControl fullWidth>
                                                        <InputField
                                                            name={`${sharesField[0].name}[${index}].description`}
                                                            autoComplete="off"
                                                            size="small"
                                                            multiline
                                                            rows={5}
                                                        />
                                                        {/*<TextField
                                                            css={tw`bg-gray-200`}
                                                            size="small"
                                                            multiline
                                                            value={share.description || ''}
                                                            rows={5}
                                                            inputProps={
                                                                { readOnly: true, }
                                                            }
                                                        />*/}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                }
            </Box >
            <BriicksAddNewReserver
                onClose={() => { setOpenModalAddNewReserver(false) }}
                open={openModalAddNewReserver}
                onAddNewReserver={_handleOnAddNewReserver}
                maxShareValue={maxShareValue}
            />
        </>
    )
}

export default ReservedSharesField