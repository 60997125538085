import { at } from 'lodash';
import { useField } from 'formik';
import {
    Switch,
    FormControl,
    FormControlLabel,
    FormHelperText
} from "@mui/material";

export default function SwitchField(props: any) {
    const { label, labelPlacement, ...rest } = props;
    const [field, meta, helper] = useField(props);
    const { setValue } = helper;

    function _renderHelperText(): any {
        const [touched, error] = at(meta, 'touched', 'error');
        if (touched && error) {
            return <FormHelperText>{error}</FormHelperText>;
        } else
            return null
    }

    function _onChange(e: any) {
        setValue(e.target.checked, false);
    }

    return (
        <FormControl {...rest}>
            <FormControlLabel               
                control={<Switch {...field} onChange={_onChange} checked={field.value} />}
                label={label}
                labelPlacement={labelPlacement || 'end'}
            />
            {
                _renderHelperText()
            }
        </FormControl>
    );
}
