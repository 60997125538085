import { useState, useEffect } from "react";
import { DialogTitle, Typography, FormControl, IconButton, Dialog, Paper, DialogContent, DialogActions, Button, PaperProps } from "@mui/material"
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitleProps } from './types'
import { Form, Formik } from "formik";
import tw from "twin.macro";
import InputField from "components/form-fields/input-field";
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    city: Yup.object().shape({
        name: Yup.string()
            .required('City name is required'),
        description: Yup.string()
            .required('City description is required'),
    })
})

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-city-creator"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} sx={{
                //top: '-25%'
            }} />
        </Draggable>
    );
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{ cursor: 'move' }} >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


const CityDescriptionCreator = (props: { open: boolean, onClose: () => void, onCreateCity: (city: string) => void }) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        props.onClose()
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    function _handleSubmit(values: any) {
        handleClose()
        props.onCreateCity(values.city.description)
    }
    return (
        <Dialog
            open={open}
            fullWidth={false}
            maxWidth='sm'

            onClose={() => { }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-city-creator"

        >
            <Formik
                initialValues={{
                    city: {
                        name: '',
                        description: ''
                    }
                }}
                validationSchema={validationSchema}
                onSubmit={_handleSubmit}
            >
                {
                    () => (
                        <Form css={tw`w-128`}>
                            <BootstrapDialogTitle id="draggable-dialog-city-creator" onClose={handleClose}>
                                Create New City Description
                            </BootstrapDialogTitle>
                            <DialogContent>
                                <FormControl fullWidth>
                                    <Typography component="h1" variant="h6">
                                        City name*
                                    </Typography>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        This will be the name
                                    </Typography>
                                    <InputField
                                        name="city.name"
                                        id="city.name"
                                        size="small"
                                    />
                                </FormControl>

                                <FormControl fullWidth>

                                    <Typography component="h1" variant="h6" css={tw`inline`}>
                                        Description*
                                    </Typography>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        the description of the city
                                    </Typography>

                                    <InputField
                                        name="city.description"
                                        id="city.description"
                                        size="small"
                                        multiline
                                        rows={10}
                                    />
                                </FormControl>

                            </DialogContent>
                            <DialogActions>
                                <Button autoFocus onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button type="submit" onClick={() => { }}>Save</Button>
                            </DialogActions>
                        </Form>
                    )
                }
            </Formik>
        </Dialog >
    )
}

export default CityDescriptionCreator