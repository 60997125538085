import { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
//import tailwind from 'tailwind.config'
import NumberFormat from 'react-number-format';
import {
    Menu as MenuBase,
    MenuItem,
} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import { ReactComponent as FacebookIcon } from 'images/facebook-icon.svg'
import { ReactComponent as TwitterIcon } from 'images/twitter-icon.svg'
import { ReactComponent as TelegramIcon } from 'images/telegram-icon.svg'
import { ReactComponent as LinkedInIcon } from 'images/linkedin-icon.svg'
import { ReactComponent as ChevronLeftIcon } from 'feather-icons/dist/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton
} from 'react-share'
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons'
import { SecondaryButton as SecondaryButtonBase } from 'components/misc/Buttons'
import { SliderBase as Slider } from 'components/misc/React-Slick'
import { BriicksListing, Listing_Badge } from '../../graphql/__generated__/graphql-types';
import { SourcesIcon } from 'components/misc/SourcesIcon';
import _ from 'lodash'
import NoImagePlaceHolder from 'images/no_picture.png'
import { LazyBackground } from 'components/misc/LazyBackground';
import Modal from 'react-modal';

//const Card = tw.div`w-full h-auto border-gray-400 rounded-lg border`
const Card = tw.div`w-full max-w-96 h-auto border-gray-400 rounded-lg border`
const ImageContainer = styled.div`
${tw`w-full h-56 lg:h-64 border-gray-400 border-b relative`}
`
//${tw`w-full h-56 lg:h-96 border-gray-400 border-b relative`}

/*const Image = styled.img`
height:100%;
    min-width: 100%;
    min-height:24rem;
    object-fit: cover;
    object-position: center;
`*/
const ImageSlide = styled.div<{ imgSrc: string }>(({ imgSrc }) => [
    `background-image: url("${imgSrc}");`,
    tw`min-w-full min-h-56 lg:min-h-64 bg-cover bg-center rounded-tl-lg rounded-tr-lg border-b`
])
//tw`min-w-full min-h-56 lg:min-h-96 bg-cover bg-center rounded-tl-lg rounded-tr-lg border-b`


/*const Image = tw.img`
w-full h-full object-cover rounded-tl-lg rounded-tr-lg
`*/

const StatusBadge = styled.span<{ status: Listing_Badge }>(({ status }) => [
    status === Listing_Badge.NewLaunch && tw`bg-green1-500`,
    status === Listing_Badge.AlmostDone && tw`bg-orange1-500`,
    status === Listing_Badge.SoldOut && tw` bg-red1-500`,
    status === Listing_Badge.ComingSoon && tw` bg-blue-500`,
    status === Listing_Badge.Open && tw`bg-teal-500`,
    status === Listing_Badge.LastChance && tw` bg-yellow-500`,
    status === Listing_Badge.NotFunded && tw` bg-secondary-100`,

    tw`absolute w-40 rounded-md text-center mx-2 my-2 px-2 py-2 text-white uppercase top-0 left-0`
])

const SpecialBadge = tw.span`
bg-gray-300 text-gray-700
absolute w-40 rounded-md text-center mx-2 my-2 px-2 py-2 
uppercase bottom-0 left-0
`

const DetailsContainer = tw.div`w-full`//py-1 px-2 sm:px-4 sm:py-1 


const Table = tw.table`
w-full text-gray-600 
`
/*const TableWithSpacedBorder = styled(Table)`
${tw`border-separate`}
border-spacing: 0 ${tailwind.theme.spacing[1]};
@media (min-width: ${tailwind.theme.screens.sm}) { 
    border-spacing: 0 ${tailwind.theme.spacing[2]};
}
tr:first-child td , tr:first-child th{
    ${tw`pt-2 sm:pt-4`}
}
`*/

const TableWithSpacedBorder = styled(Table)`
${tw`border-separate`}
border-spacing: 0 0.25rem;
@media (min-width: 640px) { 
    border-spacing: 0 0.5rem;
}
tr:first-child td , tr:first-child th{
    ${tw`pt-2 sm:pt-4`}
}
`

const TableRow = styled.tr`
`

const TableHeader = styled.th`
${tw`text-left uppercase px-2 sm:px-4 whitespace-nowrap`}
${tw`font-bold text-base`}
`

const TableDataCell = styled.td`
${tw`text-right w-full px-2 sm:px-4`}
${tw`font-semibold text-base`}
`


const AddressBase = styled.span`
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1; // number of lines to show //
line-clamp: 1; 
-webkit-box-orient: vertical;
`
const County = styled(AddressBase)``
const Address = styled(AddressBase)`
${tw`capitalize`}
`

const Briicks = tw.span`
inline-flex items-center h-12 border-gray-400 border-r pr-2 sm:pr-4
text-lg text-primary-500
`

const BriicksMakerLogo = tw.img`
  w-auto h-6 float-right
`

const CardFooter = tw.div`
flex w-full justify-center p-2 sm:p-4
`

const PrimaryButton = styled(PrimaryButtonBase)`
${tw`sm:text-lg rounded py-1 sm:py-2 w-32 ml-4 sm:ml-6 text-center`}
`

const SecondaryButton = styled(SecondaryButtonBase)`
${tw`sm:text-lg rounded py-1 sm:py-2 w-32 mr-4 sm:mr-6`}
`

const Controls = tw.div`absolute flex items-center justify-center bottom-0 right-0 p-4 bg-white rounded-tl-3xl`
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`sm:mt-0 first:ml-0 ml-6 rounded-full p-0 text-lg`}
  svg {
    ${tw`w-6 h-6`}
  }
`
const PrevButton = tw(ControlButton)``
const NextButton = tw(ControlButton)``

const CustomStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        'alignItems': 'center',
    },
};

const ModalContent = tw.div`
bg-white rounded-md p-4
`

const ModalTitle = tw.h1`
text-center mb-4
`

const ModaFooter = tw.div`
flex flex-row
`

const StyledModal = styled(Modal)`
  position: relative;
  overflow: auto;
  outline: none;
  margin: auto;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  minWidth: '50rem',
  maxWidth: '100rem'

`;


const ListingCard = (props: {
    listing: BriicksListing
}) => {

    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }

    //@ts-ignore
    const [sliderRef, setSliderRef] = useState<Slider | null>(null)
    //<Image src='https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80' />

    const thumbnails = _.isEmpty(props.listing.images) ? [NoImagePlaceHolder] : props.listing.images;
    const typeAllUrl = props.listing.listingURL.length === 1 ? props.listing.listingURL[0].url : '#'

    const displayModal = props.listing.source === 'Reental' || (props.listing.source === 'RealTMixed' && props.listing.listingURL.length === 2)
                    

    const ReentalModal = <StyledModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={CustomStyles}
        contentLabel="">
        <ModalContent>
            <ModalTitle>
                {`Offer with id: ${props.listing.tokenName}`}
            </ModalTitle>
            <ModaFooter>
                <SecondaryButton onClick={() => { closeModal() }}>Cancel</SecondaryButton>
                <PrimaryButton onClick={() => {
                    closeModal()
                    window.open(typeAllUrl)
                }}>Visit</PrimaryButton>
            </ModaFooter>
        </ModalContent>
    </StyledModal>

    let RealTMixedModal = null
    if ( props.listing.source === 'RealTMixed' && props.listing.listingURL.length > 1) {
        
        const links = _.reduce(props.listing.listingURL, ( acc, o) => {
            if (o.type === 'non-us') acc.nonUS = o.url
            else if (o.type === 'us-only') acc.USonly = o.url
            return acc;
        },
        {
            nonUS: '#',
            USonly: '#',
        });

        RealTMixedModal = <StyledModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={CustomStyles}
        contentLabel="">
        <ModalContent>
            <ModalTitle>
                Are you US citizen?
            </ModalTitle>
            <ModaFooter>
                <PrimaryButton onClick={() => { 
                    closeModal() 
                    window.open(links.USonly)
                }}>US&nbsp;only</PrimaryButton>
                <PrimaryButton onClick={() => {
                    closeModal()
                    window.open(links.nonUS)
                }}>Non&nbsp;US</PrimaryButton>
            </ModaFooter>
        </ModalContent>
    </StyledModal>
    }

    return (
        <Card>
            {
                props.listing.source === 'Reental' && ReentalModal
            }
            {
              RealTMixedModal  
            }
            <ImageContainer>
                <Slider arrows={false} ref={setSliderRef}>
                    {thumbnails.map((src, index) => {
                        return src && <LazyBackground key={`${props.listing.listingId}-${index}`} src={`${src}?tr=h-658,w-512,q-60`}>
                            <ImageSlide key={`${props.listing.listingId}-${index}`} imgSrc={`${src}?tr=h-658,w-512,q-60`} />
                        </LazyBackground>
                        /*return <div key={index}>
                                   <Image key={index} src={src} alt="" />
                            
                            
                        </div>*/
                    })}
                </Slider>
                {
                    props.listing.specialBadge && <SpecialBadge>{props.listing.specialBadge || ""}</SpecialBadge>
                }

                <StatusBadge status={props.listing.badge}>{props.listing.badge.replace("_", " ")}</StatusBadge>

                {

                    props.listing.tokensRemaining >= 0 && props.listing.salesProgress >= 0 &&
                    <Progress value={props.listing.salesProgress} status={props.listing.badge} />

                }

                <Controls>
                    <PrevButton onClick={sliderRef?.slickPrev}>
                        <ChevronLeftIcon />
                    </PrevButton>
                    <NextButton onClick={sliderRef?.slickNext}>
                        <ChevronRightIcon />
                    </NextButton>
                </Controls>
            </ImageContainer>
            <DetailsContainer>
                <Table css={tw`border-gray-400 border-b`}>
                    <TableRow>
                        <TableHeader rowSpan={3} css={tw`pr-0`}>
                            <Briicks>
                                Briicks
                            </Briicks>
                        </TableHeader>
                        <TableHeader>Price</TableHeader>
                        <TableDataCell css={tw`text-primary-500`}>

                            <NumberFormat
                                value={props.listing.tokenPrice.value}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$ '}
                                renderText={(value: string) => <span>{value}</span>}
                            />

                        </TableDataCell>
                    </TableRow>
                    <TableRow>
                        <TableHeader>total</TableHeader>
                        <TableDataCell css={tw`text-primary-500`}>
                            <NumberFormat
                                value={props.listing.totalToken.toFixed(0)}
                                displayType={'text'}
                                thousandSeparator={true}
                                renderText={(value: string) => <span>{value}</span>}
                            />
                        </TableDataCell>
                    </TableRow>
                    <TableRow>
                        <TableHeader>remaining</TableHeader>
                        <TableDataCell css={tw`text-primary-500`}>
                            {
                                props.listing.tokensRemaining < 0 ? "N/A" :
                                    <NumberFormat
                                        value={props.listing.tokensRemaining}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        renderText={(value: string) => <span>{value}</span>}
                                    />
                            }
                        </TableDataCell>
                    </TableRow>
                </Table>

                <TableWithSpacedBorder>
                    <TableRow>
                        <TableHeader css={tw`text-blue-500`}>Expected income</TableHeader>
                        <TableDataCell css={tw`text-blue-500`}>
                            {
                                props.listing.expectedIncome ?
                                    <NumberFormat
                                        value={props.listing.expectedIncome}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        suffix='%'
                                        thousandSeparator={true}
                                        renderText={(value: string) => <span>{value}</span>}
                                    /> : 'N/A'
                            }

                        </TableDataCell>
                    </TableRow>
                    <TableRow>
                        <TableHeader css={tw`font-normal align-top`}>address</TableHeader>
                        <TableDataCell>
                            <Address>
                                {props.listing.address}
                            </Address>
                            <Address>
                                {props.listing.state}
                            </Address>
                            <County>
                                {props.listing.country}
                            </County>
                        </TableDataCell>
                    </TableRow>
                    <TableRow>
                        <TableHeader css={tw`font-normal`}>briick maker</TableHeader>
                        <TableDataCell>
                            <BriicksMakerLogo
                                src={SourcesIcon(props.listing.source)}
                            //src={"props.listing.makerIconSrc"}
                            />
                        </TableDataCell>
                    </TableRow>
                </TableWithSpacedBorder>
            </DetailsContainer>
            <CardFooter>
                <SocialShare linkToShare='' textToshare='' />
                {
                    displayModal ?
                        <PrimaryButton onClick={() => {
                            openModal()
                        }}>Visit</PrimaryButton> :
                        < PrimaryButton as="a" href={typeAllUrl} target="_blank">Visit</PrimaryButton>
                }
            </CardFooter>
        </Card >
    )
}

export default ListingCard


//https://github.com/nygardk/react-share/issues/416
const Menu = styled(MenuBase)`
  .share-menu {
    ${tw`bg-gray-100 min-w-0`}
  }
  .social-link {
    ${tw`bg-gray-100`}
  }
  button {
    ${tw`m-0 p-0`}
    span {
      ${tw`inline-block cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700! transition duration-300`}
      svg {
        ${tw`w-4 h-4`}
      }
    }
  }
`
const SocialLink = styled.span`
  ${tw`cursor-pointer p-1 rounded-full bg-gray-900! text-gray-100 hover:bg-gray-700 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`

const SocialShare = (props: {
    textToshare: string;
    linkToShare: string;
}) => {
    const SocialMedia = [
        {
            name: 'Facebook',
            component: () => (
                <FacebookShareButton url={props.linkToShare} quote={props.textToshare}>
                    <SocialLink>
                        <FacebookIcon />
                    </SocialLink>
                </FacebookShareButton>
            )
        },
        {
            name: 'Twitter',
            component: () => (
                <TwitterShareButton url={props.linkToShare} title={props.textToshare}>
                    <SocialLink>
                        <TwitterIcon />
                    </SocialLink>
                </TwitterShareButton>
            )
        },
        {
            name: 'Telegram',
            component: () => (
                <TelegramShareButton url={props.linkToShare} title={props.textToshare}>
                    <SocialLink>
                        <TelegramIcon />
                    </SocialLink>
                </TelegramShareButton>
            )
        },
        {
            name: 'Linkedin',
            component: () => (
                <LinkedinShareButton url={props.linkToShare} >
                    <SocialLink>
                        <LinkedInIcon />
                    </SocialLink>
                </LinkedinShareButton>
            )
        }
    ]

    return (
        <Menu
            menuClassName='share-menu'
            menuButton={<SecondaryButton>Share</SecondaryButton>}
            direction='right'
            align='end'
            position='auto'
            viewScroll='auto'
            arrow={true}
            offsetX={0}
            offsetY={0}
        >
            {SocialMedia.map(({ name, component }) => (
                <MenuItem
                    className='social-link'
                    key={name}
                >
                    {component()}
                </MenuItem>
            ))}
        </Menu>
    )
}

const Progress = (props: { value: number, status: string }) => {

    let color = "#22c55e"
    if (props.status === Listing_Badge.NewLaunch) color = "#22c55e"
    else if (props.status === Listing_Badge.AlmostDone) color = "#f97316"
    else if (props.status === Listing_Badge.SoldOut) color = "#ef4444"
    else if (props.status === Listing_Badge.ComingSoon) color = '#4299e1'
    else if (props.status === Listing_Badge.Open) color = '#38b2ac'
    else if (props.status === Listing_Badge.LastChance) color = '#ecc94b'
    else if (props.status === Listing_Badge.NotFunded) color = '#7c8ba1'


    const Container = styled.div`
    ${tw`h-10 w-10 absolute top-0 right-0 mx-2 my-2`}
    text {
        ${tw`text-4xl!`}
        fill: #fff !important;
      }
    `
    return (
        <Container>
            <CircularProgressbar
                value={Math.floor(props.value)}
                text={`${Math.floor(props.value)}%`}
                strokeWidth={50}
                styles={buildStyles({
                    strokeLinecap: 'butt',
                    backgroundColor: color,
                    trailColor: `${color}A0`,
                    pathColor: color,
                })}
            />
        </Container>
    )

}