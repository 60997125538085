import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import { Routes as Router, Route } from 'react-router-dom'
import { ReactElement } from 'react';
import RootesData from './routes-data';

export interface IRoute {
    path: string;
    component: ReactElement;
    hidden?: boolean;
    name?: string;
    subRoutes?: IRoute[]
}

const Rootes = () => (
    <Router>
        {
            RootesData.map((route: IRoute, index) => (
                <Route key={index} path={route.path} element={route.component}>
                    {
                        route.subRoutes && route.subRoutes.map((r, i) => (
                            <Route key={i} path={r.path} element={r.component}>
                                {
                                    r.subRoutes && r.subRoutes.map((sr, i) => (
                                        <Route key={i} path={sr.path} element={sr.component}/>
                                    ))   
                                }
                            </Route>
                        ))
                    }
                </Route>
            ))
        }
    </Router>
)


export default Rootes;