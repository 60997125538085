import tw from 'twin.macro';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent,
} from '@mui/lab';
import ArticleContainer from 'components/article/article-container';
import { Ads } from 'graphql/__generated__/graphql-types';
import _ from 'lodash'
import { OtherDate } from '../../../../graphql/__generated__/graphql-types';
import moment from 'moment';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Content = tw.div`
flex justify-center pt-8
`


const Agenda = (props: { ads: Ads, id?: any, viewPortObserver?: (isVisible: boolean) => void }) => {
    const calendar = props.ads.calendar
    let otherDate: OtherDate[] = [];
    if (calendar.otherDates) {
        otherDate = calendar.otherDates.reduce((acc: OtherDate[], el: OtherDate) => {
            return [...acc, el]
        }, [])
    }
    const events = _.sortBy([...otherDate, { title: 'Start Sale', date: calendar.salesStart }, { title: 'Advertise', date: calendar.advertise }], ["date"]).reverse()

    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Agenda</Headline>
            <Divider />

            <Content>
                <Timeline >
                    {
                        events.map((el: OtherDate, index: number) => (
                            <TimelineItem key={index}>
                                <TimelineOppositeContent>
                                    {moment(el.date).format("YYYY-MM-DD")}
                                </TimelineOppositeContent>
                                <TimelineSeparator >
                                    <TimelineDot variant="outlined" //sx={{ borderColor: '#38b2ac' }}
                                    />
                                    {
                                        index < (events.length -1)  &&
                                        <TimelineConnector />
                                    }
                                </TimelineSeparator>
                                <TimelineContent color={'#38b2ac'}>{el.title}</TimelineContent>
                            </TimelineItem>
                        ))
                    }


                </Timeline>


            </Content>
        </ArticleContainer>
    )
}

export default Agenda;