import { put, takeLatest } from "redux-saga/effects";
import { DraftAdsActions } from "redux/reducers/maker/draft-ads";
import clientGraphQL from "graphql/client";
import { PayloadAction } from '@reduxjs/toolkit';
import { AdsDraft, AdsSaveDraftMutation, AdsSaveDraftDocument, QueryAdsLoadDraftArgs, AdsDraftPublishMutationVariables } from 'graphql/__generated__/graphql-types';
import { AdsLoadDraftQueryQuery, AdsLoadDraftQueryDocument, Error as BriicksError } from 'graphql/__generated__/graphql-types';
import { AdsDraftPublishMutation, AdsDraftPublishDocument, Ads } from '../../../graphql/__generated__/graphql-types';

function* adsCreationSaveAsDraftAsyncRequest(action: PayloadAction<AdsDraft>) {
    try {
        const args = action.payload
        const adsSaveDraftMutation: AdsSaveDraftMutation = yield clientGraphQL.request(AdsSaveDraftDocument, args);
        yield put(DraftAdsActions.adsCreationSaveAsDraftRequestSuccess(adsSaveDraftMutation.adsSaveDraft as AdsDraft));
    } catch (error) {
        yield put(DraftAdsActions.adsCreationSaveAsDraftRequestFailed("error"));
    }
}

function* adsLoadDraftAsyncRequest(action: PayloadAction<QueryAdsLoadDraftArgs & { callback: () => void }>) {
    try {
        const { callback, ...args } = action.payload;
        const adsLoadDraftQueryQuery: AdsLoadDraftQueryQuery = yield clientGraphQL.request(AdsLoadDraftQueryDocument, args);
        if (adsLoadDraftQueryQuery.adsLoadDraft.__typename === "AdsDraft") {
            yield put(DraftAdsActions.adsLoadDraftRequestSuccess(adsLoadDraftQueryQuery.adsLoadDraft as AdsDraft))
            if (callback) callback()
        }
        else if (adsLoadDraftQueryQuery.adsLoadDraft.__typename === "Error")
            yield put(DraftAdsActions.adsLoadDraftRequestFailed((adsLoadDraftQueryQuery.adsLoadDraft as BriicksError).message))
    } catch (error) {
        yield put(DraftAdsActions.adsLoadDraftRequestFailed("error"));
    }
}

function* adsPublishAsyncRequest(action: PayloadAction<AdsDraftPublishMutationVariables & { callback?: () => void }>) {
    try {
        const args = action.payload;
        const adsDraftPublishMutation: AdsDraftPublishMutation = yield clientGraphQL.request(AdsDraftPublishDocument, args);
        if (adsDraftPublishMutation.adsDraftPublish.__typename === 'Ads') {
            yield put(DraftAdsActions.adsPublishRequestSuccess(adsDraftPublishMutation.adsDraftPublish as Ads))
            if (action.payload.callback) action.payload.callback()
        }
        else if (adsDraftPublishMutation.adsDraftPublish.__typename === "Error")
            yield put(DraftAdsActions.adsLoadDraftRequestFailed((adsDraftPublishMutation.adsDraftPublish as BriicksError).message))
    } catch (error) {
        yield put(DraftAdsActions.adsPublishRequestFailed("error"));
    }
}

export const DraftAdsSaga = [
    takeLatest('draftAdsSlice/adsCreationSaveAsDraftRequest', adsCreationSaveAsDraftAsyncRequest),
    takeLatest('draftAdsSlice/adsLoadDraftRequest', adsLoadDraftAsyncRequest),
    takeLatest('draftAdsSlice/adsPublishRequest', adsPublishAsyncRequest),
]