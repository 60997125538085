import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet'
import tw from 'twin.macro'
import { useMemo, useRef } from 'react';
import { useField } from 'formik';
import _, { at } from 'lodash';
import { FormHelperText } from '@mui/material';

const Container = tw.div`
w-128 h-128
`

const CoordinatesField = (props: any) => {
    const [field, meta, helper] = useField(props);
    const { setValue } = helper;

    function _renderHelperText(): any {
        //const [touched, error] = at(meta, 'touched', 'error');
        const [error] = at(meta, 'error');
        //console.log({ name: props.name, meta })
        //if (touched && error) {
        if (error) {
            return <FormHelperText error sx={{ paddingLeft: 2 }}>{error}</FormHelperText>;
        } else
            return null
    }

    function DraggableMarker() {
        const markerRef = useRef(null)
        const eventHandlers = useMemo(
            () => ({
                dragend() {
                    const marker = markerRef.current
                    if (marker != null) {
                        //@ts-ignore
                        const { lat, lng } = marker.getLatLng()
                        setValue({
                            latitude: lat,
                            longitude: lng
                        }, false);
                    }
                },
            }),
            [],
        )


        return (
            <Marker
                draggable={true}
                eventHandlers={eventHandlers}
                position={[field.value?.latitude || 0, field.value?.longitude || 0]}
                ref={markerRef}>
            </Marker>
        )
    }

    // We need to wrap component in `forwardRef` in order to gain
    // access to the ref object that is assigned using the `ref` prop.
    // This ref is passed as the second parameter to the function component.

    function RecenterMap(props: { coordinates: any }) {


        const map = useMap();

        return null

        const MapCenter = props.coordinates || [0, 0] // lat, lng

        if (field.value?.latitude === MapCenter[0] && field.value?.longitude === MapCenter[1]) return null

        setValue({
            latitude: MapCenter[0],
            longitude: MapCenter[1]
        })
        map.setView(MapCenter, map.getZoom());




        return null;
    }


    return (
        <Container {...props}>
            {_renderHelperText()}
            <MapContainer
                style={{ width: "100%", height: "100%" }}
                center={[field.value?.latitude || 0, field.value?.longitude || 0]} zoom={3} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <DraggableMarker />
                <RecenterMap coordinates={props.coordinates || [0, 0]} />
            </MapContainer>
        </Container>
    )
}

export default CoordinatesField