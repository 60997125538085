import React from "react";
import tw from "twin.macro";
import { useIsInViewport } from '../common/use-is-in-viewport';

const Container = tw.div`flex mt-24 flex-col`

const ArticleContainer = (props: { className?: string, children?: React.ReactNode, id? : any, viewPortObserver?: (isVisible: boolean) => void }) => {

    const ref = React.useRef(null);
    const isInViewport = useIsInViewport(ref);
    if (props.viewPortObserver)
        props.viewPortObserver(isInViewport)

    return (
        <Container id={props.id} ref={ref} className={props.className}>
            { props.children}
        </Container>
    )
}

export default ArticleContainer;