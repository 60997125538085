import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import _ from 'lodash';

function srcset(image: string, size: number, rows = 1, cols = 1) {
    const c = Math.floor(Math.random() * 10)
    const src = `https://ik.imagekit.io/brma/tr:w-${size * cols + c},h-${size * rows + c}/${image}`
    return {
        src
    }
    /*return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };*/
}

interface ITile {
    img: string;
    title: string;
    rows: number;
    cols: number;
}

function tiles(images: string[], inverse: boolean): ITile[] {
    if (images.length === 1)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: 4,
        }]
    else if (images.length === 2)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: 2,
        },
        {
            img: images[1],
            title: '',
            rows: 2,
            cols: 2,
        }]

    else if (images.length === 3)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: inverse ? 4 : 2,
        },
        {
            img: images[1],
            title: '',
            rows: 2,
            cols: 2,
        },
        {
            img: images[2],
            title: '',
            rows: 2,
            cols: inverse ? 2 : 4,
        }]

    else if (images.length === 4)
        return [{
            img: images[0],
            title: '',
            rows: inverse ? 2 : 3,
            cols: 2,
        },
        {
            img: images[1],
            title: '',
            rows: inverse ? 3 : 2,
            cols: 2,
        },
        {
            img: images[2],
            title: '',
            rows: 1,
            cols: 1,
        }
            ,
        {
            img: images[3],
            title: '',
            rows: 1,
            cols: 1,
        }]
    else if (images.length >= 5)
        return [{
            img: images[0],
            title: '',
            rows: 2,
            cols: 4,
        },
        {
            img: images[1],
            title: '',
            rows: inverse ? 1 : 2,
            cols: 2,
        },
        {
            img: images[2],
            title: '',
            rows: inverse ? 2 : 1,
            cols: 2,
        }
            ,
        {
            img: images[3],
            title: '',
            rows: 1,
            cols: 1,
        },
        {
            img: images[4],
            title: '',
            rows: 1,
            cols: 1,
        }]
    else return []
}

const QuiltedImageList = (props: { images: string[]} & any ) => {

    const imagesItems = _.chain(props.images).chunk(5).reduce<ITile[]>((acc, o, index) => {
        return [...acc, ...tiles(o, index % 2 === 0)]
    }, []).value();



    return (
        <ImageList
            //sx={{ width: 500, height: 450 }}
            variant="quilted"
            cols={4}
            rowHeight={200}
        >
            {imagesItems.map((item) => (
                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                    <img
                        {...srcset(item.img, 200, item.rows, item.cols)}
                        alt={item.title}
                        loading="lazy"
                    />
                </ImageListItem>
            ))}
        </ImageList>
    )
}

export default QuiltedImageList;