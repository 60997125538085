import { Box, Typography, FormControl, TextField } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { BriicksMinMax } from "graphql/__generated__/graphql-types"
import tw from "twin.macro"

const BriickMinMaxTakers = (props: { totalBriicks: number, briicksMinMax?: BriicksMinMax, }) => {
    const { briicksMinMax = { min: 1, max: 1 }, totalBriicks } = props
    return (
        <Box>
            <Typography component="h1" variant="h6">
                Min/Max Takers
            </Typography>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Minimum of Takers
                    </Typography>
                </Grid>

                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={Math.ceil(totalBriicks / (briicksMinMax?.max || 1))}

                            size="small"
                            css={tw`bg-gray-200`}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>



                <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        Maximum of Takers
                    </Typography>
                </Grid>

                <Grid xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={Math.ceil(totalBriicks / (briicksMinMax?.min || 1))}
                            size="small"
                            css={tw`bg-gray-200`}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )
}

export default BriickMinMaxTakers