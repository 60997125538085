import tw from 'twin.macro';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { Listing_Badge } from 'graphql/__generated__/graphql-types';

const Container = styled.div`
${tw`h-8 w-8 absolute top-0 right-0 mx-2 my-2`}
text {
    ${tw`text-4xl!`}
    fill: #fff !important;
  }
`

const Progress = (props: {
    progress: number,
    status: Listing_Badge,
}) => {
    let color = "#22c55e"
    if (props.status === Listing_Badge.NewLaunch) color = "#22c55e"
    else if (props.status === Listing_Badge.AlmostDone) color = "#f97316"
    else if (props.status === Listing_Badge.SoldOut) color = "#ef4444"
    else if (props.status === Listing_Badge.ComingSoon) color = '#4299e1'
    else if (props.status === Listing_Badge.Open) color = '#38b2ac'
    else if (props.status === Listing_Badge.LastChance) color = '#ecc94b'
    else if (props.status === Listing_Badge.NotFunded) color = '#7c8ba1'

    return (
        <Container>
            <CircularProgressbar
                value={Math.floor(props.progress)}
                text={`${Math.floor(props.progress)}%`}
                strokeWidth={50}
                styles={buildStyles({
                    strokeLinecap: 'butt',
                    backgroundColor: color,
                    trailColor: `${color}A0`,
                    pathColor: color,
                })}
            />
        </Container>
    )
}

export default Progress;