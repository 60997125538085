import { AvailableCountriesActions } from "redux/reducers/available-countries";
import { put, takeLatest } from "redux-saga/effects";
import clientGraphQL from "graphql/client";
import { AvailableCountriesDocument, AvailableCountriesQuery } from "graphql/__generated__/graphql-types";

function* loadAvailableCountriesAsyncRequest(){
    try {
        const availableCountriesQuery: AvailableCountriesQuery = yield clientGraphQL.request(AvailableCountriesDocument);
        yield put(AvailableCountriesActions.loadAvailableCountriesSuccess(availableCountriesQuery.availableCountries));
    } catch (error) {
        //console.log(error)        
    }
}

/* ------------- Connect Types To Sagas ------------- */
export const AvailbaleCountriesSaga = [
    //reducer-name/reducer-function
    takeLatest('availableCountriesSlice/loadAvailableCountriesRequest', loadAvailableCountriesAsyncRequest,
    ),
];