import { Box, FormControl, TextField, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import tw from "twin.macro"

interface ILine {
    label: string;
    value: string;
}

const BriicksPrice = (props: { price: number, investment: number, totalBriicks: number }) => {
    const {
        investment,
        totalBriicks,
        price,
    } = props

    const Lines: ILine[] = [
        { label: 'Investment', value: `${investment}` },
        { label: 'Total Briicks for sale', value: `${totalBriicks}` },
        { label: 'UNIT BRIICK PRICE', value: price.toFixed(2) },
    ]

    function _drawLine(line: ILine, index: number) {
        return (
            <>
                <Grid key={`label-${index}`} xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                    <Typography component="h3" variant="h6" css={tw`text-sm`}>
                        {line.label}
                    </Typography>
                </Grid>
                <Grid key={`value-${index}`} xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={line.value}
                            size="small"
                            css={tw`bg-gray-200`}
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                    </FormControl>
                </Grid>
            </>
        )
    }

    return (
        <Box>
            <Typography component="h1" variant="h6">
                Briick Price
            </Typography>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 3 }} sx={{ marginTop: 1 }}>
                {Lines.map(_drawLine)}
            </Grid>
        </Box>
    )
}

export default BriicksPrice