import {
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import { Ads } from "graphql/__generated__/graphql-types";
import { AdsLoadByIdQueryVariables } from 'graphql/__generated__/graphql-types';

export interface AdsDetailsState {
    ads: Ads | null;
    isLoading: boolean;
    error: string;
}

const initialState: AdsDetailsState = {
    ads: null,
    isLoading: false,
    error: "",
}
export const AdsDetailsSlice = createSlice({
    name: 'adsDetails',
    initialState,
    reducers: {
        adsLoadByIdRequest: (state, _action: PayloadAction<AdsLoadByIdQueryVariables>) => {
            state.isLoading = true
            state.error = ""
            state.ads = null
        },
        adsLoadByIdRequestSuccess: (state, action: PayloadAction<Ads>) => {
            state.isLoading = false
            state.ads = action.payload
        },
        adsLoadByIdRequestFaillure: (state, action: PayloadAction<string>) => {
            state.isLoading = false
            state.error = action.payload
        },
    }
})

export const AdsDetailsActions = AdsDetailsSlice.actions
export default AdsDetailsSlice