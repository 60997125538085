
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2 w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`

const SubHeadLine = tw.h1`
text-primary-500 text-sm font-medium uppercase
`

const Features = (props: { id?: any,  viewPortObserver?: (isVisible: boolean) => void }) => {

    return (
        <ArticleContainer  id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Features</Headline>
            <Divider />
            <Card>
                <CardHead>
                    <HeadLine>
                        Type
                    </HeadLine>
                </CardHead>
                <Content>
                    <Label>Destination</Label>
                    <Value>residential</Value>
                </Content>
                <Content>
                    <Label>type</Label>
                    <Value>Multi-Family</Value>
                </Content>
                <Content>
                    <Label>Units</Label>
                    <Value>15</Value>
                </Content>
                <Content>
                    <Label>Stories</Label>
                    <Value>10</Value>
                </Content>
                <Content>
                    <Label>Level</Label>
                    <Value>8</Value>
                </Content>
                <CardHead>
                    <HeadLine>
                        conditions
                    </HeadLine>
                </CardHead>

                <Content>
                    <Label>Construction Year</Label>
                    <Value>1994</Value>
                </Content>

                <Content>
                    <Label>Last Renovation</Label>
                    <Value>2018</Value>
                </Content>

                <CardHead>
                    <HeadLine>
                        description
                    </HeadLine>
                </CardHead>
                <Content>
                    <SubHeadLine>
                        Interior
                    </SubHeadLine>
                </Content>
                <Content>
                    <Label>Bedrooms</Label>
                    <Value>1</Value>
                </Content>
                <Content>
                    <Label>Bathrooms</Label>
                    <Value>4</Value>
                </Content>
                <Content>
                    <Label>Lot Size</Label>
                    <Value>158</Value>
                </Content>

                <Content>
                    <SubHeadLine>
                        Outside
                    </SubHeadLine>
                </Content>
                <Content>
                    <Label>Car Parking</Label>
                    <Value>10</Value>
                </Content>
                <Content>
                    <SubHeadLine>
                        Power
                    </SubHeadLine>
                </Content>
                <Content>
                    <Label>Heating</Label>
                    <Value>Gaz</Value>
                </Content>
                <Content>
                    <Label>Cooling</Label>
                    <Value>None</Value>
                </Content>
            </Card>
        </ArticleContainer>
    )
}

export default Features;