
import { all } from "redux-saga/effects";
import { ListingSaga } from "./listings";
import { HighlightedListingSaga } from "./highlightedListings";
import { AvailbaleCountriesSaga } from './available-countries';
import { DraftAdsSaga } from './maker/draft-ads';
import { AdsListingItemsSaga } from "./maker/ads-listing-items";
import { AdsDetailsSaga } from './ads-details';

export default function* root() {
    yield all([
        ...DraftAdsSaga,
        ...ListingSaga, 
        ...HighlightedListingSaga, 
        ...AvailbaleCountriesSaga,
        ...AdsListingItemsSaga,
        ...AdsDetailsSaga,
    ]);
}