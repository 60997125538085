import tw from 'twin.macro'
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`


const Card = tw.div`
min-w-86 max-w-lg mt-2   w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2 flex flex-row
`
const HeadLine = tw.h1`
text-lg font-medium uppercase w-40 
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`
const SubHeadLine = tw.h1`
text-primary-500 text-sm font-medium uppercase
`


const Involvement = (props: {id? : any, viewPortObserver?: (isVisible: boolean) => void }) => {

    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Involvement</Headline>
            <Divider />

            <Card>
                <CardHead>
                    <HeadLine>TOTAL BRIICKS</HeadLine>
                    <Value css={tw`text-lg flex-grow`}>
                        <NumberFormat
                            value={10000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>

                    <Value css={tw`text-lg flex-grow`}>
                        <NumberFormat
                            value={80}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            suffix={' %'}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </CardHead>

                <Content>
                    <SubHeadLine>
                        On Sale
                    </SubHeadLine>
                </Content>
                <Content>
                    <Label>Market Offering</Label>
                    <Value css={tw`flex-grow`}>
                        <NumberFormat
                            value={8000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>

                    <Value css={tw`flex-grow`}>
                        <NumberFormat
                            value={80}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            suffix={' %'}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>


                <Content>
                    <SubHeadLine>
                        Current owner
                    </SubHeadLine>
                </Content>
                <Content>
                    <Label>Mouloud Chan -</Label>
                    <Value css={tw`flex-grow`}>
                        <NumberFormat
                            value={8000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>

                    <Value css={tw`flex-grow`}>
                        <NumberFormat
                            value={80}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            suffix={' %'}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>
            </Card>

        </ArticleContainer>
    )
}

export default Involvement;