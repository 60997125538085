import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';
import { Ads } from 'graphql/__generated__/graphql-types';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2  w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`

const Price = (props: {ads: Ads, id? : any, viewPortObserver?: (isVisible: boolean) => void }) => {

    const { ads } = props
    const price = ads.briicks.price
    const saleAmount = price * ads.briicks.distribution.sharesOnSale
    const remaining = price * ads.briicks.distribution.stock
    const minInvest = price * ads.briicks.minBriicksPurchase
    const maxInvest = price * ads.briicks.maxBriicksPurchase
    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>
                Price
            </Headline>
            <Divider />

            <Card>
                <CardHead>
                    <HeadLine>Price</HeadLine>
                </CardHead>
                <Content>
                    <Label>Unit Briick Price</Label>
                    <Value>
                        <NumberFormat
                            value={price}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <CardHead>
                    <HeadLine>Sale amount</HeadLine>
                </CardHead>

                <Content>
                    <Label>Amount</Label>
                    <Value>
                        <NumberFormat
                            value={saleAmount}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Remaining</Label>
                    <Value>
                        <NumberFormat
                            value={remaining}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <CardHead>
                    <HeadLine>Purchase Volume</HeadLine>
                </CardHead>


                <Content>
                    <Label>Minimum Investment</Label>
                    <Value>
                        <NumberFormat
                            value={minInvest}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Maximum Investment</Label>
                    <Value>
                        <NumberFormat
                            value={maxInvest}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>
            </Card>
        </ArticleContainer>
    )
}

export default Price