import { IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';
import tw from "twin.macro";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Container = tw.div`
flex flex-row justify-around items-center
`

const BaseButton = styled(IconButton)({
    height: 50,
    width: 50,
    borderRadius: 3,
    boxShadow: 'none',
    '&:hover': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      }
})

const AddCart = styled(BaseButton)({
    backgroundColor: "#f97316",
    borderColor: "#9a3412",
    '&:hover': {
        backgroundColor:"#9a3412" ,
        borderColor: "#f97316",
      },
      '&:active': {
        backgroundColor: "#9a3412",
        borderColor: "#f97316",
      },
      '&:focus': {
        //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
})


const AddWichList = styled(BaseButton)({
    backgroundColor: '#ed64a6',
    borderColor: '#97266d',
    '&:hover': {
        backgroundColor:'#97266d' ,
        borderColor: '#ed64a6',
      },
      '&:active': {
        backgroundColor: '#97266d',
        borderColor: '#ed64a6',
      },
      '&:focus': {
        //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
})
const AddCartWichList = () => {

    return (
        <Container>
            <AddWichList>
                <FavoriteIcon sx={{ color: "white", fontSize: 30  }}/>
            </AddWichList>

            <AddCart>
                <AddShoppingCartIcon sx={{ color: "white", fontSize: 30  }}/>
            </AddCart>
        </Container>
    )
}

export default AddCartWichList;