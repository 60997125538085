import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import tw from "twin.macro";
import NumberFormat from 'react-number-format';
import { AssetValue, AquisitionCost, FinancialMetadata, OtherFinancialMetadata } from "graphql/__generated__/graphql-types";

const Container = tw.div`min-w-86 max-w-lg mt-8   w-128  self-center`
const SammaryTitle = tw.h2`
text-primary-500 text-sm
`


const Card = tw.div`
min-w-86 max-w-lg
`
const CardHead = tw.div`
bg-primary-500 text-white p-2 flex flex-row
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`

const SubHeadLine = tw.h1`
text-primary-500 text-sm font-medium uppercase
`

const OtherAquisitionCost = (props: { cost: OtherFinancialMetadata } & any) => {
    const { cost } = props;
    return (
        <>
            <Content>
                <SubHeadLine>
                    {cost.name}
                </SubHeadLine>
            </Content>

            {
                cost.metadata.map((data: FinancialMetadata, index: number) => (
                    <Content key={index}>
                        <Label>{data.title}</Label>
                        <Value>
                            <NumberFormat
                                value={data.value}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={'text'}
                                thousandSeparator={' '}
                                prefix={'$ '}
                                renderText={(value: string) => <span>{value}</span>}
                            />
                        </Value>
                    </Content>
                ))
            }
        </>
    )
}

const Details = (props: { aquisitionCost: AquisitionCost, assetValue: AssetValue, } & any) => {
    const { aquisitionCost, assetValue } = props
    return (
        <Container>
            <Accordion elevation={0}>
                <AccordionSummary
                    sx={{ paddingLeft: 1 }}
                    expandIcon={<ExpandMoreOutlined css={tw`text-primary-500`} />}
                >
                    <SammaryTitle>Détails</SammaryTitle>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>



                    <Card>
                        <CardHead>
                            <HeadLine>Underlying Asset Value</HeadLine>
                            <Value css={tw`text-lg`}>
                                <NumberFormat
                                    value={assetValue.totalAssetValue}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    displayType={'text'}
                                    thousandSeparator={' '}
                                    prefix={'$ '}
                                    renderText={(value: string) => <span>{value}</span>}
                                />
                            </Value>
                        </CardHead>

                        {
                            assetValue.metadata && assetValue.metadata.map((data: FinancialMetadata, index: number) => (
                                <Content key={index}>
                                    <Label>{data.title}</Label>
                                    <Value>
                                        <NumberFormat
                                            value={data.value}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            displayType={'text'}
                                            thousandSeparator={' '}
                                            prefix={'$ '}
                                            renderText={(value: string) => <span>{value}</span>}
                                        />
                                    </Value>
                                </Content>
                            ))
                        }

                        <CardHead>
                            <HeadLine>Operation Cost</HeadLine>
                            <Value css={tw`text-lg`}>
                                <NumberFormat
                                    value={aquisitionCost.totalCost}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    displayType={'text'}
                                    thousandSeparator={' '}
                                    prefix={'$ '}
                                    renderText={(value: string) => <span>{value}</span>}
                                />
                            </Value>
                        </CardHead>

                        {
                            aquisitionCost.reserve &&
                            <>
                                <Content>
                                    <SubHeadLine>
                                        Reserve
                                    </SubHeadLine>
                                </Content>

                                {
                                    aquisitionCost.reserve.map((data: FinancialMetadata, index: number) => (
                                        <Content key={index}>
                                            <Label>{data.title}</Label>
                                            <Value>
                                                <NumberFormat
                                                    value={data.value}
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    displayType={'text'}
                                                    thousandSeparator={' '}
                                                    prefix={'$ '}
                                                    renderText={(value: string) => <span>{value}</span>}
                                                />
                                            </Value>
                                        </Content>
                                    ))
                                }
                            </>
                        }


                        {
                            aquisitionCost.reserve &&
                            <>
                                <Content>
                                    <SubHeadLine>
                                        Closing Cost
                                    </SubHeadLine>
                                </Content>

                                {
                                    aquisitionCost.closingCost.map((data: FinancialMetadata, index: number) => (
                                        <Content key={index}>
                                            <Label>{data.title}</Label>
                                            <Value>
                                                <NumberFormat
                                                    value={data.value}
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    displayType={'text'}
                                                    thousandSeparator={' '}
                                                    prefix={'$ '}
                                                    renderText={(value: string) => <span>{value}</span>}
                                                />
                                            </Value>
                                        </Content>
                                    ))
                                }
                            </>
                        }



                        {
                            aquisitionCost.otherCosts && aquisitionCost.otherCosts.map((otherCost: OtherFinancialMetadata, index: number) => (
                                <OtherAquisitionCost cost={otherCost} index={index} />
                            ))
                        }


                        {
                            aquisitionCost.setupCost &&
                            <>
                                <Content>
                                    <SubHeadLine>
                                    Tokenization Fees
                                    </SubHeadLine>
                                </Content>

                                {
                                    aquisitionCost.setupCost.map((data: FinancialMetadata, index: number) => (
                                        <Content key={index}>
                                            <Label>{data.title}</Label>
                                            <Value>
                                                <NumberFormat
                                                    value={data.value}
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    displayType={'text'}
                                                    thousandSeparator={' '}
                                                    prefix={'$ '}
                                                    renderText={(value: string) => <span>{value}</span>}
                                                />
                                            </Value>
                                        </Content>
                                    ))
                                }
                            </>
                        }

                    </Card>


                </AccordionDetails>
            </Accordion>
        </Container>
    )
}

export default Details;