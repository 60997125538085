import tw from 'twin.macro';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';

const Div = tw.div`
w-full flex flex-row bg-purple-900  mt-1 p-1 rounded-md  
items-center justify-center
`
const Span = tw.span`
overflow-hidden text-center
text-white uppercase whitespace-nowrap truncate
mr-1
`

//tw`absolute w-24 rounded-md text-center mx-2 my-2 px-2 py-1 text-white uppercase top-0 left-0`
export const DaysCount = (props: {
    children: React.ReactNode
}) => {
    return (
        <Div>
            <Span>{props.children}</Span>
            <Tooltip title="Detail infos" placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: "#faf5ff",
                        color: '#44337a'
                    }
                },
                arrow: {
                    sx: {
                        color: "#faf5ff"
                    }
                }
            }}>
                <IconButton sx={{height: 16, width: 16, padding: 0}}>
                    <InfoIcon sx={{fontSize: 16, color: 'white'}}/>
                </IconButton>
            </Tooltip>
        </Div>
    )
}

export default DaysCount;