import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import tw from "twin.macro";
import MediaGalleryNameDialog from "./gallery-name-dialog";
import { useState } from "react";
import { useField } from 'formik';
import _, { at } from 'lodash';
import { FormHelperText } from '@mui/material';
import GalleryContent from "./gallery-content";

interface GalleryContentType {
    link: string;
    type: string;
}

export interface MediaGalleryDataType {
    galleryName: string,
    content: GalleryContentType[]
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`media-gallery-${index}`}
            aria-labelledby={`media-gallery-tab${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2, border: 1, borderTop: 0, borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderColor: 'divider' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `media-gallery-tab${index}`,
        'aria-controls': `media-gallery-${index}`,
    };
}

const MediaGallery = (props: any &
{
    label: string,
    buttonLabel: string,
    helpText: string,
    galleryMapper: (gallery: any) => MediaGalleryDataType,
    creatNewGalleryDelegate: (name: string) => any,
    dataMapper: (input: File, output: any) => any 
}) => {
    const { label, buttonLabel, helpText, galleryMapper, creatNewGalleryDelegate, subObjectsName, dataMapper, name, ...rest } = props
    const [openGalleryNameDialog, setOpenGalleryNameDialog] = useState(false)
    const [selectedGallery, setSelectedGallery] = useState(0)

    const [field, meta, helper] = useField(props);
    const { setValue } = helper;

    function addGallery(name: string) {
        const galleries = (field.value || []) as Array<any>
        galleries.push(creatNewGalleryDelegate(name))

        setValue(galleries, false)
    }


    function _renderHelperText(): any {
        const [error] = at(meta, 'error');

        if (error) {
            return <FormHelperText error sx={{ paddingLeft: 2 }}>{error}</FormHelperText>;
        } else
            return null
    }

    const handleChangeGalleryTab = (_event: React.SyntheticEvent, newValue: number) => {
        setSelectedGallery(newValue);
    };

    function drawGalleryContent(index: number, gallery: any) {
        const mediaGallery: MediaGalleryDataType = galleryMapper(gallery)
        return (
            <GalleryContent gallery={mediaGallery} name={`${name}[${index}].${subObjectsName}`} dataMapper={dataMapper}/>
        )
    }

    return (
        <>
            <Box {...rest}>
                <Box css={tw`flex flex-row justify-between`}>
                    <Typography component="h1" variant="h6">
                        {label}
                    </Typography>
                    <Button size="small" color='secondary' variant="contained" onClick={() => { setOpenGalleryNameDialog(true) }}>{buttonLabel}</Button>
                </Box>
                <Typography variant="caption" display="block" gutterBottom>
                    {helpText}
                </Typography>
                {_renderHelperText()}
                {

                    field.value &&
                    <Box sx={{ marginTop: 1 }}>

                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> 
                            <Tabs
                                variant="scrollable"
                                scrollButtons="auto"
                                value={selectedGallery}
                                onChange={handleChangeGalleryTab}
                                aria-label="basic tabs example">
                                {
                                    field.value.map((gallery: any, index: number) => (
                                        <Tab key={index} label={gallery.name} {...a11yProps(0)} />
                                    ))
                                }

                            </Tabs>

                        </Box>

                        {

                            field.value.map((gallery: any, index: number) => (
                                <TabPanel key={index} value={selectedGallery} index={index}>
                                    {
                                        drawGalleryContent(index, gallery)
                                    }
                                </TabPanel>
                            ))

                        }

                    </Box>
                }
            </Box>

            <MediaGalleryNameDialog
                open={openGalleryNameDialog}
                onClose={() => { setOpenGalleryNameDialog(false) }}
                onValidate={(name: string) => { addGallery(name) }}
            />
        </>
    )
}

export default MediaGallery;