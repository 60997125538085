import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import tw from "twin.macro";
import NumberFormat from 'react-number-format';
import CapitalApreciation from "./capital-apreciation";

const Container = tw.div`min-w-86 max-w-lg mt-8   w-128  self-center`
const SammaryTitle = tw.h2`
text-primary-500 text-sm
`


const Card = tw.div`
min-w-86 max-w-lg
`
const CardHead = tw.div`
bg-primary-500 text-white p-2 flex flex-row
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`


const Details = () => {
    return (
        <Container>
            <Accordion elevation={0}>
                <AccordionSummary
                    sx={{ paddingLeft: 1 }}
                    expandIcon={<ExpandMoreOutlined css={tw`text-primary-500`} />}
                >
                    <SammaryTitle>Détails</SammaryTitle>
                </AccordionSummary>

                <AccordionDetails sx={{ padding: 0 }}>
                    <Card>
                        <CardHead>
                            <HeadLine>Rental Income</HeadLine>
                        </CardHead>

                        <Content>
                            <Label>Annual Gross Rent</Label>
                            <Value>
                                <NumberFormat
                                    value={95000}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    displayType={'text'}
                                    thousandSeparator={' '}
                                    prefix={'$ '}
                                    renderText={(value: string) => <span>{value}</span>}
                                />
                            </Value>
                        </Content>

                        <Content>
                            <Label>Annual Operating Cost</Label>
                            <Value>
                                <NumberFormat
                                    value={8500}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    displayType={'text'}
                                    thousandSeparator={' '}
                                    prefix={'$ '}
                                    renderText={(value: string) => <span>{value}</span>}
                                />
                            </Value>
                        </Content>

                        <Content>
                            <Label>Annual Net Rent</Label>
                            <Value>
                                <NumberFormat
                                    value={96500}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    displayType={'text'}
                                    thousandSeparator={' '}
                                    prefix={'$ '}
                                    renderText={(value: string) => <span>{value}</span>}
                                />
                            </Value>
                        </Content>

                        <Content>
                            <Label>Cash On Cash</Label>
                            <Value>
                                <NumberFormat
                                    value={8.75}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    displayType={'text'}
                                    thousandSeparator={' '}
                                    suffix={' %'}
                                    renderText={(value: string) => <span>{value}</span>}
                                />
                            </Value>
                        </Content>
                    </Card>

                    <CapitalApreciation />
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}

export default Details;