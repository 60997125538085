import Involvement from './involvement';
import { IFullAdsSection } from 'components/common/types';
//import Ledger from './ledger';
import Restrinctions from './restrictions';
import SPVId from "./spv-id";
import SectionContainer from 'components/article/section-container';
import { Ads } from 'graphql/__generated__/graphql-types';

export const elements: IFullAdsSection[] = [
    {
        name: 'SPV',
        element: SPVId
    },
    {
        name: 'Involvement',
        element: Involvement
    },
    /*{
        name: 'Ledger',
        element: Ledger
    },*/
    {
        name: 'Restrinctions',
        element: Restrinctions
    },
]

const Legal =  (props: {ads: Ads, setVisibleArticle: (articleName: string) => void }) => {

    return (
        <SectionContainer>
            {
                elements.map((e, index) => {
                    const V = e.element

                    return <V ads={props.ads} id={e.name} key={index} viewPortObserver={(isVisible: boolean) => {
                        if (isVisible) props.setVisibleArticle(elements[index].name)
                    }} />
                })
            }
        </SectionContainer>
    )
}

export default Legal;