import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { Box } from "@mui/system"
import tw from "twin.macro"
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DeleteIcon from '@mui/icons-material/Delete';
import PreviewIcon from '@mui/icons-material/Preview';
import { AdsListing, AdsStatus } from '../../../graphql/__generated__/graphql-types';
import { useDispatch } from "react-redux";
import { DraftAdsActions } from "redux/reducers/maker/draft-ads";
import { useNavigate } from "react-router-dom";
import { AdsListingItemsActions } from "redux/reducers/maker/ads-listing-items";
import { useState } from "react";

const AssetItem = (props: { asset: AdsListing } & any) => {
    const {
        asset
    } = props

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const _handleEdit = () => {
        dispatch(DraftAdsActions.adsLoadDraftRequest({ _id: asset._id, callback: () => { navigate(`create/${asset._id}`) } }))
    }

    const _handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false)
    }
    const _handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true)
    }
    const _handleDelete = () => {
        dispatch(AdsListingItemsActions.deleteAdsListingItemRequest({ _id: asset._id }))
        setOpenDeleteDialog(false)
    }

    const _handlePreview = () => {
        /*navigate(`/market/listing/${asset._id}`, {
            replace: false
        });*/
        window.open(`/market/listing/${asset._id}`,'_blank')
    }

    return (
        <>
            <ListItem css={tw`border-b py-2`}>
                <ListItemAvatar>
                    <Avatar css={tw`h-24 w-24`} variant="square" src={`https://ik.imagekit.io/brma/tr:w-400,h-400/${asset.asset.pictures.featured}`} />
                </ListItemAvatar>
                <ListItemText css={tw`px-4`}>
                    <Typography variant="h6" gutterBottom>{asset.title}</Typography>
                    <Typography variant="caption" color="success.main">{asset.status}</Typography>
                </ListItemText>
                <Box>
                    <Tooltip title="Edit">
                        <IconButton onClick={_handleEdit}>
                            <DesignServicesIcon />
                        </IconButton>
                    </Tooltip>
                    {
                        asset.status !== AdsStatus.Draft &&
                        <Tooltip title="View on the market">
                            <IconButton onClick={_handlePreview}>
                                <PreviewIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title="Delete">
                        <IconButton onClick={_handleOpenDeleteDialog}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </ListItem>
            <Dialog
                open={openDeleteDialog}
                onClose={_handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Ads?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Delete
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={_handleCloseDeleteDialog}>No</Button>
                    <Button onClick={_handleDelete} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </>

    )
}

export default AssetItem