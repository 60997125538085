import tw from 'twin.macro';
import { useEffect, useState, useRef } from 'react';
import MarketFilters from 'components/form/market-filter';
import { ReactComponent as ArrowLeftIcon } from 'feather-icons/dist/icons/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from 'feather-icons/dist/icons/arrow-right.svg'
import { ReactComponent as ShowFilterIcon } from 'feather-icons/dist/icons/filter.svg'
import { ReactComponent as LoaderIcon } from 'feather-icons/dist/icons/loader.svg'
import { ReactComponent as CloseFilterIcon } from 'feather-icons/dist/icons/x.svg'
import ListingCard from '../../components/cards/listing';
//import InfiniteScroll from 'react-infinite-scroll-component'
import { SecondaryButton as SecondaryButtonBase } from 'components/misc/Buttons.js'

import { useSelector } from "redux/store";
import { useDispatch } from "react-redux";
import {
    ListingsActions
} from "redux/reducers/listings";
import { BriicksFiltersInput } from "graphql/__generated__/graphql-types";
import styled from "styled-components";
import { motion } from 'framer-motion';
import ErrorAlert from "components/alert/error";
import _ from 'lodash';
import { AvailableCountriesActions } from '../../redux/reducers/available-countries';

const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`
const Wraper = tw.div`md:flex flex-col md:flex-row md:min-h-screen w-full`

const SideBareWraper = tw.div`flex flex-col w-full md:w-64 text-gray-700 bg-white flex-shrink-0`
const FilterToggle = tw.div`flex-shrink-0 px-8 p-4 lg:py-0 flex flex-row items-center justify-between`
const FilterButton = tw.button`rounded-lg md:hidden rounded-lg focus:outline-none focus:shadow-outline`
const MobileFilterContent = tw.div`lg:hidden flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto`
const DesktopFilterContent = tw.div`hidden lg:flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto`
const FilterHead = tw.h1`text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg`

const ListingContainer = tw.div`flex flex-col items-center flex-grow`
const Grid = tw.div`grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 items-center relative`
const PaginationContainer = tw.div`flex flex-col items-center mt-8`
const PaginationButtonsContainer = tw.div`inline-flex mt-2`
const PagesLabel = tw.span`text-sm text-gray-500`
const PagesNumber = tw.span`font-semibold text-gray-700`
const SecondaryButton = styled(SecondaryButtonBase)`
${tw`inline-flex items-center py-2! px-4! text-sm font-medium first:mr-4`}
svg{
    ${tw`h-5`}
}
`
const LoadingContainer = styled(motion.div)`
${tw`flex w-full h-10 py-8 justify-center items-center`}
svg{
    ${tw`h-10 w-10`}
}
`
const NoResultContainer = tw.div`flex w-full h-10 py-8 justify-center items-center`
const NoResultHead = tw.h5`ml-2 text-2xl font-black tracking-wider`
//const LoadMoreContainer = tw.div`mt-10 flex flex-col justify-center items-center`


const MarketPage = () => {
    const [filterVisible, setFilterVisible] = useState(false)

    const listings = useSelector((state) => state.listings)

    const availableCountries = useSelector((state) => state.availableCountrie);

    const [apiInput, setApiInput] = useState<{ page: number; filters?: BriicksFiltersInput }>({
        page: 1,
        filters: {}
    })

    const setCurrentPage = (page: number) => {
        setApiInput({ ...apiInput, page })
    }

    /*const loadMoreAction = () => {
        setCurrentPage(currentPage + 1)
    }*/

    let containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;


    const dispatche = useDispatch()

    const fetchListingsData = () => {
        /*if (containerRef && containerRef.current && containerRef.current.offsetTop)
            window.scrollTo({ behavior: 'smooth', top: containerRef?.current?.offsetTop })*/
        dispatche(ListingsActions.loadListingsRequest({
            page: apiInput.page,
            limit: 9,
            filters: apiInput.filters
        }))

        dispatche(AvailableCountriesActions.loadAvailableCountriesRequest())
    }

    useEffect(() => {
        fetchListingsData()
        // eslint-disable-next-line
    }, [apiInput])

    const onFiltersChange = (filters: BriicksFiltersInput) => {
        setApiInput({ page: 1, filters })
    }

    const totalDocs = listings.listingsPage.totalDocs
    const startIndex = (listings.listingsPage.page - 1) * listings.listingsPage.limit + 1
    const lastIndex = listings.listingsPage.hasNextPage ? listings.listingsPage.limit * listings.listingsPage.page : listings.listingsPage.totalDocs

    return <>
            <div ref={containerRef} />
                <Content>
                    {
                        !_.isEmpty(listings.error) &&
                        <ErrorAlert message='Oops looks like something went wrong.' />
                    }
                
                    <Wraper>
                        <SideBareWraper>
                            <FilterToggle>
                                <FilterHead>FILTERS</FilterHead>
                                <FilterButton onClick={() => setFilterVisible(!filterVisible)}>
                                    {filterVisible ? <CloseFilterIcon /> : <ShowFilterIcon />}
                                </FilterButton>
                            </FilterToggle>
                            <MobileFilterContent hidden={!filterVisible}>
                                <MarketFilters countries={availableCountries.countries} onFiltersChange={onFiltersChange} />
                            </MobileFilterContent>
                            <DesktopFilterContent>
                                <MarketFilters countries={availableCountries.countries} onFiltersChange={onFiltersChange} />
                            </DesktopFilterContent>
                        </SideBareWraper>

                        {/******* Start content *******/}
                        {
                            /*
                        }
                        <InfiniteScroll
                            dataLength={listings.listingsPage.totalDocs}
                            next={() => { }}
                            hasMore={listings.listingsPage.hasNextPage || false}
                            loader={
                                <LoadMoreContainer>
                                    {listings.isLoading && <h4>Loading...</h4>}
                                    {!listings.isLoading && (
                                        <PrimaryButton onClick={loadMoreAction}>
                                            Load more
                                        </PrimaryButton>
                                    )}
                                </LoadMoreContainer>
                            }
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            {
                                */
                        }

                        
                        <ListingContainer>
                            {
                                !listings.isLoading && listings.listingsPage.totalDocs === 0 &&
                                <NoResultContainer>
                                    <NoResultHead>No offers found.</NoResultHead>
                                </NoResultContainer>
                            }

                            {
                                listings.isLoading && <LoadingContainer
                                    animate={{ rotate: 360 }}
                                    transition={{
                                        loop: Infinity,
                                        ease: "linear",
                                        duration: 1.5
                                    }}
                                >
                                    <LoaderIcon />
                                </LoadingContainer>

                            }
                            <Grid css={tw`px-4 sm:px-0`}>
                                {
                                    listings.listingsPage.docs.map((listing, index) => {
                                        return (
                                            <div key={index}>
                                                <ListingCard key={listing.listingId} listing={listing} />
                                            </div>
                                        )
                                    })
                                }
                            </Grid>

                            {
                                //</InfiniteScroll>
                            }

                            {
                                listings.listingsPage.totalDocs > 0 &&
                                <PaginationContainer>
                                    <PagesLabel>
                                        Showing <PagesNumber>{startIndex}</PagesNumber> to <PagesNumber>{lastIndex}</PagesNumber> of <PagesNumber>{totalDocs}</PagesNumber> Offers
                                    </PagesLabel>
                                    <PaginationButtonsContainer>
                                        <SecondaryButton onClick={() => { setCurrentPage(apiInput.page - 1) }} disabled={!!!listings.listingsPage.hasPrevPage}>
                                            <ArrowLeftIcon />
                                            Prev
                                        </SecondaryButton>
                                        <SecondaryButton onClick={() => { setCurrentPage(apiInput.page + 1) }} disabled={!!!listings.listingsPage.hasNextPage}>
                                            Next
                                            <ArrowRightIcon />
                                        </SecondaryButton>
                                    </PaginationButtonsContainer>
                                </PaginationContainer>
                            }
                        </ListingContainer>

                    </Wraper>
                </Content>
            
    </>
}

export default MarketPage