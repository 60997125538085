import { ChangeEvent, memo, useEffect, useState } from 'react';
import tw from 'twin.macro';
import _ from "lodash"
import styled from 'styled-components';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons'
import { SecondaryButton as SecondaryButtonBase } from 'components/misc/Buttons'
import { ReactComponent as SearchIcon } from 'feather-icons/dist/icons/search.svg'
import { Listing_Source_Names, Listing_Badge, BriicksFiltersInput, ComparatorInput, FilterNumberInput, Arithmetic_Comparator } from 'graphql/__generated__/graphql-types';

const InputContainer = tw.div`flex items-center mb-1 pl-2`
const Input = tw.input`w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`
const Label = tw.label`ml-2 text-sm font-medium text-gray-700`
const FilterHeader = tw.h2`text-lg font-semibold mb-2 mt-6`

const NumberInput = styled.input`
${tw`inline p-1 max-w-20 mr-2 bg-gray-100 rounded border border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
`


const PrimaryButton = styled(PrimaryButtonBase)`
${tw`rounded font-normal p-2 px-2`}
svg{
    ${tw`w-5 h-5`}
}
`

const SecondaryButton = tw(SecondaryButtonBase)`rounded mt-4 w-full py-2 `

const statusFilter = [
    {
        label: "Coming soon",
        value: Listing_Badge.ComingSoon
    },
    {
        label: "New launch",
        value: Listing_Badge.NewLaunch
    },
    {
        label: "Open",
        value: Listing_Badge.Open
    },
    {
        label: "Almost Done",
        value: Listing_Badge.AlmostDone
    },
    {
        label: "Last Chance",
        value: Listing_Badge.LastChance
    },
    {
        label: "Sold Out",
        value: Listing_Badge.SoldOut
    },
    {
        label: "Not Funded",
        value: Listing_Badge.NotFunded
    },
]

const minInvestFilter = [
    {
        label: "< 50$",
        value: "lt-50"
    },
    {
        label: "< 5000$",
        value: "lt-5000"
    },
    {
        label: "> 5000$",
        value: "gt-5000"
    },
]

const incomeFilter = [
    {
        label: "< 5%",
        value: "lt-5"
    },
    {
        label: "< 15%",
        value: "lt-15"
    },
    {
        label: "> 15%",
        value: "gt-15"
    }
]

const makerFilter = [
    {
        label: "RealT",
        value: Listing_Source_Names.RealtMixed
    },
    {
        label: "Reental",
        value: Listing_Source_Names.Reental
    },
    {
        label: "BrickS",
        value: Listing_Source_Names.Bricks
    },
    {
        label: "LandShare",
        value: Listing_Source_Names.LandShare
    },
    {
        label: "Grapen Invest",
        value: Listing_Source_Names.GrapenInvest
    },
    {
        label: "Lofty",
        value: Listing_Source_Names.Lofty
    },
    {
        label: "Vista Equity",
        value: Listing_Source_Names.VestaEquity
    },
    {
        label: "BrickX",
        value: Listing_Source_Names.Brickx
    },
    {
        label: "Katapulte",
        value: Listing_Source_Names.Katapulte
    },
    {
        label: "Smart Crowd",
        value: Listing_Source_Names.SmartCrowd
    },
    {
        label: "Smart Direct",
        value: Listing_Source_Names.SmartCrowdDirect
    },
    {
        label: "Invest Dubai",
        value: Listing_Source_Names.InvestDubai
    },
    {
        label: "Smart Crowd Pakistan",
        value: Listing_Source_Names.SmartCrowdPakistan
    },
    /*{
        label: "La Première Brique",
        value: Listing_Source_Names.LaPremiereBrique
    },*/
    {
        label: "Vairt",
        value: Listing_Source_Names.Vairt
    },
    {
        label: "Aqar Chain",
        value: Listing_Source_Names.AqarChain
    }
]

interface IBetween {
    min: string;
    max: string;
}
interface IFiltersInterval {
    income: IBetween;
    minInvest: IBetween;
}

interface IFilters {
    status: string[];
    minInvest: string[] | IBetween;
    income: string[] | IBetween;
    country: string[];
    maker: string[];
}

interface IConvertor {
    (el: string): ComparatorInput | null;
}

type FiltersKey = keyof IFilters;
type FiltersIntervalKey = keyof IFiltersInterval;
type BetweenKey = keyof IBetween

export interface onFiltersChangeFun {
    (filters: BriicksFiltersInput): void;
}

const MarketFilters = memo((props: { onFiltersChange: onFiltersChangeFun, countries: string[] }) => {

    const initialState: IFilters = {
        status: [Listing_Badge.ComingSoon, Listing_Badge.NewLaunch, Listing_Badge.Open, Listing_Badge.LastChance, Listing_Badge.AlmostDone],
        minInvest: [],
        income: [],
        country: [],
        maker: []
    }

    const initialFiltersIntervalState: IFiltersInterval = {
        minInvest: {
            min: "",
            max: ""
        },
        income: {
            min: "",
            max: ""
        }
    }

    const [filters, setFilters] = useState<IFilters>(initialState)
    const [filtersInterval, setFiltersInterval] = useState<IFiltersInterval>(initialFiltersIntervalState)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const filterValue = e.target.value
        const path = e.target.id.split('_')
        const key = path[0] as FiltersKey;
        let oldFilter = filters[key]

        if (!Array.isArray(oldFilter)) oldFilter = [] as string[]

        //if (['status'].includes(path[0])) {
        let newFilter: string[] = []
        if (_.includes(oldFilter, filterValue)) {
            newFilter = _.filter(oldFilter, (e) => e !== filterValue)
        } else {
            newFilter = [...oldFilter, filterValue]
        }

        filters[key] = newFilter
        //}


        setFilterState({ ...filters })
        if (key === 'minInvest') {
            setFiltersInterval({ ...filtersInterval, minInvest: { min: '', max: '' } })
        } else if (key === 'income') {
            setFiltersInterval({ ...filtersInterval, income: { min: '', max: '' } })
        }



    }

    const minInvestConvertor: IConvertor = (el) => {
        switch (el) {
            case 'lt-50':
                return {
                    operator: Arithmetic_Comparator.LittelThan,
                    value: 50
                }
            case 'lt-5000':
                return {
                    operator: Arithmetic_Comparator.LittelThan,
                    value: 5000
                }
            case 'gt-5000':
                return {
                    operator: Arithmetic_Comparator.GreaterThan,
                    value: 5000
                }
            default: return null
        }
    }

    const incomeConvertor: IConvertor = (el) => {
        switch (el) {
            case 'lt-5':
                return {
                    operator: Arithmetic_Comparator.LittelThan,
                    value: 5
                }
            case 'lt-15':
                return {
                    operator: Arithmetic_Comparator.LittelThan,
                    value: 15
                }
            case 'gt-15':
                return {
                    operator: Arithmetic_Comparator.GreaterThan,
                    value: 15
                }
            default: return null;
        }
    }

    const convertStringArrayToComparatorInput = (originMinInvest: string[], convertor: IConvertor): ComparatorInput[] => {
        const oneValueOf = originMinInvest.map<ComparatorInput | null>(convertor)
        return _.filter(oneValueOf, (el) => el !== null) as ComparatorInput[]
    }

    const { onFiltersChange } = props
    useEffect(() => {
        const requestFilters: BriicksFiltersInput = {
        }

        if (!_.isEmpty(filters.status)) requestFilters.status = filters.status as Listing_Badge[]
        if (!_.isEmpty(filters.country)) requestFilters.countries = filters.country
        if (!_.isEmpty(filters.maker)) requestFilters.makers = filters.maker as Listing_Source_Names[]
        if (!_.isEmpty(filters.income)) {
            let income: FilterNumberInput;
            if (('min' in filters.income) && ('max' in filters.income)) {
                income = {
                    between: {
                        min: parseFloat(filters.income.min),
                        max: parseFloat(filters.income.max)
                    }
                }
                requestFilters.income = income;
            }
            else if (Array.isArray(filters.income)) {
                income = {
                    oneValueOf: convertStringArrayToComparatorInput(filters.income, incomeConvertor)
                }
                requestFilters.income = income;
            }
        }
        if (!_.isEmpty(filters.minInvest)) {
            let minInvest: FilterNumberInput
            if (('min' in filters.minInvest) && ('max' in filters.minInvest)) {
                minInvest = {
                    between: {
                        min: parseFloat(filters.minInvest.min),
                        max: parseFloat(filters.minInvest.max)
                    }
                }
                requestFilters.minInvest = minInvest;
            }
            else if (Array.isArray(filters.minInvest)) {
                minInvest = {
                    oneValueOf: convertStringArrayToComparatorInput(filters.minInvest, minInvestConvertor)
                }
                requestFilters.minInvest = minInvest;
            }
        }

        onFiltersChange(requestFilters)

    }, [filters])




    const onIntervalChange = (e: ChangeEvent<HTMLInputElement>) => {
        const path = e.target.id.split('-')
        const oldFilter = filtersInterval[path[0] as FiltersIntervalKey]
        oldFilter[path[1] as BetweenKey] = e.target.value

        filtersInterval[path[0] as FiltersIntervalKey] = oldFilter
        setFiltersInterval({ ...filtersInterval })
    }

    const setFilterState = (newState: IFilters) => {
        setFilters({ ...newState })
    }


    return <>
        <FilterHeader>Status</FilterHeader>
        {
            statusFilter.map((filter, index) => (
                <InputContainer key={index}>
                    <Input id={`status_${filter.value}`} value={filter.value} onChange={onChange} checked={_.includes(filters.status, filter.value)} type='checkbox' />
                    <Label htmlFor={`status_${filter.value}`}>{filter.label}</Label>
                </InputContainer>
            ))
        }

        <FilterHeader>Min. Invest</FilterHeader>
        {
            minInvestFilter.map((filter, index) => (
                <InputContainer key={index}>
                    <Input id={`minInvest_${filter.value}`} value={filter.value} onChange={onChange} checked={Array.isArray(filters.minInvest) && _.includes(filters.minInvest, filter.value)} type='checkbox' />
                    <Label htmlFor={`minInvest_${filter.value}`}>{filter.label}</Label>
                </InputContainer>
            ))
        }


        <InputContainer>
            <NumberInput id="minInvest-min" type="number" placeholder="Min"
                onChange={onIntervalChange}
                value={filtersInterval.minInvest.min} />
            <NumberInput id="minInvest-max" type="number" placeholder="Max"
                onChange={onIntervalChange}
                value={filtersInterval.minInvest.max} />
            <PrimaryButton
                onClick={() => { setFilters({ ...filters, minInvest: filtersInterval.minInvest }) }}
                disabled={_.isEmpty(filtersInterval.minInvest.max) || _.isEmpty(filtersInterval.minInvest.min)}>
                <SearchIcon />
            </PrimaryButton>
        </InputContainer>

        <FilterHeader>Income</FilterHeader>
        {
            incomeFilter.map((filter, index) => (
                <InputContainer key={index}>
                    <Input id={`income_${filter.value}`} value={filter.value} onChange={onChange} checked={Array.isArray(filters.income) && _.includes(filters.income, filter.value)} type='checkbox' />
                    <Label htmlFor={`income_${filter.value}`}>{filter.label}</Label>
                </InputContainer>
            ))
        }

        <InputContainer>
            <NumberInput id='income-min' type="number" placeholder="Min"
                onChange={onIntervalChange}
                value={filtersInterval.income.min} />
            <NumberInput id='income-max' type="number" placeholder="Max"
                onChange={onIntervalChange}
                value={filtersInterval.income.max} />
            <PrimaryButton
                onClick={() => { setFilters({ ...filters, income: filtersInterval.income }) }}
                disabled={_.isEmpty(filtersInterval.income.max) || _.isEmpty(filtersInterval.income.min)}>
                <SearchIcon />
            </PrimaryButton>
        </InputContainer>

        <FilterHeader>Country</FilterHeader>
        {
            props.countries.map((country, index) => (
                <InputContainer key={index}>
                    <Input id={`country_${country}`} value={country} onChange={onChange} checked={_.includes(filters.country, country)} type='checkbox' />
                    <Label htmlFor={`country_${country}`}>{country}</Label>
                </InputContainer>
            ))
        }


        <FilterHeader>Maker</FilterHeader>
        {
            makerFilter.map((filter, index) => (
                <InputContainer key={index}>
                    <Input id={`maker_${filter.value}`} value={filter.value} onChange={onChange} checked={_.includes(filters.maker, filter.value)} type='checkbox' />
                    <Label htmlFor={`maker_${filter.value}`}>{filter.label}</Label>
                </InputContainer>
            ))
        }

        <SecondaryButton onClick={() => {
            setFilterState(initialState)
            setFiltersInterval(initialFiltersIntervalState)
        }}>Reset</SecondaryButton>
    </>
})

export default MarketFilters