import { Box, Typography, Button, FormControl, TextField } from "@mui/material";
import BriicksRestrictionsPicker from "components/modale/briicks-restrictions-picker";
import { useState } from "react";
import tw from "twin.macro";
import { BriicksRestrictionItemInput } from '../../graphql/__generated__/graphql-types';
import { useField } from "formik";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import InputField from "./input-field";

const BriicksRestrictionItem = (props: { title: string, quantity: number, percentage: number, name?: string } & any) => {
    const { title, quantity, percentage, name = '' } = props
    return (
        <>
            <Grid xs={1} sx={{ display: "flex", justifyContent: 'start', alignItems: 'center' }}>
                <Typography component="h3" variant="h6" css={tw`text-sm`}>
                    {title}
                </Typography>
            </Grid>
            <Grid xs={2}>
                <FormControl fullWidth>
                    <InputField
                        name={`${name}.quantity`}
                        size="small"
                        valueType='Int'
                    />
                    {/*
                        <TextField
                            name={`${name}.quantity`}
                            value={"quantity"}
                            css={tw`bg-gray-200`}
                            size="small"
                            inputProps={
                                { readOnly: true, }
                            }
                        />
                        */}
                </FormControl>
            </Grid>
            <Grid xs={2}>
                <FormControl fullWidth>
                    <TextField
                        value={`${percentage.toFixed(0)}%`}
                        css={tw`bg-gray-200`}
                        size="small"
                        inputProps={
                            { readOnly: true, }
                        }
                    />
                </FormControl>
            </Grid>
        </>
    )
}

const BriicksRestrictionsField = (props: {
    label?: string,
    buttonLabel?: string,
    labelStyle?: any,
    maxQuantityValue: number,
    totalBriicksForSale: number,
} & any) => {

    const {
        label = "Restrictions",
        buttonLabel = 'Add new restriction',
        labelStyle = {},
        maxQuantityValue,
        totalBriicksForSale,
        ...rest } = props

    const [openModalAddNewRestriction, setOpenModalAddNewRestriction] = useState(false);
    const restrictionsField = useField("restrictions.restrictionItems")
    function _handleOnSelectRestriction(restrictionItem: BriicksRestrictionItemInput) {
        const items = [restrictionItem]
        if (restrictionsField[0].value) {
            restrictionsField[2].setValue([...restrictionsField[0].value, ...items], false)
            restrictionsField[2].setTouched(true, false)
        } else {
            restrictionsField[2].setValue(items, false)
            restrictionsField[2].setTouched(true, false)
        }

    }

    function addRestrictionAction() {
        setOpenModalAddNewRestriction(true)
    }

    return (
        <>
            <Box {...rest}>
                <Box css={tw`flex flex-row justify-between`}>
                    <Typography component="h1" variant="h6" css={tw`inline`} {...labelStyle}>
                        {label}
                    </Typography>

                    <Button size="small" color='secondary' variant="contained" onClick={addRestrictionAction}>{buttonLabel}</Button>
                </Box>
                {
                    restrictionsField[0].value &&
                    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 5 }} sx={{ marginTop: 1 }}>
                        {
                            restrictionsField[0].value.map((r: BriicksRestrictionItemInput, index: number) => (
                                <BriicksRestrictionItem
                                    key={index}
                                    title={r.title}
                                    name={`${restrictionsField[0].name}[${index}]`}
                                    quantity={r.quantity}
                                    percentage={r.quantity * 100 / totalBriicksForSale}
                                />
                            ))
                        }
                    </Grid>
                }


            </Box>

            <BriicksRestrictionsPicker
                open={openModalAddNewRestriction}
                onClose={() => { setOpenModalAddNewRestriction(false) }}
                onSelectRestriction={_handleOnSelectRestriction}
                maxQuantityValue={maxQuantityValue}
            />
        </>
    )
}

export default BriicksRestrictionsField;