import tw from 'twin.macro';
import parse from 'html-react-parser';
import styled from 'styled-components';
import ArticleContainer from 'components/article/article-container';
import { Ads, BriicksRestrictionItem } from 'graphql/__generated__/graphql-types';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const RestrictionContent = styled.div`
${tw`mt-2`}
h1{
 ${tw`font-semibold py-2 mt-2 text-lg`}
}
a{
    ${tw`text-primary-400`}
}
`

const Restrinctions = (props: { ads: Ads, id?: any, viewPortObserver?: (isVisible: boolean) => void }) => {
    const { restrictions } = props.ads.briicks
    if (!restrictions || (restrictions && !restrictions.hasRestriction)) return <></>
    else
        return (
            <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
                <Headline>Restrinctions</Headline>
                <Divider />
                {
                    restrictions && restrictions.restrictionItems && restrictions.restrictionItems.map((restriction: BriicksRestrictionItem, index: number) => (
                        <RestrictionContent key={index}>
                            <h1>{restriction.title}</h1>
                            {
                                parse(restriction.description || '', { trim: true })
                            }
                            {
                                restriction.url && 
                                <a href={restriction.url} target={'_blank'} rel="noreferrer">{" "} more info...</a>
                            }
                        </RestrictionContent>
                    ))
                }

            </ArticleContainer>
        )
}

export default Restrinctions;
