import { DialogTitle, Typography, IconButton, Dialog, Paper, DialogContent, DialogContentText, DialogActions, Button, PaperProps } from "@mui/material"
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import _ from 'lodash'
import { DialogTitleProps } from './types'
import AssetFeatureCategoryCreator from "./asset-features-category-creator";

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-asset-feature-category"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} sx={{
                //top: '-25%'
            }} />
        </Draggable>
    );
}



function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{ cursor: 'move' }} >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const AssetFeatureCategoryPicker = (props: { open: boolean, onClose: () => void, onSelectFeatureCategories: (feature: [string]) => void }) => {

    const [open, setOpen] = useState(false);
    const [openAssetFeatureCategoryCreator, setOpenAssetFeatureCategoryCreator] = useState(false);

    /*const handleClickOpen = () => {
        setOpen(true);
    };*/

    const handleClose = () => {
        setOpen(false);
        props.onClose()
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    const handleCreateNewFeatureCategory = () => {
        handleClose()
        setOpenAssetFeatureCategoryCreator(true)
    }

    const [rowSelection, setRowSelection] = useState({});


    function renderCityDescriptionList() {
        type FeatureCategory = {
            name: string;
        };

        const columns: MRT_ColumnDef<FeatureCategory>[] = [
            {
                accessorKey: "name", //access nested data with dot notation
                header: "name",
                //Header: () => (<></>),
            },
        ]

        const data: FeatureCategory[] = [
            { name: "Interior Rooms" },
            { name: "Exterior elements" },
            { name: "Equipment" },
            { name: "Construction" },
        ]

        return <MaterialReactTable

            state={{ rowSelection }}
            localization={{
                noRecordsToDisplay: 'No cities description'
            }}
            initialState={{
                showGlobalFilter: true
            }}
            muiTablePaginationProps={{
                rowsPerPageOptions: [10],
            }}
            columns={columns}
            positionGlobalFilter='left'
            data={data}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableHiding={false}
            enableColumnFilters={false}
            enableColumnActions={false}
            enableSorting={false}
            enableRowSelection
            //enableSelectAll={false}
            onRowSelectionChange={setRowSelection}
            //enableMultiRowSelection={false}
            muiTablePaperProps={{
                elevation: 0,
                variant: "outlined",
                sx: { width: 550 }
            }}
            muiTableHeadRowProps={{
                sx: {
                    display: 'none'
                },
            }}
            muiTopToolbarProps={{
                sx: {
                    justifyContent: "center",
                }
            }}
            muiToolbarAlertBannerProps={{
                sx: {
                    display: 'none'
                },
            }}
        />
    }

    /*
    */


    return (
        <>
            <Dialog
                open={open}
                fullWidth={false}
                maxWidth='md'

                onClose={() => { }}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-asset-feature-category"

            >
                <BootstrapDialogTitle id="draggable-dialog-asset-feature-category" onClose={handleClose}>
                    Select feature categories
                </BootstrapDialogTitle>
                <DialogContent>
                    {renderCityDescriptionList()}
                    <DialogContentText>
                        <Typography variant="caption" display="block" gutterBottom>
                            you may want to
                            <Button href='/market' color="secondary" target="_blank" size="small" sx={{ textTransform: 'capitalize', padding: 0, margin: 0, marginX: 1, minWidth: 0 }}>Manage</Button> feature categories list, or
                            <Button onClick={handleCreateNewFeatureCategory} size="small" color="success" sx={{ textTransform: 'capitalize', padding: 0, margin: 0, marginX: 1, minWidth: 0 }}>Create</Button>
                            new one.
                        </Typography>
                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button disabled={_.isEmpty(rowSelection)} onClick={() => { }}>Select</Button>
                </DialogActions>
            </Dialog>
            <AssetFeatureCategoryCreator
                open={openAssetFeatureCategoryCreator}
                onClose={() => { setOpenAssetFeatureCategoryCreator(false) }}
                onCreateFeatureCategory={(name: string) => { props.onSelectFeatureCategories([name]) }}
            />
        </>
    )

}

export default AssetFeatureCategoryPicker;