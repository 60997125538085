import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';


const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`
const Divider = tw.hr`
`

const Card = tw.div`
min-w-86 max-w-lg mt-2  w-128  self-center
`
const CardHead = tw.div`
bg-primary-500 text-white p-2 flex flex-row
`
const HeadLine = tw.h1`
text-lg font-medium uppercase
`

const Content = tw.div`
px-2 flex flex-row
`

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`

const Value = tw.div`
flex-grow text-sm text-right capitalize
`
const Flipping = (props: {id? : any, viewPortObserver?: (isVisible: boolean) => void }) => {

    return (
        <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
            <Headline>Flipping</Headline>
            <Divider />
            <Card>
                <CardHead>
                    <HeadLine>Purchase Price</HeadLine>
                    <Value css={tw`text-lg`}>
                        <NumberFormat
                            value={5000000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </CardHead>
                <Content>
                    <Label>Building 1</Label>
                    <Value>
                        <NumberFormat
                            value={5000000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>


            </Card>

            <Card>
                <CardHead>
                    <HeadLine>Réhabilitation</HeadLine>
                    <Value css={tw`text-lg`}>
                        <NumberFormat
                            value={2000000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </CardHead>
                <Content>
                    <Label>Réhabilitation Cost</Label>
                    <Value>
                        <NumberFormat
                            value={2000000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>


            </Card>

            <Card>
                <CardHead>
                    <HeadLine>Estimated Sale Price</HeadLine>
                    <Value css={tw`text-lg`}>
                        <NumberFormat
                            value={12000000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </CardHead>
                <Content>
                    <Label>Building 1</Label>
                    <Value>
                        <NumberFormat
                            value={6000000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>

                <Content>
                    <Label>Building 2</Label>
                    <Value>
                        <NumberFormat
                            value={6000000}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                        />
                    </Value>
                </Content>


            </Card>

        </ArticleContainer>
    )
}

export default Flipping;